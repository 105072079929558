<template>
    <div class="columns is-multiline">
        <div class="column is-12 has-text-left">
            <h1 class="title fonte-1 is-size-4-touch pb-1">
                <i class="icon mdi mdi-web mr-2" />
                <span>
                    Canais
                </span>
            </h1>
            <h2 class="subtitle fonte-1 is-size-6-touch">Gerencie os canais de reserva de sua hospedagem.</h2>
            <hr class="my-2">
        </div>
        <div class="column is-12 has-text-left py-2">
            <button class="button is-success is-medium is-responsive"
                @click="this.$refs.modalAdicionarCanal.ativarModal()" id="button-add">
                <i class="mdi mdi-plus icon" />
                <span>Adicionar</span>
            </button>

        </div>

        <div class=" column is-6 is-12-touch">

            <div class="table-container" v-if="canais.length > 0">

                <table class="table is-fullwidth">

                    <thead class="thead_color">

                        <th class="has-text-left">Categoria</th>
                        <th class="has-text-left">Nome</th>
                        <th class="has-text-left">

                        </th>

                    </thead>

                    <tbody>
                        <tr v-for="canal in canais" v-bind:key="canal.id" :class="{ 'tr-arquivado': canal.arquivado }">
                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': canal.arquivado }">
                                <span>
                                    {{ canal.categoria }}
                                </span>
                                <span v-if="canal.arquivado">
                                    (<i class="icon mdi mdi-archive" /> Arquivado)
                                </span>
                            </td>

                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': canal.arquivado }">{{ canal.nome
                                }}
                            </td>

                            <td class="has-text-centered fonte-1">
                                <div class="buttons is-right">
                                    <button class="button is-small is-dark has-tooltip-left"
                                        v-if="!canal.arquivado && !canal.canal_padrao"
                                        @click="canalSelecionado = canal; modalArquivarAtivo = true">
                                        
                                      
                                       <span class="is-hidden-desktop">
                                           <i class="mdi mdi-archive icon" />
                                       </span>
                                        
                                        <span class="is-hidden-touch">
                                            <i class="mdi mdi-archive icon" />
                                            <span>
                                                Arquivar
                                            </span>
                                        </span>
                                    
                                        </button>

                                    <button class="button is-small is-danger has-tooltip-left" v-if="canal.arquivado"
                                        @click="canalSelecionado = canal; modalApagarAtivo = true">

                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-delete icon" />
                                        </span>

                                        <span class="is-hidden-touch">
                                            <i class="mdi mdi-delete icon" />
                                            <span>
                                                Apagar
                                            </span>
                                        </span>
                                    </button>
                                    <button class="button is-small is-dark" v-if="canal.arquivado"
                                        @click="canalSelecionado = canal; modalDesarquivarAtivo = true">
                                        
                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-archive-arrow-up icon" />
                                        </span>
                                        
                                        <span class="is-hidden-touch">
                                            <i class="mdi mdi-archive-arrow-up icon" />
                                            <span>
                                                Recuperar
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>

                    </tbody>

                </table>


            </div>

            <div class="notification" v-else>

                <p class="fonte-1 is-size-5">
                    <i class="icon mdi mdi-information-outline mr-2"></i>
                    <span>Você ainda não cadastrou nenhum <strong>Canal de Reserva</strong>.</span>
                </p>
            </div>

        </div>
    </div>

    <div class="modal is-active" v-if="modalArquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left fonte-1  is-size-5 ">

                    Deseja <strong>arquivar</strong> o canal <span class="has-text-weight-bold">{{
                    canalSelecionado.opcao
                }}</span> ?

                </p>
                <hr>
                <div class="field is-grouped is-grouped-centered">

                    <div class="control is-expanded">
                        <button class="button is-fullwidth has-text-weight-bold "
                            @click="modalArquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="arquivarCanal">
                            <i class="icon mdi mdi-archive"></i>
                            <span>Arquivar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>
    <div class="modal is-active" v-if="modalApagarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left  fonte-1  is-size-5 ">
                    Deseja <span class="has-text-danger">apagar</span> o canal <span class="has-text-weight-bold">{{
                    canalSelecionado.nome
                }}</span> ?

                </p>
                <hr class="mx-3">
                <div class="field is-grouped is-grouped-centered mx-3">

                    <div class="control is-expanded">
                        <button class="button is-fullwidth has-text-weight-bold "
                            @click="modalApagarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-danger" @click="apagarCanal">
                            <i class="icon mdi mdi-delete"></i>
                            <span>Apagar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>

    <div class="modal is-active" v-if="modalDesarquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left  fonte-1  is-size-5 ">

                    Deseja <strong>recuperar</strong> o canal <span class="has-text-weight-bold">{{
                    canalSelecionado.nome
                }}</span> ?

                </p>
                <hr class="">
                <div class="field is-grouped is-grouped-centered">
                    <div class="control is-expanded">
                        <button class="button is-fullwidth  has-text-weight-bold "
                            @click="modalDesarquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="recuperarCanal">
                            <i class="icon mdi mdi-archive-arrow-up" />
                            <span>Recuperar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>



    <ModalAdicionarCanal ref="modalAdicionarCanal" @canal-adicionado="this.getCanais()" />
</template>

<script>
import ModalAdicionarCanal from './Modals/ModalAdicionarCanal.vue';
import { toast } from 'bulma-toast';
import axios from 'axios';

export default {
    components: { ModalAdicionarCanal },

    data() {
        return {
            canais: [],

            modalArquivarAtivo: false,
            modalDesarquivarAtivo: false,
            modalApagarAtivo: false,
        };
    },

    mounted() {

        this.getCanais()
    },

    methods: {

        async getCanais() {

            await axios.get('api/v1/canais/todos-canais/')
                .then(response => {

                    this.canais = response.data

                })

        },

        async arquivarCanal() {

            this.canalSelecionado.arquivado = true

            await axios.patch(`api/v1/canais/${this.canalSelecionado.id}/`, this.canalSelecionado)
                .then(response => {
                    this.getCanais()
                    this.modalArquivarAtivo = false

                    const msg = `<i class='mdi mdi-archive mr-2'><span> <strong>${this.canalSelecionado.descricao}</strong> foi arquivado.</span>`

                    toast({
                        message: msg,
                        type: ' is-light',
                        position: 'top-right',
                        duration: 2000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })


        },
        async recuperarCanal() {
            await axios.post(`api/v1/canais/desarquivar-canal/`, { id: this.canalSelecionado.id })
                .then(response => {
                    this.getCanais()
                    this.modalDesarquivarAtivo = false

                    const msg = `<i class='mdi mdi-archive-arrow-up mr-2'><span> O canal <strong>${this.canalSelecionado.nome}</strong> foi recuperado.</span>`

                    toast({
                        message: msg,
                        type: 'is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })
        },

        async apagarCanal() {

            await axios.post(`api/v1/canais/apagar-canal/`, { id: this.canalSelecionado.id })
                .then(response => {
                    //console.log(response)
                    this.modalApagarAtivo = false
                    const msg = `<i class='mdi mdi-delete mr-2'><span> O canal <strong>${this.canalSelecionado.nome}</strong> foi apagado.</span>`

                    toast({
                        message: msg,
                        type: 'is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                    this.getCanais()
                })
        }
    }
}


</script>

<style lang="scss" scoped>
.modal-card-body {
    width: max-content;
    border-radius: 5px;
    margin: 5rem;
}

@media screen and (max-width: 763px) {
    table .button {
        width: auto !important;
        aspect-ratio: 1;
    }
    .modal-card-body {
        border-radius: 5px;
        margin: 1rem !important;
        width: auto !important;
    }
}

.tr-arquivado {
    font-family: 'Poppins';
    background-color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.03);
}

.td-arquivado {
    color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.6);
    font-family: 'Poppins';
}

table .button {
    width: 7rem;
}

table td {

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

table {
    table-layout: fixed;
}
</style>