<template>
    <div class="modal is-active" v-if="modalAtivo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-produtos">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2 has-text-left">Editar Produto</p>
                <button class="delete is-large" aria-label="close" @click="modalAtivo = !modalAtivo"></button>

            </header>

        </div>
        <div class="modal-content has-background-white p-5" id="body-produtos">
            <section>
                <form @submit.prevent="novo_produto ? salvarNovoProduto() : atualizarProduto()" id="form-produto">

                    <div class="field is-grouped ">
                        <div class="control">
                            <label class="label has-text-left fonte-1" for="input-nome">Nome:</label>
                            <input class="input fonte-1" @input="verificarAlteracoes" required
                                v-model="produtoSelecionadoForm.descricao" type="text">
                        </div>

                        <MoneyInput ref="money" @input="verificarAlteracoes"
                            inputClass="input has-text-left fonte-1 money_input"
                            labelClass="label has-text-left fonte-1" label="Valor base:"
                            v-model="produtoSelecionadoForm.valor_unitario" />
                    </div>

                    <hr>

                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <button type="button" class="button is-normal is-fullwidth " @click="modalAtivo = false">
                                <span>
                                    Cancelar
                                </span>
                            </button>
                        </div>

                        <div class="control is-expanded">
                            <button class="button is-info is-fullwidth  is-normal" form="form-produto"
                                :disabled="!alteracaoFeita">
                                <i class="icon mdi mdi-content-save"></i>
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>

                </form>
            </section>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';



export default {
    name: 'Modal Produtos',
    emits: ['produtoEditado'],
    data() {
        return {
            modalAtivo: false,
            produtos: null,

            produtoSelecionado: true,
            produtoSelecionadoForm: {
                descricao: '',
                valor_unitario: ''
            },

            produtoSelecionadoFormCopy: null,
            alteracaoFeita: false,

        };
    },

    methods: {
        ativarModal(produto) {
            this.modalAtivo = true;
            this.selecionarProduto(produto)
        },
        selecionarProduto(produto) {

            this.produtoSelecionado = produto
            this.produtoSelecionadoForm = Object.assign({}, this.produtoSelecionado)

            var valor_f = Number.parseFloat(produto.valor_unitario).toFixed(2)
            valor_f = valor_f.replace('.', ',')

            this.produtoSelecionadoForm.valor_unitario = '' + valor_f

            this.produtoSelecionadoForm_copy = Object.assign({}, this.produtoSelecionadoForm)

        },

        verificarAlteracoes() {

            if (JSON.stringify(this.produtoSelecionadoForm) !== JSON.stringify(this.produtoSelecionadoForm_copy)) {

                this.alteracaoFeita = true;

            } else {
                this.alteracaoFeita = false;
            }

        },

        atualizarProduto() {

            this.produtoSelecionado.descricao = this.produtoSelecionadoForm.descricao
            this.produtoSelecionado.valor_unitario = Number.parseFloat(this.produtoSelecionadoForm.valor_unitario.replace(',', '.'))

            axios.patch(`api/v1/produtos/${this.produtoSelecionado.id}/`, this.produtoSelecionado)
                .then(response => {

                    const msg = `<i class="mdi mdi-trash-can pr-3"><span>  Produto <strong>${this.produtoSelecionadoForm_copy.descricao}</strong> foi atualizado!</span>`

                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                    // this.produtoSelecionadoForm_copy = Object.assign({}, this.produtoSelecionadoForm)
                    // this.verificarAlteracoes()

                    this.$emit('produtoEditado')
                    this.modalAtivo = false

                })
                .catch(error => {
                    console.log(error)
                })

        },



    },
    components: { MoneyInput }
}

</script>


<style scoped lang="scss">
@media only screen and (max-width: 1024px) {

    #header-produtos,
    #body-produtos {
        width: 97% !important;

    }
}

#header-produtos,
#body-produtos {
    width: 450px;

}
</style>