<template>
    <div class="modal is-active" v-if="modalAtivo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-canais">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2 has-text-left ml-2">Adicionar Canal</p>
                <button class="delete is-large" aria-label="close" @click="modalAtivo = !modalAtivo"></button>
            </header>

        </div>
        <div class="modal-content has-background-white p-5" id="body-canais">
            <section>

                <form @submit.prevent="salvarNovoCanal()" id="form-categoria" class="mx-2">

                    <div class="field is-grouped has-text-left ">

                        <div class="control">
                            <label for="categoria" class="label fonte-1">Categoria:</label>
                            <div class="select">
                                <select name="categoria" v-model="categoria" id="categoria">
                                    <option value="Operadora">Operadora</option>
                                    <option value="Agência">Agência</option>
                                    <option value="OTA">OTA</option>
                                    <option value="Particular">Particular</option>

                                </select>
                            </div>
                        </div>

                        <div class="control is-expanded">
                            <label class="label has-text-left fonte-1" for="input-nome">Nome:</label>
                            <input class="input fonte-1" placeholder="Ex.: Booking,Trivago,CVC..." required
                                v-model="nome" type="text">
                        </div>


                    </div>

                    <hr class="my-5">
                    <div class="field is-grouped is-grouped-right">
                        <div class="control is-expanded">
                            <button type="button" class="button is-normal is-fullwidth " @click="modalAtivo = false">
                                <span>
                                    Cancelar
                                </span>
                            </button>
                        </div>
                        <div class="control is-expanded">
                            <button class="button is-success is-fullwidth" form="form-categoria">
                                <i class="icon mdi mdi-plus-thick"></i>
                                <span>Adicionar</span>
                            </button>
                        </div>

                    </div>

                </form>




            </section>
        </div>

    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';



export default {
    name: 'Modal Adicionar Canal',

    emits: ['canalAdicionado'],

    data() {
        return {
            modalAtivo: false,
            nome: '',
            categoria: ''

        };
    },

    methods: {

        ativarModal() {
            this.modalAtivo = true;
            this.nome = ''
            this.categoria = 'Operadora'
        },

        salvarNovoCanal() {
            const canal = {
                nome: this.nome,
                categoria: this.categoria
            };
            axios.post("api/v1/canais/", canal)
                .then(response => {


                    const msg = `<i class='mdi mdi-check-circle mr-2'><span> O Canal <strong>${this.nome}</strong> foi adicionado com sucesso!</span>`

                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })

                    this.modalAtivo = false;
                    this.$emit('canalAdicionado')
                })
                .catch(error => {
                    console.log(error);
                });
        }

    },
    components: { MoneyInput }
}

</script>

<style scoped lang="scss">
@media only screen and (max-width: 1024px) {

    #header-canais,
    #body-canais {
        width: 97% !important;

    }
}

#header-canais,
#body-canais {
    width: 450px;

}
</style>