<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">

        <div class="navbar-brand">

            <a role="button" class="navbar-item is-hidden-touch" id="button-min-sidebar" @click="minimizarSidebar"><i
                    class="mdi title fonte-2 is-clickable"
                    :class="[this.$store.state.isSidebarMin ? 'mdi-menu' : 'mdi-menu-open']"></i></a>
            

            <div class="navbar-item is-hidden-desktop">
                <figure class="image" >
                    <img src="@/assets/logo3.png"  alt="Logo Atena Hub" >
                </figure>
            </div>
            <div class="navbar-item">
                <h1 class="marca is-size-4 pb-1">{{ $route.name }}</h1>

            </div>


            <a role="button" class="navbar-burger fonte-2" :class="{ 'is-active': hamburgerMenuAtivo }"
                @click="hamburgerMenuAtivo = !hamburgerMenuAtivo" aria-label="menu" aria-expanded="false"
                data-target="navbarMenu">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': hamburgerMenuAtivo }" id="navbarMenu">

            <div class="navbar-end mr-6">

                <div class="navbar-item has-text-left is-hidden-desktop">
                    <router-link to="/mapa" @click="hamburgerMenuAtivo = false">
                        <div class="columns m-0 is-mobile  is-vcentered">
                            <div class='column is-narrow pr-4'>
                                <figure class=" image is-16x16">
                                    <img src="@/assets/map2.png" class="side-btn" alt="Menu de Mapa">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow">
                                <span class="span-link fonte-1 has-text-weight-bold">Mapa de Reservas</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-item has-text-left is-hidden-desktop ">
                    <router-link to="/painel" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class='column is-narrow pr-4'>
                                <figure class="image is-16x16">
                                    <img src="@/assets/inout.png" class="side-btn" alt="Menu de Check-in">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow">
                                <span class="span-link fonte-1 has-text-weight-bold">Meu painel</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-item has-text-left is-hidden-desktop ">
                    <router-link to="/acomodacoes" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class='column is-narrow pr-4'>
                                <figure class="image is-16x16">
                                    <img src="@/assets/bed.png" class="side-btn" alt="Menu de Acomodações">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow">
                                <span class="span-link fonte-1 has-text-weight-bold">Acomodações</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-item has-text-left is-hidden-desktop ">
                    <router-link to="/hospedes" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class='column is-narrow pr-4'>
                                <figure class="image is-16x16">
                                    <img src="@/assets/hospedes2.png" class="side-btn" alt="Menu de Hóspedes">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow">
                                <span class="span-link fonte-1 has-text-weight-bold">Hóspedes</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-item has-text-left is-hidden-desktop">
                    <router-link to="/reservas" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class="column is-narrow pr-4">
                                <figure class="image is-16x16">
                                    <img src="@/assets/list.png" class="side-btn" alt="Menu de Reservas">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow">
                                <span class="span-link fonte-1 has-text-weight-bold">Reservas</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-item has-text-left is-hidden-desktop ">
                    <RouterLink to="/insights" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class="column is-narrow pr-4">
                                <figure class="image is-16x16">
                                    <img src="@/assets/insight.png" class="side-btn" alt="Meu Painel">
                                </figure>
                            </div>

                            <div class="column pl-0 pr-0 is-8 is-narrow">

                                <span class="span-link fonte-1 has-text-weight-bold">Insights</span>
                            </div>
                        </div>
                    </RouterLink>
                </div>

                <div class="navbar-item has-text-left is-hidden-desktop ">
                    <RouterLink to="/gerenciamento" @click="hamburgerMenuAtivo = false">
                        <div class="columns is-mobile m-0 is-vcentered">
                            <div class="column is-narrow pr-4">
                                <figure class="image is-16x16">
                                    <img src="@/assets/resort.png" class="side-btn" alt="Meu Painel">
                                </figure>
                            </div>

                            <div class="column pl-0 pr-0 is-8 is-narrow">

                                <span class="span-link fonte-1 has-text-weight-bold">Gerenciamento</span>
                            </div>
                        </div>
                    </RouterLink>
                </div>

                <div class="navbar-item has-text-left is-hidden-desktop">
                    <a class="px-2" @click="logout">
                        <span class="has-text-danger icon-text">
                            <i class="mdi mdi-logout-variant icon"></i>
                            <span class="is-extend">Sair</span>
                        </span>
                    </a>
                </div>


                <div class="navbar-item has-dropdown  is-hidden-touch" :class="{ 'is-active': this.$store.state.isProfileDropdown }"
                    id="dropdown-usuario">

                    <a role="button" class="navbar-link  fonte-2 is-arrowless is-size-5 "
                        @click="this.$store.commit('setIsProfileDropdown', !this.$store.state.isProfileDropdown)" id="dropdown-link">
                
                        <i class="mdi mdi-account-outline pr-2 is-size-4" id="avatar"></i>
                        <span>Meu perfil</span>
                    </a>

                    <div class="navbar-dropdown mt-3 is-boxed is-right">
                        <div class="navbar-item">
                            <div class="columns m-0 p-0 ">
                                <div class="column py-0 pl-0">
                                    <p class="has-text-left">
                                        <span class="title is-size-6 mb-0 fonte-1">
                                            {{ this.$store.state.hospedagem.nome}}
                                        </span>
                                        <br>
                                        <span class="subtitle is-size-7 fonte-1">{{ this.$store.state.user.email
                                            }}</span>

                                    </p>
                                </div>

                            </div>
                        </div>

                        <hr class="my-1">
                        <a class="navbar-item px-2" v-if="this.$store.state.isStaff === true">
                            <RouterLink to="/adicionar-hospedagem" @click="hamburgerMenuAtivo = false">
                                <span class="mt-2 fonte-1 icon-text">
                                    <i class="mdi mdi-plus-thick icon"></i>
                                    <span class="is-extend">Adicionar Hospedagem</span>
                                </span>
                            </RouterLink>
                        </a>
                        <a class="navbar-item px-2">
                            <RouterLink to="/alterar-senha" @click="hamburgerMenuAtivo = false">
                                <span class="mt-2 fonte-1 icon-text">
                                    <i class="mdi mdi-lock icon"></i>
                                    <span class="is-extend">Alterar senha</span>
                                </span>
                            </RouterLink>
                        </a>

                        <a class="navbar-item px-2" @click="logout">
                            <span class="has-text-danger icon-text">
                                <i class="mdi mdi-logout-variant icon"></i>
                                <span class="is-extend">Sair</span>
                            </span>
                        </a>

                    </div>

                </div>

            </div>
        </div>

    </nav>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Navbar',
    emits: ['minimizarSidebar'],
    data() {
        return {

            dropdownAtivo: false,
            hamburgerMenuAtivo: false,

        }
    },

    mounted() {
        ////console.log("->" + this.$store.state.team.is_activated)
        // //console.log('é superUser -> ', this.$store.state.isSuperUser)
        ////console.log(this.$store.state.isSuperUser === true)
    },
    methods: {

        logout() {

            this.$store.commit('logoutUser')
            this.$store.commit('setIsProfileDropdown', false)
            this.$store.commit('removeToken')
            this.$router.push('log-in')

        },

        minimizarSidebar() {
            this.$store.commit('setIsSidebarMin', !this.$store.state.isSidebarMin)
            localStorage.setItem('isSidebarMin', this.$store.state.isSidebarMin)
        },

        changeTitle(titulo) {
            this.titulo = titulo
        }

    }
}

</script>

<style scoped lang="scss">
.navbar {
    background-color: $primary-color !important;
    max-width: 100%;
    position: relative;
    border-bottom: 2px $primary-color solid;

}

.marca {
    color: $secondary-color !important;
    font-family: "Poppins";
}

.navbar-dropdown {
    width: max-content;
}

#button-min-sidebar:focus,
#button-min-sidebar:hover,
#button-min-sidebar.is-active {
    box-shadow: $box-shadow-color 1px 2px 2px !important;
}

#avatar {
    border: none !important;
}

#dropdown-usuario:focus,
#dropdown-usuario:hover,
#dropdown-usuario.is-active {
    background-color: rgba(180, 180, 180, 0.5) !important;
}

#dropdown-usuario:focus .navbar-link,
#dropdown-usuario:hover .navbar-link,
#dropdown-usuario.is-active .navbar-link {
    background-color: $primary-color;
    box-shadow: $box-shadow-color 1px 2px 2px;
}
</style>