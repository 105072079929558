<template>
    <div class="columns m-0 strech" id="container">
        <div class="column is-12">
            <div class="tabs is-boxed is-medium  mb-0 is-hidden-touch">
                <ul>
                    <li :class="[tabAtiva == 1 ? 'is-active' : '']" @click="tabAtiva = 1">
                        <a class="tab-item fonte-1 has-text-weight-bold">
                            <span>
                                <i class="icon mx-0 mdi mdi-tag espelhado" />
                                <span>
                                    Tipos de Acomodação
                                </span>
                            </span>
                        </a>
                    </li>
                    <li :class="[tabAtiva == 2 ? 'is-active' : '']" @click="tabAtiva = 2">
                        <a class="tab-item fonte-1 has-text-weight-bold">
                            <span>
                                <i class="icon mx-0 mdi mdi-home-group" />
                                Acomodações
                            </span>
                        </a>
                    </li>
                    <li :class="[tabAtiva == 3 ? 'is-active' : '']" @click="tabAtiva = 3">
                        <a class="tab-item fonte-1 has-text-weight-bold">
                            <span>
                                <i class="icon mx-0 mdi mdi-cart" />
                                Produtos e Serviços
                            </span>
                        </a>
                    </li>
                    <li :class="[tabAtiva == 4 ? 'is-active' : '']" @click="tabAtiva = 4">
                        <a class="tab-item fonte-1 has-text-weight-bold">
                            <span>
                                <i class="icon mx-0 mdi mdi-web" />
                                Canais
                            </span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="field is-hidden-desktop ">
                <div class="control">
                    <div class="select is-fullwidth is-dark">
                        <select name="" id="" class="fonte-1 title" v-model="tabAtiva">

                            <option :value="1">Tipos de Acomodação</option>
                            <option :value="2">Acomodações</option>
                            <option :value="3">Produtos e Serviços</option>
                            <option :value="4">Canais</option>

                        </select>
                    </div>
                </div>

            </div>

            <div class="p-6" id="area-gerenciamento">
                <template v-if="tabAtiva === 1">

                    <KeepAlive>
                        <TabTiposAcomodacao />
                    </KeepAlive>

                </template>

                <template v-else-if="tabAtiva === 2">

                    <KeepAlive>
                        <TabAcomodacoes />
                    </KeepAlive>

                </template>
                <template v-else-if="tabAtiva === 3">

                    <KeepAlive>
                        <TabProdutosServicos />
                    </KeepAlive>

                </template>
                <template v-else-if="tabAtiva === 4">

                    <KeepAlive>
                        <TabCanais />
                    </KeepAlive>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
import TabAcomodacoes from '@/components/TabAcomodacoes.vue';
import TabCanais from '@/components/TabCanais.vue';
import TabProdutosServicos from '@/components/TabProdutosServicos.vue';
import TabTiposAcomodacao from '@/components/TabTiposAcomodacao.vue';


export default {
    name: "Gerenciamento",
    data() {
        return {
            tabAtiva: 1
        };
    },
    components: { TabTiposAcomodacao, TabAcomodacoes, TabProdutosServicos, TabCanais }
}

</script>

<style lang="scss" scoped>

@media screen and (max-width: 1024px) {

    #area-gerenciamento {
        border-top: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.50) 1px solid !important;
        border-radius: 5px !important;
        padding: 1rem !important;
        height: 100%;
    }
    
}

#container {
    overflow-x: auto;
    max-width: auto;
    position: relative;
}

.columns{
    width: 100%;
}

#area-gerenciamento {
    border: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.50) 1px solid;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    min-height: 94%;
}

.espelhado {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
</style>