<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card cartao" id="header_criar_reserva">
            <header class="modal-card-head titulo">
                <p class="modal-card-title fonte-2 ">
                    Criar nova reserva
                </p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>
            </header>
        </div>

        <div class="modal-content has-background-white has-y-scroll" id="content_criar_reserva">

            <form @submit.prevent="salvarReserva" id="criar_reserva">

                <p class="title has-text-left fonte-1 is-size-4 mb-5 mt-4">
                    <span class="icon-text">
                        <span class="icon mr-3">
                            <i class="mdi mdi-book-account"></i>
                        </span>
                        <span>
                            Dados da Reserva
                        </span>
                    </span>
                </p>
                <hr>
                <div class="columns is-multiline ">

                    <div class="field column is-6-desktop is-6-tablet">
                        <div class="control is-expanded">
                            <label class="label fonte-1 has-text-left" for="check-in">Check-in</label>
                            <input type="date" readonly disabled :min="data_entrada" :max="data_entrada"
                                class="input is-fullwidth is-normal" v-model="data_entrada" id="check-in">
                        </div>
                    </div>
                    <div class="field column is-6-desktop is-6-tablet">
                        <div class="control is-expanded">
                            <label class="label fonte-1 has-text-left" for="check-out">Check-out</label>
                            <input type="date" :min="`${this.min_data_saida}`" :max="`${this.max_data_saida}`" required
                                class="input is-fullwidth is-normal" v-model="data_saida" id="check-out">
                        </div>
                    </div>
                    <div class="field column is-4-desktop is-6-tablet">
                        <div class="control is-expanded">
                            <label class="label fonte-1 has-text-left" for="acomodacao">Acomodação</label>
                            <input type="text" readonly disabled class="input mr-0" :value="acomodacao.nome"
                                id="acomodacao">
                        </div>
                    </div>
                    <div class="field column is-4-desktop is-6-tablet">
                        <div class="control is-expanded has-text-left">
                            <label class="label fonte-1 has-text-left" for="canal">Canal</label>
                            <div class="select is-fullwidth">
                                <select name="" class="fonte-1 " id="canal" v-model="canal">
                                    <option :value="canal.id" v-for="canal in canais" v-bind:key="canal.id">
                                        {{ canal.opcao }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4 is-hidden-touch"></div>
                    <div class="field column is-3">
                        <div class="control">
                            <label class="label fonte-1 has-text-left" for="quantidade">Adultos</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button type="button" class="button  " style="z-index: 9999;"
                                        @click="function () { if (qtd_adultos > 0) { qtd_adultos-- } }" id="quantidade">
                                        <i class="mdi mdi-minus-thick"></i>
                                    </button>
                                </div>

                                <div class="control">
                                    <input type="number" max="10" readonly
                                        class="input has-text-centered fonte-1 has-text-weight-bold"
                                        v-model="qtd_adultos">
                                </div>

                                <div class="control">
                                    <button type="button" class="button fonte-1 " @click="qtd_adultos++">
                                        <i class="mdi mdi-plus-thick"></i>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field column is-3">
                        <div class="control">
                            <label class="label fonte-1 has-text-left" for="quantidade">Crianças</label>
                            <div class="field has-addons has-text-left">
                                <div class="control">
                                    <button type="button" class="button fonte-1 " style="z-index: 9999;"
                                        @click="function () { if (qtd_criancas > 0) { qtd_criancas-- } }"
                                        id="quantidade">
                                        <i class="mdi mdi-minus-thick"></i>
                                    </button>
                                </div>

                                <div class="control">
                                    <input type="number" max="10" readonly
                                        class="input has-text-centered fonte-1 has-text-weight-bold"
                                        v-model="qtd_criancas">
                                </div>

                                <div class="control">
                                    <button type="button" class="button fonte-1  " @click="qtd_criancas++">
                                        <i class="mdi mdi-plus-thick"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6 is-hidden-touch">

                    </div>


                    <div class="field is-12 column">
                        <div class="control has-text-left">
                            <label for="cortesia" class="checkbox">
                                <input type="checkbox" id="cortesia" v-model="cortesia"
                                    @change="valorDiaria = '0,00', atualizaValorTotal()">
                                <span class="fonte-1 is-size-6">
                                    Essa reserva é uma <strong>Cortesia</strong>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="field  column is-4 pb-0 ">
                        <div class="control is-expanded">
                            <money-input input-class="input fonte-1"
                                :label-class="cortesia ? 'input-desabilitado label fonte-1 has-text-left' : 'label fonte-1 has-text-left'"
                                :disabled="cortesia" :is-half="false" label="Valor da Diária" required
                                v-model="valorDiaria" @input="atualizaValorTotal" />
                        </div>
                    </div>
                    <div class="column field is-4 pb-0">
                        <div class="control is-expanded">
                            <label class="label fonte-1 has-text-left">
                                <strong>Estadia</strong>
                            </label>
                            <input class=" input fonte-1 has-text-centered" readonly
                                :value="diferencaDias(data_entrada, data_saida) + ' dia(s)'">
                        </div>
                    </div>
                    <div class="field column is-4">
                        <div class="control is-expanded">
                            <label class="label fonte-1 has-text-left">
                                Valor Total
                            </label>
                            <input class="input fonte-1 has-text-centered " :value="valorTotal" readonly>

                        </div>
                    </div>
                    <div class="column is-12">
                        <p class="fonte-1 is-size-7 ml-1 has-text-left mb-3 has-text-weight-bold"
                            :class="{ 'input-desabilitado': cortesia }">
                            Valor base da acomodação : {{ valorBase }} - Desconto na diária: {{ valorDescontado }} ({{
        desconto }}%)
                        </p>
                    </div>
                </div>

                <hr class="">
                <p class="title fonte-1 is-size-4 has-text-left mb-5">
                    <span class="icon-text">
                        <span class="icon mr-3">
                            <i class="mdi mdi-account" />
                        </span>
                        <span>Dados do Hóspede</span>
                    </span>
                </p>

                <p class="is-size-6 fonte-1 has-text-left subtitle pt-2">
                    <i class="icon mdi mdi-information-outline"></i>
                    Informe o CPF para buscar por um hóspede já cadastrado.
                </p>

                <div class="columns is-multiline">
                    <div class="field column is-4">
                        <div class="control has-text-left">
                            <label class="fonte-1 label has-text-left" for="input-cpf">CPF</label>

                            <c-p-f-input :cpf-mascara="cpfMascara" @cpf-valido="buscaHospedePorCPF"
                                @cpf-incompleto="hospede_encontrado !== false ? limparHospede() : ''"
                                @cpf-invalido="IsCPFInvalido = true" v-model="cpf" />

                            <p class="help is-danger has-text-left pl-1" v-if="IsCPFInvalido">CPF inválido,
                                informe
                                novamente!</p>
                        </div>
                    </div>

                    <div class="field column is-4">
                        <div class="control has-text-left">
                            <label class="fonte-1 label has-text-left" for="telefone">Telefone</label>

                            <input v-maska="telefoneMascara" data-maska-eager v-model="telefone"
                                data-maska="['(##) #####-####','(##) ####-####']" class="input fonte-1 has-text-left"
                                id="telefone" :class="{ 'input-invalido': telefoneInvalido }" type="text" required
                                :disabled="this.hospede_encontrado">

                            <p class="help has-text-danger has-text-left pl-1" v-if="telefoneInvalido">Telefone
                                informado inválido
                            </p>
                        </div>
                    </div>
                    <div class="field column is-4">
                        <div class="control  has-text-left is-expanded">
                            <label class="fonte-1 label" for="data-nascimento">Data de Nasc.</label>

                            <input class="input fonte-1 " type="date" id="data-nascimento" required
                                :disabled="this.hospede_encontrado" v-model="data_nascimento">

                        </div>
                    </div>
                    <div class="field column is-6">
                        <div class="control is-expanded">
                            <label class="fonte-1 label has-text-left" for="nome">Nome Completo</label>

                            <input class="input fonte-1" type="text" required :disabled="this.hospede_encontrado"
                                v-model="nome" id="nome">

                        </div>
                    </div>

                    <div class="field column is-6">
                        <div class="control is-expanded">
                            <label class="fonte-1 label has-text-left" for="email">E-mail</label>

                            <input class="input fonte-1" placeholder="ex.: exemplo@email.com" type="email"
                                autocomplete="email" required id="email" v-model="email"
                                :disabled="this.hospede_encontrado">

                        </div>
                    </div>

                    <div class="field column is-4">
                        <div class="control pb-5 has-text-left">
                            <label class="fonte-1 label has-text-left">Estado</label>
                            <div class="select">
                                <select id="select-estados" v-model="estado" :disabled="this.hospede_encontrado">

                                    <option class="fonte-1" :value="estado" v-for="estado in estados">
                                        {{ estado }}
                                    </option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 pt-0">

                <div class="field">
                    <div class="control">
                        <label class="label fonte-1 has-text-left" for="reserva_info_adicional">
                            Informações adicionais (opcional):
                        </label>

                        <textarea class="textarea has-fixed-size" v-model="informacao_adicional"
                            placeholder="Máx. 255 caracteres" maxlength="255" id="reserva_info_adicional"></textarea>

                        <p class="has-text-right pt-1" style="opacity: 0.42;">{{ informacao_adicional.length
                            }}/255
                        </p>
                    </div>
                </div>

                <hr>
                <div class="field is-grouped ">
                    <div class="control is-expanded has-text-left">
                        <button class="button" @click="this.modal_ativo = !this.modal_ativo" type="button">
                            <span class="has-text-weight-bold">Cancelar</span>
                        </button>
                    </div>
                    <div class="control is-expanded ">
                        <button type="submit" class="button is-fullwidth is-success">
                            <span class="icon">
                                <i class=" mdi mdi-check-circle-outline"></i>
                            </span>
                            <span class="has-text-weight-bold">Salvar</span>
                        </button>
                    </div>
                </div>

            </form>
        </div>


    </div>
</template>

<script>

import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiHumanChild, mdiHumanMale } from '@mdi/js';
import { toast } from 'bulma-toast'
import MoneyInput from '@/components/inputs/MoneyInput.vue'
import CPFInput from '@/components/inputs/CPFInput.vue'
import { vMaska } from "maska"
export default {

    name: "ModalCriarReserva",
    directives: { maska: vMaska },
    components: {
        SvgIcon,
        MoneyInput,
        CPFInput
    },
    emits: ['reservaCriada'],
    data() {
        return {

            modal_ativo: false,

            mdiChild: mdiHumanChild,
            mdiHumanMale: mdiHumanMale,

            //Inputs da Reserva
            valorDiaria: '',
            valorTotal: 'R$ 0,00 ',
            data_entrada: '',
            data_saida: '',
            acomodacao: '',
            qtd_adultos: 1,
            qtd_criancas: 0,
            informacao_adicional: '',
            cortesia: false,
            min_data_saida: '',
            max_data_saida: '',

            hospede_encontrado: false,

            //Inputs do Hóspede titular
            data_nascimento: '',
            cpf: '',
            IsCPFInvalido: false,
            cpfMascara: {
                masked: '',
                unmasked: '',
                completed: ''
            },
            estado: '',
            nome: '',
            telefone: '',
            telefoneInvalido: false,
            telefoneMascara: {
                masked: '',
                unmasked: '',
                completed: ''
            },

            canais: [],
            canal: null,

            email: '',
            estados: [
                "Acre",
                "Alagoas",
                "Amapá",
                "Amazonas",
                "Bahia",
                "Ceará",
                "Distrito Federal",
                "Espirito Santo",
                "Goiás",
                "Maranhão",
                "Mato Grosso do Sul",
                "Mato Grosso",
                "Minas Gerais",
                "Pará",
                "Paraíba",
                "Paraná",
                "Pernambuco",
                "Piauí",
                "Rio de Janeiro",
                "Rio Grande do Norte",
                "Rio Grande do Sul",
                "Rondônia",
                "Roraima",
                "Santa Catarina",
                "São Paulo",
                "Sergipe",
                "Tocantins",
            ]
        }
    },

    computed: {
        valorDescontado() {
            const valor = this.acomodacao.tipo_acomodacao.valor_base - this.valorDiaria.replace(',', '.')
            return valor.toLocaleString('pt-br', { style: 'currency', currency: 'brl' })
        },
        valorBase() {
            const valor = this.acomodacao.tipo_acomodacao.valor_base
            return valor.toLocaleString('pt-br', { style: 'currency', currency: 'brl' })
        },

        desconto() {
            return Math.round((100 - (this.valorDiaria.replace(',', '.') * 100) / this.acomodacao.tipo_acomodacao.valor_base))
        },



    },

    methods: {

        ativarModal(acomodacao, data_entrada, data_saida, min_data_saida, max_data_saida) {
            this.valorDiaria = ''
            this.valorDiarias = ''
            this.qtd_adultos = 1
            this.qtd_criancas = 0
            this.informacao_adicional = ''
            this.hospede_encontrado = false
            this.data_nascimento = ''
            this.cpf = ''
            this.cpf_formatado = ''
            this.estado = ''
            this.nome = ''
            this.email = ''
            this.telefone = ''
            this.IsCPFInvalido = false
            this.telefoneInvalido = false
            this.cortesia = false
            this.acomodacao = acomodacao
            this.data_entrada = data_entrada
            this.data_saida = data_saida
            this.min_data_saida = min_data_saida
            this.max_data_saida = max_data_saida
            this.modal_ativo = true
            this.getCanais()
        },
        diferencaDias(d1, d2) {

            const data1 = new Date(d1)
            const data2 = new Date(d2)

            return Math.ceil(Math.abs(data2 - data1) / (1000 * 60 * 60 * 24));

        },

        atualizaValorTotal() {
            this.valorTotal = (this.diferencaDias(this.data_entrada, this.data_saida) *
                Number.parseFloat(this.valorDiaria.replace(',', '.').replace('', 0))).toLocaleString('pt-br', {
                    style: 'currency', currency: 'BRL'
                })

        },


        salvarReserva() {
            let erros = false
            this.telefoneInvalido = false
            if (!this.telefoneMascara.completed) {
                this.telefoneInvalido = true
                erros = true
            }

            if (!this.cpfMascara.completed || this.IsCPFInvalido) {
                erros = true
            }

            if (erros)
                return

            const hospede = {
                cpf: this.cpfMascara.unmasked,
                nome: this.nome,
                telefone: this.telefoneMascara.unmasked,
                email: this.email,
                estado: this.estado,
                data_nascimento: this.data_nascimento
            }

            var valorDiaria_convertida = Number.parseFloat(this.valorDiaria.replace(',', '.'))
            if (this.cortesia) {
                valorDiaria_convertida = 0
            }

            const reserva = {
                acomodacao: this.acomodacao,
                data_entrada: this.data_entrada,
                data_saida: this.data_saida,
                valor_diaria: valorDiaria_convertida,
                valor_diarias: (this.diferencaDias(this.data_entrada, this.data_saida)) * valorDiaria_convertida,
                manutencao: false,
                hospede: hospede,
                canal: this.canal,
                cortesia: this.cortesia,
            }


            var descricao = `Pré-reserva realizada.`
            if (this.cortesia) {
                descricao += ' A reserva é uma cortesia.'
            }

            axios.post('/api/v1/reservas/', reserva)
                .then(response => {

                    axios.post('api/v1/historicos/adicionar-historico/', {
                        id_reserva: response.data.id, descricao: descricao,
                        informacao_adicional: this.informacao_adicional, categoria: "Negociação"
                    })
                        .then(response => {

                            this.modal_ativo = false

                            const msg = `<i class='mdi mdi-check-circle mr-2'><span>  Nova pré-reserva foi criada!</span>`

                            toast({
                                message: msg,
                                type: 'is-success is-light',
                                position: 'top-right',
                                duration: 5000,
                                dismissible: true,
                                pauseOnHover: true,
                            })

                            this.$emit('reservaCriada')
                            this.modal_ativo = false

                        })
                        .catch(error => {
                            console.log(error);
                        });



                })

                .catch(error => {
                    console.log(error)
                })


        },

        buscaHospedePorCPF() {
            this.IsCPFInvalido = false

            const filtro = {
                cpf: this.cpfMascara.unmasked

            }

            axios.get('/api/v1/hospedes/', { params: filtro })

                .then(response => {

                    const hsp = response.data[0]

                    if (hsp === undefined) {
                        if (this.hospede_encontrado) {

                            this.limparHospede()
                        }

                    } else {
                        this.nome = hsp.nome
                        this.telefone = hsp.telefone
                        this.estado = hsp.estado
                        this.data_nascimento = hsp.data_nascimento
                        this.email = hsp.email
                        this.hospede_encontrado = true
                    }

                })
                .catch(error => {
                })

            // this.cpf_formatado = cpf

        },

        limparHospede() {
            this.IsCPFInvalido = false
            this.telefone = ''
            this.nome = ''
            this.estado = ''
            this.email = ''
            this.data_nascimento = null
            this.hospede_encontrado = false
        },

        async getCanais() {

            axios.get('api/v1/canais/')
                .then(response => {
                    this.canais = response.data
                    this.canal = this.canais[0].id
                })
        }

    }


}

</script>

<style scoped>
.modal-content {
    border-radius: 0px !important;
}
</style>


<style scoped lang="scss">
#box-acomodacao {
    background-color: $primary-color;
}

.input-invalido {
    border: hsl(348, 86%, 61%) 1px solid;

}

.input-desabilitado {
    color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.5) !important;
}

@media only screen and (max-width: 1024px) {

    #header_criar_reserva,
    #content_criar_reserva,
    #footer_criar_reserva {
        width: 97% !important;
    }




}

@media only screen and (max-width: 760px) {
    #content_criar_reserva {
        width: 90% !important;
        max-height: 70%;
        padding: 1rem !important;
    }
}

#header_criar_reserva,
#content_criar_reserva,
#footer_criar_reserva {
    width: 60rem;
}

#header_criar_reserva {
    border-radius: 10px 10px 0 0;
    background-color: $primary-color;
}

#content_criar_reserva {
    height: 80%;
    overflow-y: auto;
    padding: 4rem;
}

#footer_criar_reserva {
    justify-content: flex-end !important;
}

.box-title {

    background-color: $primary-color;
    border-radius: 10px 10px 0 0;

}


/* Remove as setas do input de número*/
#currency_input::-webkit-outer-spin-button,
#currency_input::-webkit-inner-spin-button {
    display: none;
}

/* Remove o date picker do input da data de nascimento*/
#data-nascimento::-webkit-calendar-picker-indicator {
    display: none;
}
</style>