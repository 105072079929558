<template>
  <div class="input-wrap">
    <label :class="labelClass" v-if="label">{{ label }}</label>
    <div class="control has-icons-left" :class="{ 'half': isHalf }" style="text-align: left;">
      <input type='text' required placeholder="0,00" @click="moverCursorFinal" @input="currencyFormatter"
        :value="modelValue" v-bind="$attrs" :class="inputClass" id="currency-input">
      <span class="icon is-left">
        <p class="fonte-1" :class="iconClass">R$</p>
      </span>
    </div>
  </div>
</template>
<script setup>
defineProps({
  label: {
    type: [String, Boolean],
    default: false,
  },
  inputClass: {
    type: String,
    default: "",
  },
  iconClass: {
    type: String,
    default: "",
  },
  labelClass: {
    type: String,
    default: "",
  },
  isHalf: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: String,
    default: "",
  },

});


</script>


<script>
import SvgIcon from '@jamescoyle/vue-icon';


export default {
  name: 'MoneyInput',
  components: {
    SvgIcon,

  },
  data() {
    return {

    }
  },
  methods: {

    currencyFormatter(event) {


      const element = event.srcElement
      const key = event.data;
      const input = event.inputType;
      var valor = element.value;
      valor = valor.replace(',', '.')
      if ((Number(key) || key === '0') && valor < 9999) {



        //console.log('valor 1º ->', valor);

        // valor = valor * 10;
        //console.log('valor * 10 ->', valor);
        //valor = valor + parseInt(key);
        //console.log('valor + key ->', valor);
        if (valor.length !== 1) {
          valor = valor.slice(0, valor.length - 1)
          //console.log('valor 2° ->', valor);


          valor = valor * 100
          valor = Number.parseFloat(valor).toFixed(0)
          //console.log('valor 3° ->', valor);

          valor = valor + key
          valor = valor / 100;
          //console.log('valor 4° ->', valor);
        } else {
          valor = valor / 100;
          //console.log('valor/100 ->', valor);
        }

        valor = Number.parseFloat(valor).toFixed(2)
        //console.log('valor 5º ->', valor);
        valor = valor.replace('.', ',')
        element.value = valor
        //this.valor_diaria = valor

      }
      else if (input == 'deleteContentBackward') {
        valor = valor / 10
        valor = Number.parseFloat(valor).toFixed(2)
        valor = valor.replace('.', ',')
        element.value = valor
      }

      else {
        // //console.log(element.value)
        valor = valor.replace('.', ',')
        element.value = valor.slice(0, valor.length - 1)

      }

      if (element.value == 0.00) {
        // //console.log(element.value)
        element.value = null
      }
      this.$emit('update:modelValue', event.target.value);


    },

    simpleFormatter(event) {
      const element = event.srcElement

      var valor = element.value;

      valor = Number.parseFloat(valor).toFixed(2)
      valor = valor.replace('.', ',')
      element.value = valor

      this.$emit('update:modelValue', event.target.value);
    },

    moverCursorFinal(event) {

      const end = event.srcElement.value.length;
      event.srcElement.setSelectionRange(end, end)
      event.srcElement.focus()
    }

  }
}

</script>

<style lang="scss" scoped>
.half {
  width: 10rem;
}
</style>