<template>
    <div class="columns is-centered m-0 is-multiline is-mobile" :class="{ 'strech': acomodacoes.length === 0 }"
        id="container">
        <div class="column is-12 p-0" id="content" v-if="acomodacoes.length > 0">
            <div class="columns m-0 is-multiline">
                <div class="column is-12 pb-0 pt-1">
                    <div class="columns is-mobile is-vcentered is-multiline m-0">
                        <div class="column is-narrow pl-0">
                            <div class=" field has-addons">
                                <div class="control">
                                    <button class="button  btn-intervalo is-responsive" @click="this.mes_anterior()">
                                        <span class="icon">
                                            <i class=" icon-intervalo mdi mdi-chevron-left"></i>
                                        </span>
                                    </button>
                                </div>

                                <div class="control">
                                    <button class="button is-responsive  mes-intervalo is-static has-text-weight-bold">
                                        {{ data_inicio_intervalo.toLocaleDateString('pt-BR') }} à {{data_final_intervalo.toLocaleDateString('pt-BR')}}
                                    </button>
                                </div>

                                <div class="control">
                                    <button class=" button  btn-intervalo is-responsive" @click="this.prox_intervalo()">
                                        <span class="icon">
                                            <i class="icon-intervalo mdi mdi-chevron-right"></i>
                                        </span>
                                    </button>
                                </div>
                                <!-- <input type="month" class="input button is-responsive" v-model="mes_intervalo"
                                        @change="setIntervaloMes()" id="input-mes"> -->
                            </div>
                        </div>

                        <div class="column is-narrow pl-0">
                            <div class="control">
                                <button class=" button  btn-intervalo is-responsive" @click="this.resetaIntervalo()">

                                    
                                        <i class="mdi mdi-restart"></i>
                                    
                                    <span class="">
                                         Reiniciar
                                    </span>

                                </button>
                            </div>

                        </div>

                        <div class="column is-narrow pl-0 is-hidden-touch" id="month-picker">
                            <MonthAndYearPicker :is-right="false" v-model="mes_intervalo"
                                :year-array="Array.from({ length: 4 }, (_, i) => data_atual.getFullYear() - 1 + i)"
                                @update:model-value="setIntervaloMes()" />
                        </div>

                        <div class="column is-hidden-touch">
                            <div class="buttons is-right" id="opcoes-escopo">
                                <button class="button is-normal is-responsive"
                                    :class="[this.escopo == 7 ? 'is-escopo-ativo' : '']"
                                    @click="(event) => alteraEscopo(7, event)">

                                    <span class="icon">
                                        <i class="mdi mdi-calendar-range"></i>
                                    </span>
                                    <span>7 dias</span>

                                </button>


                                <button class="button is-normal is-responsive"
                                    :class="[this.escopo == 15 ? 'is-escopo-ativo' : '']"
                                    @click="(event) => alteraEscopo(15, event)">

                                    <span class="icon">
                                        <i class="mdi mdi-calendar-multiselect"></i>
                                    </span>
                                    <span>15 dias</span>

                                </button>


                                <button class="button is-normal is-responsive is-hidden-touch"
                                    :class="[this.escopo > 27 ? 'is-escopo-ativo' : '']"
                                    @click="(event) => alteraEscopo(30, event)">

                                    <span class="icon">
                                        <i class="mdi mdi-calendar-month"></i>
                                    </span>
                                    <span>30 dias</span>

                                </button>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="column is-12 py-0 px-0 " id="map-container">
                    <PopupReserva ref="popupReserva"></PopupReserva>
                    <div class="columns is-multiline is-mobile m-0" id="map">

                        <!-- Cabeçalho do Mapa de Reservas -->
                        <div class="column is-12 py-0 pr-0" id="map-header">
                            <div class="columns is-mobile m-0">

                                <div class="column px-0 is-1" id="uh-dia">
                                    <p class="has-text-centered py-1 px-0 fonte-1 is-size-5 is-size-7-touch">UH/Dia</p>
                                </div>

                                <div class="column col-day px-0 py-1" v-for=" data  in  this.labelDias   "
                                    v-bind:key="data.id">

                                    <p class="day"
                                        :class="[this.escopo > 27 ? 'is-size-7 mt-3' : 'is-size-6-desktop is-size-7-touch mt-1']">
                                        {{ data.mes.toUpperCase() }} {{ data.dia }}
                                    </p>

                                    <p class="day mt-0" :class="[this.escopo > 27 ? 'is-size-7' : 'is-size-6-desktop is-size-7-touch']">
                                        {{ data.dia_semana }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- Corpo do Mapa de Reservas -->
                        <div class="column is-12 py-0" id="map-body">
                            <div class="columns is-mobile m-0">
                                <div class="column is-1 px-0 py-0" id="map-acomods">
                                    <div class="columns is-multiline m-0">
                                        <template v-for="  tipo   in      this.tipos_acomodacoes    "
                                            v-bind:key="tipo.id">
                                            <div class="column is-12 p-0 row-uh uh-tipo" :id="`apt-name`"
                                                v-if="this.getAcomodacoesPorTipo(tipo.id).length > 0">

                                                <p class="my-2 pl-2 is-size-6-desktop is-size-7-touch has-text-left is-clickable"
                                                    @click="esconderUh(tipo.descricao)">
                                                    {{ tipo.descricao }}

                                                    <span class="icon hide-btn ">
                                                        <i class=" mdi mdi-eye" :id="`hide-${tipo.descricao}`" />
                                                    </span>
                                                </p>

                                            </div>

                                            <div class="column is-12 uh-apt m-0 p-0 pr-0" :id="`apt-${apt.id}`"
                                                v-for="   apt    in     this.getAcomodacoesPorTipo(tipo.id)    "
                                                v-show="mostrar[tipo.descricao]" v-bind:key="apt.id">

                                                <p class="is-size-6-desktop is-size-7-touch has-text-left fonte-1 px-2 py-2">
                                                    {{ apt.nome }}
                                                </p>
                                            </div>


                                        </template>
                                    </div>
                                </div>
                                <div class="column is-11 px-0 py-0" id="map-content">

                                    <div class="columns is-mobile is-multiline m-0">

                                        <template v-for="  tipo   in this.tipos_acomodacoes" v-bind:key="tipo.id">
                                            <div class="column  px-0 py-0"
                                                v-for="     data       in          labelDiasEn         "
                                                v-bind:key="data.id"
                                                v-if="this.getAcomodacoesPorTipo(tipo.id).length > 0">
                                                <div class=" cell cell-valor">
                                                    <p class="quartos-livres has-tooltip-bottom has-tooltip-arrow"
                                                        data-tooltip="Acomodações livres"
                                                        :class="[this.escopo > 27 ? 'is-size-6' : 'is-size-6-desktop', 
                                                        contadoresQuartosLivres[tipo.id][data.dia + '-' + data.mes] === 0 ? 'is-full' : '']">

                                                        {{ this.getQuartosLivresPorTipo(new Date(`${data.ano}-${data.mes}-${data.dia}`),tipo.id) }}

                                                    </p>

                                                </div>
                                            </div>

                                            <template v-for=" apt in this.getAcomodacoesPorTipo(tipo.id) "
                                                v-bind:key=" apt">
                                                <div class="column is-12 p-0">
                                                    <div class="columns is-mobile m-0" :id="tipo.descricao"
                                                        v-show="mostrar[tipo.descricao]">

                                                        <div class="column pt-0 pb-0 cell-day p-0"
                                                            :id="`apt${apt.id}-${data.ano}-${data.mes}-${data.dia}-cell`"
                                                            v-for="     data      in      labelDiasEn     "
                                                            v-bind:key="data.id" v-on:click="ativarModalAcao">

                                                            <div class="columns is-mobile cell  mb-0 pb-0 mt-0">

                                                                <div class="column  p-0 is-6">
                                                                    <div class="apt-dia c1"
                                                                        :id="`apt${apt.id}-${data.ano}-${data.mes}-${data.dia}-c1`">
                                                                    </div>
                                                                </div>

                                                                <div class="column p-0 is-6">
                                                                    <div class="apt-dia cell-height c2"
                                                                        :id="`apt${apt.id}-${data.ano}-${data.mes}-${data.dia}-c2`"
                                                                        v-on:mouseover="showButton">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column py-0 is-12" id="map-footer">
                            <div class="columns is-mobile is-centered is-multiline is-vcentered m-0" id="legenda">

                                <!-- <div class="column  has-text-left p-2 is-narrow">
                                    <h1 class="title fonte-2 is-size-5">Legenda: </h1>
                                </div> -->
                                <div class="column py-1 px-1  is-narrow">
                                    <span class="tag  is-pre-reserva">Pré-reserva</span>
                                </div>

                                <div class="column py-1 px-1 is-narrow">
                                    <span class="tag is-confirmada">Confirmada</span>
                                </div>

                                <div class="column py-1 px-1  is-narrow">
                                    <span class="tag is-ocupada">Ocupada</span>
                                </div>

                                <div class="column py-1 px-1 is-narrow">
                                    <span class="tag is-fechada">Fechada</span>
                                </div>

                                <div class="column py-1 px-1 is-narrow">
                                    <span class="tag is-bloqueada">Bloqueada</span>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
        <div class="column is-12" v-else>
            <div class="notification" id="acomod-notification">
                <p class="is-size-5 has-text-left fonte-1">

                    <i class="mdi mdi-information-outline icon"></i>
                    <span>
                        Por favor, configure sua hospedagem na página de
                        <router-link class=" has-text-weight-bold fonte-1" to="/gerenciamento">
                            <span>Gerenciamento</span>
                        </router-link>
                        para visualizar o <strong>Mapa de Reservas.</strong>
                    </span>
                </p>
            </div>
        </div>
    </div>

    <div class="modal" :class="{ 'is-active': this.escolha_ativo }">
        <div class="modal-background" @click="this.escolha_ativo = !this.escolha_ativo"></div>
        <div class="modal-card" id="modal-escolha">
            <section class="modal-card-body">
                <div class="buttons">
                    <button class="button  is-fullwidth btn_escolha is-large " @click="ativarModalCriarReserva">
                        <i class="mdi mr-2 mdi-book-account" /> Criar nova Reserva
                    </button>


                    <button class="button btn_escolha is-large  is-fullwidth" @click="ativarModalAgendarReserva">
                        <i class="mdi mr-2 mdi-wrench" /> Nova Manutenção
                    </button>

                </div>

            </section>
        </div>
        <button class="modal-close is-large" aria-label="close"
            @click="this.escolha_ativo = !this.escolha_ativo"></button>

    </div>


    <ModalConsultarReserva ref="modalConsultarReserva" @reserva-atualizada="this.getReservas" />
    <ModalCriarReserva ref="modalCriarReserva" @reserva-criada="this.getReservas" />
    <ModalConsultarManutencao ref="modalConsultarManutencao" @manutencao-concluida="this.getReservas" />
    <ModalManutencao ref="modalAgendarManutencao" @manutencao-registrada="this.getReservas" />
</template>

<script>


import { mdiEye, mdiEyeOff, mdiArrowLeftThick, mdiConsoleNetwork } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';
import ModalConsultarReserva from '@/components/Modals/ModalConsultarReserva.vue'
import ModalConsultarManutencao from '@/components/Modals/ModalConsultarManutencao.vue';
import ModalCriarReserva from '@/components/Modals/ModalCriarReserva.vue'
import ModalManutencao from '@/components/Modals/ModalManutencao.vue';
import axios from 'axios'
import Navbar from '@/components/layout/Navbar.vue';
import PopupReserva from '@/components/PopupReserva.vue';
import MonthAndYearPicker from '@/components/inputs/MonthAndYearPicker.vue';
import { toast } from 'bulma-toast'



export default {

    name: "MapaReservas",
    components: {
        ModalConsultarReserva,
        ModalConsultarManutencao,
        ModalCriarReserva,
        SvgIcon,
        Navbar,
        ModalManutencao,
        PopupReserva,
        MonthAndYearPicker
    },
    data() {

        return {

            mostrar: {},
            iconPath: mdiEye,
            iconPath2: mdiEyeOff,

            tipos_acomodacoes: [],
            acomodacoes: [],

            reservas: [],
            apt_index: [],

            acomodacao_selecionada: "",
            data_entrada: '',
            data_saida: '',

            escolha_ativo: false,

            max_data_saida: null,
            min_data_saida: null,

            labelDias: '',
            labelDiasEn: '',

            contadoresQuartosLivres: {},

            popupAtivo: false,

            inicio_intervalo: '',
            data_inicio_intervalo: '',
            data_final_intervalo: '',
            mes_intervalo: {},

            escopo: 15,
            isTouch:false,

            quantidade_dias: '',
            dia_atual: '',
            data_atual: '',

            nome_hospede: "",
            cont_quartos: 1,

            cores_status: {

                pre_reserva: 'rgb(255, 189, 33)',
                confirmada: 'rgb(19, 153, 86)',
                ocupada: 'rgb(78, 105, 173)',
                bloqueada: 'rgb(173, 78, 78)',
            }


        }
    },
    computed: {

        getMesAno() {
            //console.log(this.mes_intervalo)
            if (this.mes_intervalo.mes + 1 < 10) {
                return `${this.mes_intervalo.ano}-0${this.mes_intervalo.mes + 1}`
            } else {
                return `${this.mes_intervalo.ano}-${this.mes_intervalo.mes + 1}`
            }


        }
    },

    beforeMount() {

        this.data_atual = new Date()
        this.data_inicio_intervalo = new Date()

        this.data_inicio_intervalo.setHours(0)
        this.data_inicio_intervalo.setMinutes(0)
        this.data_inicio_intervalo.setSeconds(0)

        this.inicio_intervalo = this.data_inicio_intervalo.toLocaleDateString('en-CA')
        this.data_final_intervalo = new Date(this.data_inicio_intervalo.getTime())
        this.data_final_intervalo.setDate(this.data_final_intervalo.getDate() + this.escopo - 1)

        this.quantidade_dias = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0).getDate()



    },

    mounted() {
        this.getTiposAcomodacoes()
        this.get_acomodacoes()
        this.gerarLabelDias(this.data_atual)
        this.checkTouch();

    },

    methods: {
        checkTouch() {
            if (window.innerWidth < 1024) {
                this.alteraEscopo(7)
                this.isTouch = true

            } else{
                this.isTouch = false
            }
        },


        prox_intervalo() {

            this.data_inicio_intervalo.setDate(this.data_inicio_intervalo.getDate() + this.escopo)
            this.data_final_intervalo = new Date(this.data_inicio_intervalo.getTime())
            this.data_final_intervalo.setDate(this.data_inicio_intervalo.getDate() + this.escopo - 1)

            this.quantidade_dias = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0).getDate()

            this.getReservas()
            this.gerarLabelDias(this.data_inicio_intervalo)
        },

        mes_anterior() {

            this.data_inicio_intervalo.setDate(this.data_inicio_intervalo.getDate() - this.escopo)
            this.data_final_intervalo = new Date(this.data_inicio_intervalo.getTime())
            this.data_final_intervalo.setDate(this.data_inicio_intervalo.getDate() + this.escopo - 1)

            this.quantidade_dias = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0).getDate()

            this.getReservas()
            this.gerarLabelDias(this.data_inicio_intervalo)

        },

        resetaIntervalo() {

            if(this.isTouch){
                this.escopo = 7
            }else{
                this.escopo = 15
            }

            this.data_inicio_intervalo = new Date()
            this.data_inicio_intervalo.setHours(0)
            this.data_inicio_intervalo.setMinutes(0)
            this.data_inicio_intervalo.setSeconds(0)

            this.inicio_intervalo = this.data_inicio_intervalo.toLocaleDateString('en-CA')
            this.data_final_intervalo = new Date(this.data_inicio_intervalo.getTime())
            this.data_final_intervalo.setDate(this.data_final_intervalo.getDate() + this.escopo - 1)

            this.quantidade_dias = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0).getDate()

            this.getReservas()

            this.gerarLabelDias(this.data_inicio_intervalo)
        },

        setIntervaloMes() {

            //console.log(this.getMesAno)

            this.data_inicio_intervalo = new Date(this.getMesAno + '-01T00:00')

            this.inicio_intervalo = this.data_inicio_intervalo.toLocaleDateString('en-CA')
            this.data_final_intervalo = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0)

            this.quantidade_dias = new Date(this.data_inicio_intervalo.getFullYear(), this.data_inicio_intervalo.getMonth() + 1, 0).getDate()


            this.escopo = this.quantidade_dias


            this.getReservas()
            this.gerarLabelDias(this.data_inicio_intervalo)
        },

        getReservas() {
            var filtro = {

                reservas_intervalo: `${this.data_inicio_intervalo.toLocaleDateString('en-CA')},${this.data_final_intervalo.toLocaleDateString('en-CA')}`,
                is_fechado: true

            }

            axios
                .get('/api/v1/reservas/', { params: filtro })
                .then(response => {
                    this.reservas = response.data
                    //console.log(this.reservas)
                    this.renderizar_reservas()
                    // this.getQuartosLivresPorTipo(this.data_atual, 1)
                })
                .catch(error => {
                    console.log(error)

                })
        },
        getTiposAcomodacoes() {

            axios
                .get('/api/v1/tiposAcomodacoes/',)
                .then(response => {
                    this.tipos_acomodacoes = response.data

                    this.tipos_acomodacoes.forEach(tipo => {
                        this.mostrar[tipo.descricao] = true
                        this.contadoresQuartosLivres[tipo.id] = {}
                    })


                })
                .catch(error => {
                    console.log(error)

                })


        },

        async get_acomodacoes(id) {

            await axios
                .get('/api/v1/acomodacoes/')
                .then(response => {
                    this.acomodacoes = response.data
                    this.getReservas()

                })
                .catch(error => {
                    console.log(error)
                })
        },

        getAcomodacoesPorTipo(id) {
            return this.acomodacoes.filter(acomodacao => acomodacao.tipo_acomodacao.id === id)
        },

        renderizar_reservas() {

            const celulas = document.querySelectorAll('.c2')
            try {

                if (this.data_final_intervalo.getTime() > this.data_atual.getTime()) {

                    celulas.forEach(celula => {
                        const data = new Date(this.formataData(celula.id) + "T00:00")

                        if (data.getTime() >= this.data_atual.getTime() || data.toLocaleDateString('en-ca') === this.data_atual.toLocaleDateString('en-ca')) {

                            celula.closest('.cell-day').classList.add('free-cell')

                        } else {
                            celula.closest('.cell-day').classList.remove('free-cell')
                        }
                    })
                } else {

                    celulas.forEach(celula => {

                        celula.closest('.cell-day').classList.remove('free-cell')

                    })
                }

            } catch (error) {
                console.log(error)
            }

            // Guarda todas as divs das reservas previamente renderizadas
            var reservas_renderizadas = document.querySelectorAll('.render')

            // Apagando as reservas já renderizadas
            reservas_renderizadas.forEach(elemento => {
                elemento.remove();

            })

            // Percorre o Array de reservas para renderizá-las no Mapa de Reservas
            Array.from(this.reservas).forEach(reserva => {
                try {

                    if (reserva.status === "Cancelada") {
                        return
                    }

                    if (reserva.manutencao) {

                        if (reserva.data_entrada === this.data_atual.toLocaleDateString('en-ca') && reserva.acomodacao.status !== 'Manutenção') {
                            //console.log(reserva.acomodacao.status)
                            this.atualizarManutencaoAcomodacao(reserva.acomodacao)

                        }
                    }

                    const data_entrada = new Date(reserva.data_entrada + "T00:00")
                    const data_saida = new Date(reserva.data_saida + "T00:00")

                    const apt = reserva.acomodacao.id

                    const mes1 = data_entrada.toLocaleString('en', { month: 'short' }).split('.')[0]
                    var dia1 = parseInt(data_entrada.toDateString().split(' ')[2])

                    const ano = data_entrada.getFullYear()


                    const mes2 = data_saida.toLocaleString('en', { month: 'short' }).split('.')[0]
                    var dias_dif = (data_saida - data_entrada) / (1000 * 60 * 60 * 24)


                    var cor = this.get_status_color(reserva.status)

                    if (reserva.manutencao) {
                        cor = this.cores_status.bloqueada
                    }

                    // Cria o elemento div que vai servir de base para as divs das reservas
                    const div_base = document.createElement('div')
                    div_base.classList.add('is-clickable', 'render', `is-${this.formatarStatus(reserva.status)}`)
                    div_base.addEventListener("click", this.ativarModalConsultaReserva)


                    div_base.addEventListener("touchend", this.ativarModalConsultaReserva, { passive: true })

                    div_base.addEventListener('mouseover', this.showPopupReserva)
                    div_base.addEventListener('mouseout', this.hidePopupReserva)
                    div_base.id = reserva.id

                    // Inicializando a variável Id da célula referente ao dia do check-in
                    var id = '';

                    // Verifica se a data de entrada é anterior ao início do intervalo de observação, 
                    if (this.data_inicio_intervalo.getTime() - data_entrada.getTime() > 86400000) { // Se a subtração do tempo em milissegundos das duas datas for maior que um dia...
                        //se sim atribui o início da reserva para o primeiro dia do mês, se não atribuí para o dia normal
                        id = `apt${apt}-${this.data_inicio_intervalo.getFullYear()}-${mes2}-${this.data_inicio_intervalo.getDate()}-c1`
                        if (this.escopo < 28) {
                            dias_dif = Math.round((data_saida - this.data_inicio_intervalo) / (1000 * 60 * 60 * 24)) + 0.5
                        } else {
                            dias_dif = Math.round((data_saida - this.data_inicio_intervalo) / (1000 * 60 * 60 * 24)) + 0.45

                        }

                    } else {
                        id = `apt${apt}-${ano}-${mes1}-${dia1}-c2`
                    }

                    var element = document.getElementById(id)
                    element.style.zIndex = '25';

                    var texto = document.createElement('p')

                    if (reserva.hospede !== null) {

                        texto.textContent = reserva.hospede.nome

                    } else {

                        texto.textContent = "Bloqueada"

                    }

                    texto.classList.add('hsp-text', 'is-clickable')


                    texto.addEventListener("click", this.ativarModalConsultaReserva)
                    texto.id = reserva.id
                    texto.style.textAlign = 'left'
                    texto.style.zIndex = '15'

                    div_base.appendChild(texto)


                    let qtd_dias = this.quantidade_dias
                    let dt_entrada = data_entrada
                    let dt_saida = data_saida
                    let i_intervalo = this.data_inicio_intervalo
                    let f_intervalo = this.data_final_intervalo
                    let escopo = this.escopo

                    const resize_ob = new ResizeObserver(function (entries) {
                        try {
                            // Obtém o elemento que será observado
                            let rect = entries[0].contentRect;
                            let width = rect.width

                            if (dt_entrada.getTime() === f_intervalo.getTime()) {
                                // Se a data de entrada for no último dia
                                div_base.style.width = (width) + 'px'
                                div_base.style.right = 0
                                div_base.style.left = 0

                            } else if (dt_saida.getTime() > f_intervalo.getTime()) {
                                // Se a data de saída for após o final do intervalo, calcula o tamanho da div até o final do mapa;
                                div_base.style.width = ((width * (i_intervalo.getDate() + escopo - dt_entrada.getDate()) * 3.2)) + 'px'
                                div_base.style.right = 0
                                div_base.style.left = 0



                            } else {

                                let valor;
                                let diferencaBordas = ((dias_dif - 1) * 4 * 0.5)
                                let espaco = 4

                                if (escopo >= 28) {
                                    espaco = 1
                                }

                                valor = (width * (dias_dif) * 2) + diferencaBordas - espaco

                                div_base.style.width = valor + 'px'
                                div_base.style.minWidth = valor + 'px'
                                div_base.style.maxWidth = valor + 'px'

                            }
                        } catch (error) {
                            console.log(error)
                        }
                    });

                    resize_ob.observe(element)
                    element.appendChild(div_base)
                    var inicio;
                    var final;

                    inicio = data_entrada.getDate()
                    final = data_saida.getDate()

                    // Desabilita a função de criar reserva dos dias reservados
                    var data_aux = new Date(data_entrada.toLocaleDateString('en-ca') + "T00:00")

                    if (data_entrada.getDate() === this.quantidade_dias) {

                        inicio = final = this.quantidade_dias
                        element.classList.remove('free-cell')

                    } else if (data_entrada.getTime() < this.data_inicio_intervalo.getTime()) {

                        inicio = this.data_inicio_intervalo.getDate()
                        data_aux = new Date(this.data_inicio_intervalo.toLocaleDateString('en-ca') + "T00:00")

                    }

                    if ((data_saida.getMonth() > data_entrada.getMonth() && data_entrada.getFullYear() === data_saida.getFullYear())) {

                        final = qtd_dias + 1

                    } else if (data_saida.getFullYear() > data_entrada.getFullYear() && data_saida.getTime() < this.data_final_intervalo.getTime()
                        && data_entrada.getTime() > this.data_inicio_intervalo.getTime()) {

                        final = qtd_dias + data_saida.getDate()

                    } else if (data_saida.getTime() > this.data_final_intervalo.getTime()) {

                        final = this.data_final_intervalo.getDate() + 1
                    }

                    // //console.log(inicio, '->', final)

                    try {
                        for (let i = inicio; i < final; i++) {
                            var c2;

                            c2 = document.getElementById(`apt${apt}-${data_aux.getFullYear()}-${data_aux.toLocaleDateString('en', { month: 'short' })}-${data_aux.getDate()}-c2`)

                            c2.closest('.cell-day').classList.remove('free-cell')

                            data_aux.setDate(data_aux.getDate() + 1)

                        }

                    } catch (error) {
                        //console.log(reserva)
                        //console.log(`apt${apt}-${data_aux.getFullYear()}-${data_aux.toLocaleDateString('en', { month: 'short' })}-${data_aux.getDate() + 1}-c2`)
                        //console.log(data_aux.toLocaleDateString('en-ca'))
                        console.log(error)
                    }

                } catch (error) {
                    //console.log(id)
                    //console.log(reserva)
                    console.log(error)
                }

            });

        },

        async atualizarManutencaoAcomodacao(acomodacao) {
            acomodacao.status = 'Manutenção'

            axios.patch(`/api/v1/acomodacoes/${acomodacao.id}/`, acomodacao)
                .then(response => {

                    var msg = `<i class='mdi mdi-check-circle mr-2'><span><strong> Atualização:</strong>`
                    msg += ` Status da acomodação <strong>${acomodacao.nome}</strong> alterada para <strong>Manutenção</strong>.</span>`
                    msg += ` <a  href="/acomodacoes">Ver Acomodações</a>`

                    toast({
                        message: msg,
                        type: 'is-info is-light',
                        position: 'top-right',
                        duration: 60000,
                        closeOnClick: false,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                })

                .catch(error => {
                    console.log(error)
                })
        },

        criarIntervalo(inicio, fim) {
            return Array.from({ length: fim - inicio + 1 }, (_, index) => index + inicio);
        },
        gerarLabelDias(inicio) {

            this.labelDias = []
            this.labelDiasEn = []
            this.fcaoPorDia = []

            const data = new Date(inicio.toLocaleDateString('en-ca') + "T00:00")

            for (var i = 0; i < this.escopo; i++) {

                this.labelDias.push({
                    id: i, ano: data.getFullYear(), dia: data.getDate(), mes: data.toLocaleString('pt-br', { month: 'short' }).split('.')[0], dia_semana: data.toLocaleString('pt-br', { weekday: 'short' })
                })

                this.labelDiasEn.push({ id: i, ano: data.getFullYear(), dia: data.getDate(), mes: data.toLocaleString('en', { month: 'short' }).split('.')[0] })

                data.setDate(data.getDate() + 1)

            }


        },

        getQuartosLivresPorTipo(dia, idTipo) {

            var count = 0
            const total = this.getAcomodacoesPorTipo(idTipo).length
            dia.setHours(0)
            dia.setMinutes(0)
            dia.setSeconds(0)


            let quantidade;
            if (total > 0) {
                this.reservas.forEach(reserva => {

                    const checkin = new Date(reserva.data_entrada + "T00:00")
                    const checkout = new Date(reserva.data_saida + "T00:00")

                    if ((checkin.getTime() == dia.getTime() || (checkin.getTime() <= dia.getTime() && dia.getTime() < checkout.getTime())) && reserva.acomodacao.tipo_acomodacao.id === idTipo
                        && reserva.status !== 'Cancelada') {
                        count++
                    }

                })

                quantidade = total - count

            }
            else {
                quantidade = 0;
            }
            var dia_str = dia.getDate() + '-' + dia.toLocaleString('en', { month: 'short' }).split('.')[0]
            this.contadoresQuartosLivres[idTipo][dia_str] = quantidade
            return quantidade;


        },

        ativarModalAcao(event) {

            const element = event.srcElement.closest('.cell-day')
            // //console.log(element)
            if (element.classList.contains('free-cell')) {

                // Correção para o conflito entre consultar reserva e ativar modal de escolha
                // para reservas com uma renderização muito pequena
                if (this.popupAtivo) {
                    return
                }

                const div = document.getElementById(element.id)

                this.data_entrada = this.formataData(div.id)
                this.data_saida = this.incrementarDias(this.data_entrada, 1)

                this.acomodacao_selecionada = this.buscaAcomodacaoPorId(div.id.split('apt')[1].split('-')[0])


                axios.post('api/v1/reservas/get_data_limite/', { data: this.data_entrada, id_acomodacao: this.acomodacao_selecionada.id })
                    .then(response => {

                        this.max_data_saida = response.data.data_limite

                        this.escolha_ativo = true

                    })
                    .catch(error => {
                    })

            }
        },

        ativarModalCriarReserva() {
            this.escolha_ativo = !this.escolha_ativo
            this.$refs.modalCriarReserva.ativarModal(this.acomodacao_selecionada, this.data_entrada, this.data_saida, this.data_saida, this.max_data_saida)
        },

        ativarModalAgendarReserva() {
            this.escolha_ativo = !this.escolha_ativo
            this.$refs.modalAgendarManutencao.ativarModal(this.acomodacao_selecionada, this.data_entrada)
        },

        ativarModalConsultaReserva(event) {


            const id = event.srcElement.id

            const reserva = this.buscarReservaPorId(id)

            if (!reserva.manutencao) {
                this.$refs.modalConsultarReserva.ativarModal(reserva)
            } else {
                this.$refs.modalConsultarManutencao.ativarModal(reserva)
            }
        },

        showPopupReserva(event) {

            const reserva = this.buscarReservaPorId(event.srcElement.id)

            var data_entrada = new Date(reserva.data_entrada + 'T00:00')
            var esquerda = this.data_final_intervalo.getTime() - data_entrada.getTime() > 432002000
            this.popupAtivo = true
            this.$refs.popupReserva.show(event.x, event.y, esquerda, reserva)

        },

        hidePopupReserva() {
            this.popupAtivo = false
            this.$refs.popupReserva.hide()
        },

        removeButton(event) {
            const element = event.srcElement

            if (element.className.indexOf('free-cell') !== -1) {

                const div = document.getElementById(event.srcElement.id)

            }

        },

        buscaAcomodacaoPorId(id) {

            return this.acomodacoes.filter(acomodacao => acomodacao.id === parseInt(id))[0]
        },

        buscarReservaPorId(id) {

            return this.reservas.filter(reserva => reserva.id === parseInt(id))[0]
        },

        formataData(id) {

            const data = id.split('-')


            let dateInput = new Date(`${data[2]} ${data[1]} ${data[3]}`)

            return dateInput.toLocaleDateString('en-CA');
        },

        incrementarDias(string, dias) {

            const dataInc = new Date(string + "T00:00")

            dataInc.setDate(dataInc.getDate() + 1)

            var mes = dataInc.getMonth() + 1
            mes = mes >= 10 ? mes : '0' + mes

            var dia = dataInc.getDate()

            dia = dia >= 10 ? dia : '0' + dia

            return dataInc.getFullYear() + '-' + mes + '-' + dia

        },

        diferencaDias(d1, d2) {

            const data1 = new Date(d1)
            const data2 = new Date(d2)

            return Math.ceil(Math.abs(data2 - data1) / (1000 * 60 * 60 * 24));

        },

        get_status_color(string) {

            if (string === 'Pré-reserva') {
                return this.cores_status.pre_reserva
            }

            if (string === 'Confirmada') {

                return this.cores_status.confirmada

            }

            if (string === 'Ocupada') {

                return this.cores_status.ocupada

            }

            if (string === 'Fechada') {

                return this.cores_status.bloqueada

            }


        },

        esconderUh(tipoUh) {

            this.mostrar[tipoUh] = !this.mostrar[tipoUh]

            const el = document.getElementById(`hide-${tipoUh}`);

            el.style.opacity = this.mostrar[tipoUh] ? '100%' : '50%';
            el.closest('p').style.opacity = this.mostrar[tipoUh] ? '100%' : '50%';

            el.classList.toggle('mdi-eye')

            el.classList.toggle('mdi-eye-off')

        },

        alteraEscopo(quantidade, event) {

            if (this.escopo !== quantidade) {

                this.escopo = quantidade

                this.data_final_intervalo = new Date(this.data_inicio_intervalo.getTime())
                this.data_final_intervalo.setDate(this.data_inicio_intervalo.getDate() + this.escopo - 1)

                this.gerarLabelDias(this.data_inicio_intervalo)
                this.getReservas()
            }

        },
        formatarStatus(status) {
            return status.toLowerCase().replace('é', 'e')
        }

    },



}



</script>

<style lang="scss" scoped>



@media screen and (max-width: 768px) {

    *,
    *::before,
    *::after {
        box-sizing: content-box !important;
    }
    .columns {
        width: auto;
    }
    
}

.columns{
    width: 100%;
}

#title {
    height: 7dvh;
    background-color: $primary-color;
}

#container {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: auto;
    position: relative;
}

#modal-escolha {
    background-color: white;
    align-items: center !important;
    bottom: 5%;
    height: fit-content;
    width: 25rem;
    border-radius: 15px;

}

.cell-info {
    min-height: 60px;

}

.mes-intervalo {
    border: $primary-color 2px solid !important;
    color: $primary-color !important;
    background-color: #ffffff !important;

}

.btn-intervalo {
    border: $primary-color 2px solid !important;
    background-color: $primary-color;
    font-family: 'Poppins';
    color: $secondary-color;
}

.btn-intervalo:hover {
    background-color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light);
    box-shadow: $box-shadow-color 1px 1px 5px;
    color: $secondary-color;
}

.btn-intervalo:focus {
    box-shadow: $primary-color 1px 1px 5px;
    color: $secondary-color;
}


.icon-intervalo::after {
    bottom: 0% !important;

    top: 0% !important;
    float: none;
}

#field-mes {
    border: $primary-color 1px solid !important;
    border-radius: 5px;
}

#input-mes {
    background-color: $primary-color !important;
    border: $primary-color 2px solid !important;

}

#input-mes[type="month"]::-webkit-inner-spin-button,
#input-mes[type="month"]::-webkit-calendar-picker-indicator {
    /* display: none;
    -webkit-appearance: none; */
    cursor: pointer !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin: 0;
    filter: invert(100%);
}


#input-mes[type="month"]::-webkit-datetime-edit {
    display: none;
}

#opcoes-escopo button {
    background-color: $primary-color;
    color: $secondary-color;
    font-weight: 400;
}

#opcoes-escopo button:focus {
    border: $primary-color 1px solid;
    font-weight: 400;
}

#opcoes-escopo button:hover {
    background-color: $primary-color;
    opacity: 1 !important;
    box-shadow: 2px 2px 5px black;
    color: $secondary-color;
}

#opcoes-escopo:has(button.is-escopo-ativo) button:not(.is-escopo-ativo) {
    opacity: 0.6;
}


#uh-text {
    font-weight: bold;
    font-family: 'Poppins';
}

.col-day {
    position: relative;
    text-align: center;
    font-family: 'Poppins';
    background-color: $primary-color;
    border-right: #d9d9d9 0.5px solid;
}

.col-day:first-child {
    border-left: $primary-color 5px solid !important;

}

.col-day:last-of-type {
    border-radius: 0px 5px 0px 0px;
    border-right: 0px !important;

}


.day {
    color: $secondary-color;
    font-family: 'Poppins';
    font-weight: 400;
}

.cell-height {
    height: 42px;
}

.cell {
    border-left: 0.25px rgb(65, 31, 45, 0.25) solid;
    border-right: 0.25px rgb(65, 31, 45, 0.25) solid;
    border-top: 0.5px rgb(65, 31, 45, 0.25) solid;
    border-bottom: 0.5px rgb(65, 31, 45, 0.25) solid;
    height: 42px;
    z-index: -999;
    margin: 0;
    padding: 0;
}


.c2 {
    position: relative !important;
    z-index: 30;
}

.apt-dia {
    position: relative;
    z-index: 10;
}


:deep(.render) {

    overflow: hidden;
    transform: skew(-25deg, 0deg);
    border-radius: 2px;
    height: 30px;
    top: 5px;
    bottom: 0;
    position: absolute;
    z-index: 33;
    transform-style: preserve-3d;

}

:deep(.render:hover) {

    box-shadow: $primary-color 1px 1px 5px;

}

:deep(.hsp-text) {
    font-size: 16px;
    position: absolute;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 4px;
    transform: skew(25deg, 0deg);
    letter-spacing: 1px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    width: fit-content;
    white-space: nowrap;
    z-index: 30 !important;
}

#acomod-notification {

    display: flex;

}

#map-footer {
    padding-left: 0px;

}

#legenda {
    border-color: $primary-color solid 1px;
    border-radius: 0 0 5px 5px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.box-info {
    box-shadow: #0000004d 1px 1px 5px;
}

#map{
    position: relative;
}

#map-body {
    z-index: 15;
    top: 10rem;
    padding-right: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 65dvh !important;
    //min-height: 65dvh !important;
}

#map-days {
    border-top: $primary-color 2px solid;
    border-right: $primary-color 1px solid;

    z-index: 10;

}

#map-body::-webkit-scrollbar {

    width: 0;
    height: 0;

}

#map-body::-webkit-scrollbar-thumb {

    background-color: $primary-color;
    background-clip: padding-box;
    border: 0.05em solid #eeeeee;
    border-radius: 10px;
}

#map-body::-webkit-scrollbar-track {

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}


.uh-apt,
.uh-tipo {
    position: relative;
    border: 0.5px rgb(65, 31, 45, 0.25) solid;
    font-family: 'Poppins';
    min-height: 42px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

}

.uh-apt p,
.uh-tipo p {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}

.uh-apt {
    background-color: $secondary-color;
    z-index: 500;

}

.uh-apt p {
    color: $primary-color;
    font-weight: bold;
}

.uh-tipo {

    background-color: $primary-color;
    color: $secondary-color;
    font-weight: 400;
    border-bottom: 0;
}


#uh-dia {
    border: 1px $primary-color solid;
    background-color: white;
    border-radius: 5px 0 0 0;

}

#map-labels {

    border: $primary-color 1px solid;
    border-radius: 10px 2px 2px;

}


.cell-valor {
    background-color: $secondary-color !important;
    text-align: center !important;
    height: 42px;
    line-height: 39px;

}

.quartos-livres {
    color: $primary-color;
    font-weight: bold;
    font-family: 'Poppins';
}

.is-full {
    color: red !important;
}

.is-size-8 {
    font-size: 0.5rem !important;
}

.div-tipo-uh {
    background-color: $primary-color;
}

.tipo-uh {
    color: #FAEBD7;
    font-family: 'Poppins';
}


@keyframes fadeIn {

    100% {
        display: none;
    }


}


.btn_escolha {
    background-color: white;
    border-radius: 20px;
    border: 1px $primary-color solid;
    color: $primary-color;
    font-family: 'Poppins';

}

.btn_escolha:hover {

    background-color: $primary-color;
    color: $secondary-color;
}


[data-tooltip] {

    &::before,
    &::after {
        font-family: "Poppins" !important;
        font-weight: normal !important;
    }
}



.free-cell:hover {
    background-color: rgb(0, 255, 47, 0.3);
    cursor: pointer;

}



.add_button {

    display: none;

}

.hide-btn {
    background-color: $primary-color !important;
    transition: 0.3s;

}

.hide-btn:hover {
    transform: 0;
}

.hide-btn:focus {
    box-shadow: none !important;
}

.free-cell:hover+.button,
.button:hover {
    display: inline-block;
}


.free-cell:hover+.button,
.button:hover {
    display: inline-block;
}


.info-pre {

    background-color: rgb(255, 189, 33);
}

.info-conf {

    background-color: rgb(19, 153, 86);

}

.info-ocup {

    background-color: rgb(78, 105, 173);

}

.info-bloq {

    background-color: rgb(173, 78, 78);

}
</style>