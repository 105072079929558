<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-tipoUHs">
            <header class="modal-card-head">
                <p class="modal-card-title ml-1 fonte-2 has-text-left">Editar Tipo</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>
            </header>

        </div>
        <div class="modal-content has-background-white p-5" id="body-tipoUHs">
            <section>

                <form @submit.prevent="nova_tipoUH ? salvarNovatipoUH() : atualizartipoUH()" id="form-tipoUH">

                    <div class="field is-grouped ">
                        <div class="control is-expanded">
                            <label class="label has-text-left fonte-1" for="input-nome">Nome:</label>
                            <input class="input fonte-1" @input="verificarAlteracoes" required
                                v-model="tipoUH_form.descricao" type="text">
                        </div>


                        <MoneyInput ref="money" @input="verificarAlteracoes"
                            inputClass="input has-text-left fonte-1 money_input"
                            labelClass="label has-text-left fonte-1" label="Valor base:"
                            v-model="tipoUH_form.valor_base" />
                    </div>

                    <hr class="">

                    <div class="field is-grouped is-grouped-right">
                        <div class="control is-expanded">
                            <button type="button" class="button is-normal is-fullwidth " @click="modal_ativo = false">
                                <span>
                                    Cancelar
                                </span>
                            </button>
                        </div>

                        <div class="control is-expanded">
                            <button class="button is-info is-fullwidth  is-normal" form="form-tipoUH"
                                :disabled="!alteracaoFeita">
                                <i class="icon mdi mdi-content-save"></i>
                                <span>Salvar</span>
                            </button>
                        </div>
                    </div>

                </form>



            </section>
        </div>

    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';



export default {
    name: 'modalEditarTipoUH',
    data() {
        return {
            modal_ativo: false,

            edicao_desabilitada: false,

            tipoUH_selecionado: null,
            tipoUH_form: {
                descricao: '',
                valor_base: ''
            },
            tipoUH_form_copy: null,
            alteracaoFeita: false,

        };
    },

    emits: ['tipoUhAtualizada'],
    methods: {
        ativarModal(tipoUH) {
            this.modal_ativo = true;
            this.selecionartipoUH(tipoUH)
            this.descricao = '';

        },

        selecionartipoUH(tipoUH) {

            this.tipoUH_selecionado = tipoUH
            this.tipoUH_form = Object.assign({}, this.tipoUH_selecionado)

            var valor_f = Number.parseFloat(tipoUH.valor_base).toFixed(2)
            valor_f = valor_f.replace('.', ',')

            this.tipoUH_form.valor_base = '' + valor_f

            this.tipoUH_form_copy = Object.assign({}, this.tipoUH_form)

        },

        verificarAlteracoes() {

            if (JSON.stringify(this.tipoUH_form) !== JSON.stringify(this.tipoUH_form_copy)) {

                this.alteracaoFeita = true;

            } else {
                this.alteracaoFeita = false;
            }

        },

        atualizartipoUH() {

            this.tipoUH_selecionado.descricao = this.tipoUH_form.descricao
            this.tipoUH_selecionado.valor_base = Number.parseFloat(this.tipoUH_form.valor_base).toFixed(2)

            axios.patch(`api/v1/tiposAcomodacoes/${this.tipoUH_selecionado.id}/`, this.tipoUH_selecionado)
                .then(response => {

                    const msg = `<i class='mdi mdi-check-circle mr-2'><span>  O tipo UH <strong>${this.tipoUH_form_copy.descricao}</strong> foi atualizado com sucesso!</span>`

                    toast({
                        message: msg,
                        type: 'is-info is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                    this.$emit('tipoUhAtualizada')
                    this.modal_ativo = false

                })
                .catch(error => {
                    console.log(error)
                })

        },

    },
    components: { MoneyInput }
}

</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1024px) {

    #header-tipoUHs,
    #body-tipoUHs {
        width: 97% !important;

    }
}

#header-tipoUHs,
#body-tipoUHs {
    width: 450px;

}



.box-tipoUHs {
    border: #411F2D 1px solid;
    min-height: 20rem;
    max-height: 30rem;
}

.box-change {
    background-color: #411F2D;
    height: 100%;

}

#tipoUHs button {

    border: #411F2D 1.5px solid;
    transition: 0.3s;

}


#tipoUHs button:focus {
    border: #411F2D 1.5px solid;
    box-shadow: none !important;
    outline: none !important;
}



#tipoUHs button:hover {
    background-color: gray;
    color: antiquewhite !important;
}

#tipoUHs button:hover span {
    color: antiquewhite !important;
}


#tipoUHs:has(button.ativado) button:not(.ativado) {
    opacity: 0.7;
}

#tipoUHs:has(button.ativado) button:is(.ativado) {
    background-color: #411F2D;
    color: antiquewhite;
}
</style>