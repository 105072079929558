<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="columns is-centered">
                <div class="column is-4 ">
                    <div class=" box p-6 form-box">
                        <p class="fonte-1 is-size-5 title has-text-left">Defina sua nova senha de acesso</p>
                        <hr>
                        <form @submit.prevent="submitForm">

                            <div class="field">
                                <label class="label fonte-1 has-text-left" for="new-password1">Nova senha:</label>
                                <!-- <div class="control has-icons-left">
                                    <i class="icon  mdi mdi-lock pt-1"></i>
                                    <input type="password" class="input" id="new-password1" name="new-password1"
                                        placeholder='**********' required v-model="novaSenha1">
                                </div> -->
                                <InputSenha class="fonte-1" v-model="novaSenha1" />
                            </div>
                            <br>
                            <div class="field">
                                <label class="label fonte-1 has-text-left" for="new-password2">
                                    Repita sua nova Senha:
                                </label>
                                <!-- <div class="control has-icons-left ">
                                    <i class="icon mdi mdi-lock pt-1"></i>
                                    <input type="password" name="new-password2" placeholder="**********" required
                                        class="input " v-model="novaSenha2">
                                </div> -->
                                <InputSenha class="fonte-1" v-model="novaSenha2" />
                            </div>

                            <br>
                            <div class="notification is-danger is-light has-text-left " v-if="erros.length">
                                <ul>
                                    <li v-for="error in erros" class="is-size-6 mb-2" v-bind:key="error">{{ error }}
                                    </li>
                                </ul>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button class="button is-success fonte-1 is-fullwidth"
                                        :class="{ 'is-loading': this.carregando }" id="btn-enviar">
                                        <span class="icon-text">
                                            <span class="">
                                                Salvar nova senha
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="field is-grouped is-grouped-centered is-grouped-multiline">
                                <div class="control is-expanded">
                                    <a class="is-success fonte-1 has-text-weight-bold "
                                        :class="{ 'is-loading': this.carregando }" @click="logout"
                                        v-if="!this.$store.state.senhaAlterada">
                                        <p class="has-text-left has-text-danger mt-4">
                                            <span class="icon-text ">
                                                <i class="icon mdi mdi-logout"></i>
                                                <span class="">
                                                    Sair
                                                </span>
                                            </span>
                                        </p>
                                    </a>
                                    <a class="is-success fonte-1 has-text-weight-bold "
                                        :class="{ 'is-loading': this.carregando }" @click="this.$router.back()" v-else>
                                        <p class="has-text-left mt-4">
                                            <span class="icon-text ">

                                                <span class="">
                                                    Voltar
                                                </span>
                                            </span>
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toast } from 'bulma-toast';
import axios from 'axios';
import InputSenha from '@/components/inputs/InputSenha.vue';

export default {
    name: "alterarSenha",
    components: { InputSenha },
    data() {
        return {
            novaSenha1: '',
            novaSenha2: '',
            erros: [],
            carregando: false
        }
    },
    methods: {
        async submitForm() {
            this.erros = []
            this.carregando = true
            if (this.novaSenha1 === this.novaSenha2) {
                await axios.post('/api/v1/usuarios/alterar-senha/', { nova_senha: this.novaSenha2 })

                    .then(response => {

                        this.$store.commit('setSenhaAlterada', true)
                        localStorage.setItem('isSenhaAlterada', true)

                        const msg = `<i class='mdi mdi-lock mr-2'><span>  Sua senha foi alterada com sucesso.</span>`

                        toast({
                            message: msg,
                            type: 'is-success is-light',
                            position: 'top-right',
                            duration: 3000,
                            dismissible: false,
                            pauseOnHover: true,
                        })
                        this.$router.push('/painel')

                    })
                    .catch(error => {
                        if (error.response?.status === 400 || error.response?.status === 401) {
                            this.erros = error.response.data[0]
                        } else {
                            console.log(error)
                        }
                    })
            } else {

                this.erros.push('As senhas não conferem')
            }
            this.carregando = false
        },
        logout() {

            this.$store.commit('logoutUser')
            this.$store.commit('removeToken')
            this.$router.push('log-in')

        }
    },

}

</script>


<style lang="scss" scoped>
.hero-body {
    background: rgb(122, 46, 46);
    background: linear-gradient(100deg, rgba(122, 46, 46, 1) 0%, rgba(87, 18, 18, 1) 100%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    align-items: center !important;
}

.columns {
    width: 100%;
}


#btn-enviar {
    background-color: rgb(122, 46, 46);
    color: $secondary-color;
    font-family: 'Poppins';
}

#btn-enviar {
    display: block !important;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.form-box {
    border-radius: 30px;
    box-shadow: 6px 6px 2px $primary-color;
}

button:hover {
    display: block !important;
}
</style>