<template>
    <div class="columns is-multiline">
        <div class="column is-12 has-text-left">
            <h1 class="title fonte-1 is-size-4-touch pb-1">
                <i class="icon mdi mdi-tag espelhado mr-2" />
                Tipos de Acomodação
            </h1>
            <h2 class="subtitle is-size-6-touch fonte-1">Gerencie os Tipos de Acomodação de sua hospedagem.</h2>
            <hr class="my-2">
        </div>

        <div class="column is-12 has-text-left py-2">
            <button class="button is-medium is-responsive is-success" @click="this.$refs.modalAdicionarTipoAcomodacao.ativarModal()">
                <i class="mdi mdi-plus icon" />
                <span>Adicionar</span>
            </button>

        </div>

        <div class=" column is-6-desktop" v-if="tiposAcomodacao.length > 0">

            <div class="table-container">

                <table class="table is-fullwidth">

                    <thead class="thead_color">

                        <th class="has-text-left">Nome</th>
                        <!-- <th class="has-text-centered">Codigo</th> -->
                        <th class="has-text-left">Valor Base</th>
                        <!-- <th class="has-text-centered ">Arquivado</th> -->
                        <th class="has-text-centered "></th>

                    </thead>

                    <tbody>
                        <tr v-for="tipo in tiposAcomodacao" v-bind:key="tiposAcomodacao.id"
                            :class="{ 'tr-arquivado': tipo.arquivado }">
                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': tipo.arquivado }">
                                <span>
                                    {{ tipo.descricao }}
                                </span>
                                <span v-if="tipo.arquivado">
                                    (<i class="icon mdi mdi-archive" /> Arquivado)
                                </span>
                            </td>

                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': tipo.arquivado }">
                                {{valorBaseBRL(tipo.valor_base) }}
                            </td>
                    
                            <td class="has-text-centered fonte-1">
                                <div class="buttons is-right">
                                    <button class="button is-small"
                                        @click="this.$refs.modalEditarTipoAcomodacao.ativarModal(tipo)"
                                        v-if="!tipo.arquivado">

                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-pencil icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch has-text-weight-bold">
                                             <i class="mdi mdi-pencil icon" />
                                             <span>
                                                 Editar
                                             </span>
                                         </span>

                                    </button>
                                    <button class="button is-small is-dark" v-if="!tipo.arquivado"
                                        @click="tipoSelecionado = tipo; modalArquivarAtivo = true">
                                  
                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-archive icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch">
                                             <i class="mdi mdi-archive icon" />
                                             <span>
                                                 Arquivar
                                             </span>
                                         </span>

                                    </button>
                                    <button class="button is-small is-dark" v-else
                                        @click="tipoSelecionado = tipo; modalDesarquivarAtivo = true">

                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-archive-arrow-up icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch">
                                             <i class="mdi mdi-archive-arrow-up icon" />
                                             <span>
                                                 Recuperar
                                             </span>
                                         </span>

                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>

        <div class="column is-12" v-else>
            <div class="notification" >
                <p class="fonte-1 is-size-5">
                    <i class="icon mdi mdi-information-outline"></i>
                    <span>Você ainda não cadastrou nenhum <strong>Tipo de Acomodação</strong>.</span>
                </p>
            </div>
        </div>
    </div>

    <div class="modal is-active" v-if="modalArquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left fonte-1  is-size-5 ">

                    Deseja <strong>arquivar</strong> o tipo de acomodação <span class="has-text-weight-bold">
                    {{tipoSelecionado.descricao}}</span> ?

                </p>
                <hr>
                <div class="field is-grouped is-grouped-centered">

                    <div class="control is-expanded">
                        <button class="button is-fullwidth has-text-weight-bold "
                            @click="modalArquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="arquivarTipoAcomodacao">
                            <i class="icon mdi mdi-archive"></i>
                            <span>Arquivar</span>
                        </button>
                    </div>

                </div>
            </section>
        </div>
    </div>

    <div class="modal is-active" v-if="modalDesarquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left fonte-1  is-size-5 ">
                    Deseja <strong>recuperar</strong> o tipo de UH <span class="has-text-weight-bold">
                        {{ tipoSelecionado.descricao }}</span> ?
                </p>
                <hr>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control is-expanded">
                        <button class="button is-fullwidth  has-text-weight-bold "
                            @click="modalDesarquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="desarquivarTipoAcomodacao">
                            <i class="icon mdi mdi-archive-arrow-up" />
                            <span>Recuperar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>

   

    <ModalEditarTipoAcomodacao ref="modalEditarTipoAcomodacao" @tipo-uh-atualizada="this.gettiposAcomodacao()" />
    <ModalAdicionarTipoAcomodacao ref="modalAdicionarTipoAcomodacao" @novo-tipo-adicionado="this.gettiposAcomodacao()" />
</template>

<script>
import axios from 'axios';
import ModalAdicionarTipoAcomodacao from "@/components/Modals/ModalAdicionarTipoAcomodacao.vue"
import ModalEditarTipoAcomodacao from './Modals/ModalEditarTipoAcomodacao.vue';
import { toast } from 'bulma-toast'

export default {
    name: 'tabtiposAcomodacao',


    data() {
        return {
            tiposAcomodacao: [],
            tipoSelecionado: {},
            modalArquivarAtivo: false,
            modalDesarquivarAtivo: false,
            mostarArquivados: false,
        };
    },
    mounted() {
        this.gettiposAcomodacao();
    },
    computed: {},
    methods: {
        async gettiposAcomodacao() {
            await axios.get(`api/v1/tiposAcomodacoes/get-all-tipos/`)
                .then(response => {

                    //console.log(response)
                    this.tiposAcomodacao = response.data;
                });
        },


        async arquivarTipoAcomodacao() {

            this.tipoSelecionado.arquivado = true

            await axios.patch(`api/v1/tiposAcomodacoes/${this.tipoSelecionado.id}/`, this.tipoSelecionado)
                .then(response => {
                    this.gettiposAcomodacao()
                    this.modalArquivarAtivo = false

                    const msg = `<i class='mdi mdi-archive mr-2'><span> <strong>${this.tipoSelecionado.descricao}</strong> foi arquivado.</span>`

                    toast({
                        message: msg,
                        type: ' is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,


                    })
                })


        },


        async desarquivarTipoAcomodacao() {
            await axios.post(`api/v1/tiposAcomodacoes/desarquivar-tipo/`, { id: this.tipoSelecionado.id })
                .then(response => {
                    this.gettiposAcomodacao()
                    this.modalDesarquivarAtivo = false

                    const msg = `<i class='mdi mdi-archive-arrow-up mr-2'><span>  <strong>${this.tipoSelecionado.descricao}</strong> foi recuperado.</span>`

                    toast({
                        message: msg,
                        type: 'is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })
        },

        valorBaseBRL(valor) {
            return valor.toLocaleString('pt-br', { style: 'currency', currency: 'brl' });
        },
    },
    components: { ModalAdicionarTipoAcomodacao, ModalEditarTipoAcomodacao }
}

</script>
<style>
.full-width-toast {
    width: 100% !important;
    padding: 0.5rem !important;
}
</style>

<style lang="scss" scoped>

@media screen and (max-width: 763px) {
    table .button {
        width: auto !important;
        aspect-ratio: 1;
    }
    .modal-card-body {
        border-radius: 5px;
        margin: 1rem !important;
        width: auto !important;
    }
}

.modal-card-body {
    border-radius: 5px;
    margin: 5rem;
    width: max-content;
}

.tr-arquivado {
    font-family: 'Poppins';
    background-color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.03);
}

.td-arquivado {
    color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.6);
    font-family: 'Poppins';
}

.espelhado {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}


table .button {
    width: 7rem;
}

table td {

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

table {
    table-layout: fixed;
}

</style>