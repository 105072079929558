<template>
    <div id="barra-lateral" class="child">

        <div id="menu-title">
            <!-- <h1 class="fonte-2 is-size-4 py-2 mb-0" id="marca">
                {{ this.$store.state.isSidebarMin ? "AH" : "ATENA'S HUB" }}
            </h1> -->
            <figure class="image my-2 ml-1" v-if="this.$store.state.isSidebarMin" >
                <img src="@/assets/icon3.png"  alt="Logo Atena Hub" id="logo-min">
            </figure>

            <figure class="image my-2 mr-2 " v-else>
                <img src="@/assets/logo3.png"  alt="Logo Atena Hub" id="logo-max">
            </figure>
        </div>

        <aside class=" menu pb-6" id="sidebar" :class="{ 'minimizada': this.$store.state.isSidebarMin }">
            <ul class="menu-list">
                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/painel">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/inout.png" class="side-btn" alt="Menu de Check-in">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link" v-show="!this.$store.state.isSidebarMin">Meu Painel</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <hr class="mb-0  mt-0">
                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/mapa">
                        <div class="columns m-0 is-centered  is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class=" image is-24x24">
                                    <img src="@/assets/map2.png" class="side-btn" alt="Menu de Mapa">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link">Mapa de Reservas</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <hr class="mb-0  mt-0">
                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/acomodacoes">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/bed.png" class="side-btn" alt="Menu de Acomodações">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link" v-show="!this.$store.state.isSidebarMin">Acomodações</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <hr class="mb-0  mt-0">

                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/hospedes">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/hospedes2.png" class="side-btn" alt="Menu de Hóspedes">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link" v-show="!this.$store.state.isSidebarMin">Hóspedes</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <hr class="mb-0  mt-0">

                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/reservas">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/list.png" class="side-btn" alt="Menu de Reservas">
                                </figure>
                            </div>
                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link" v-show="!this.$store.state.isSidebarMin">Reservas</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <hr class="mb-0  mt-0">

                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/insights">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/insight.png" class="side-btn" alt="Meu Painel">
                                </figure>
                            </div>

                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">

                                <span class="span-link">Insights</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link class="p-0 sidebar-item"
                        :class="{ 'has-text-centered': this.$store.state.isSidebarMin }" to="/gerenciamento">
                        <div class="columns is-centered m-0 is-vcentered">
                            <div
                                :class="[this.$store.state.isSidebarMin ? 'column is-12 px-1' : 'column is-narrow pr-3']">
                                <figure class="image is-24x24">
                                    <img src="@/assets/resort.png" class="side-btn" alt="Meu Painel">
                                </figure>
                            </div>

                            <div class="column pl-0 pr-0 is-8 is-narrow" v-show="!this.$store.state.isSidebarMin">
                                <span class="span-link">Gerenciamento</span>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </aside>
    </div>
</template>


<script>

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiArrowCollapseLeft } from '@mdi/js';

export default {

    name: 'SideBar',

    components: {
        SvgIcon,
    },


    data() {
        return {


            iconPath: mdiArrowCollapseLeft

        }
    },

    mounted() {
        const sidebar = document.getElementById("barra-lateral")
        const sidebar_col = document.getElementById("sidebar")


    },
    methods: {
        minimizarSidebar() {
            this.$store.commit('setIsSidebarMin', !this.$store.state.isSidebarMin)
            localStorage.setItem('isSidebarMin', this.$store.state.isSidebarMin)
        },
        setActive(event) {

            const menu = event.srcElement.closest('span')
            // const activeMenu = document.getElementsByClassName('is-active')[0]
            menu.classList.add('link-ativado')
            //console.log(menu.classList)

        }
    }

}


</script>

<style lang="scss" scoped>
#sidebar {
    width: 200px;
    background-color: rgba(255, 255, 255, 1);
    height: 100%;
    box-shadow: $primary-color 2px 3px 5px;
}

.minimizada {
    position: relative;
    width: 60px !important;
}

#barra-lateral {
    width: auto;
    height: 100%;
}

#min-btn {
    position: absolute;
    left: 100%;
    top: 20%;
    opacity: 0;
    transition: .3s;
    cursor: pointer;
    z-index: 999;

}

aside:hover+button {
    opacity: 100;
}

#min-btn:hover {
    opacity: 100;

}

figure {
    display: inline-block !important;
    align-items: center !important;
}

#avatar2 {
    border: $primary-color 1px solid;
    height: 100% !important;
    width: 100% !important;
}

#marca {
    background-color: $primary-color;
    color: $secondary-color;
    border-bottom: $primary-color 1px solid;
}

.span-link {
    display: inline-block;
    position: relative;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    font-family: 'Poppins';

}

.router-link-active {
    box-shadow: 0px 2px 3px black;
    background-color: hsl($secondary-color-hue, $secondary-color-saturation, 90%);
}


.sidebar-item:hover {
    box-shadow: 0px 2px 3px black;
    background-color: hsl($secondary-color-hue, 100%, 89%) !important;

}
#menu-title {
    background-color: $primary-color;
    color: $secondary-color;
    text-align: center;


}

#logo-max{
    height: 35px;
    width: 105px;

}
#logo-min{
    height: 35px;
    width: 30px;
}

.sidebar-item {
    border-radius: 0px !important;
    padding: 2px;

}


li {
    text-align: left;
}

.image img {
    display: block;
    height: 100%;
    width: 100%;
}

.menu-label {
    color: $primary-color !important;
    font-weight: bold;
}


.side-btn {
    position: relative;
    top: 2px;
    height: auto;
    z-index: 10;
}


.icon {
    text-align: center;
}
</style>