<template>
  <template v-if="this.$store.state.isAuthenticated && this.$route.name !== 'Alterar Senha'">
    <div class="columns m-0 is-multiline" id="page">
      <div class="column p-0  is-narrow is-hidden-touch sidebar" :class="{ 'sobre': !this.$store.state.isSidebarMin }" id="sidebar-column"
      @click="this.$store.commit('setIsProfileDropdown', false)">
        <SideBar />
      </div>

      <div class="column p-0 content-area" id="parent" :class="{'maximizado': isTouch }">

        <section class="hero is-fullheight">
          <div class="hero-head"  :class="{ 'reduzido': !this.$store.state.isSidebarMin}">
            <Navbar />
          </div>
          <div class="hero-body p-5" @click="this.$store.commit('setIsSidebarMin', true); this.$store.commit('setIsProfileDropdown', false)">
            <div id="pause-screen" v-show="!this.$store.state.isSidebarMin"></div>
            <router-view />
          </div>
          <div class="hero-foot">
            <VFooter />
          </div>
        </section>
      </div>
      
    </div>

  </template>

  <template v-else>
    <router-view />
  </template>
</template>

<script>

import axios from 'axios';
import SideBar from '@/components/layout/SideBar'
import Navbar from './components/layout/Navbar.vue';
import ModalConsultarHistorico from './components/Modals/ModalConsultarHistorico.vue';
import VFooter from './components/layout/VFooter.vue';


export default {
  name: 'App',
  components: {
    SideBar,
    Navbar,
    VFooter
  },

  data() {
    return {
      isTouch: true
    }
  },

  computed: {

  },

  beforeCreate() {
    this.$store.commit('initializeStore')
    this.$store.commit('setIsLoading', false)

    // console.log('-> team name', this.$store.state.team.name, '\n-> team id ', this.$store.state.team.id)
    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }

    if (this.$store.state.token) {
      axios.get('/api/v1/usuarios/is-staff/').then(response => {
        this.$store.commit('setStaff', response.data.is_staff)
      })
    }

  },

  mounted() {
    this.$store.commit('setIsSidebarMin', localStorage.getItem('isSidebarMin') === 'true')

    window.addEventListener('resize', this.checkTouch);
    this.checkTouch()

  },

  unmounted() {
    window.removeEventListener('resize', this.checkTouch);
  },

  methods: {
    async checkTouch() {
      try {
          if (window.innerWidth < 1024) {
            this.isTouch = true
            var hero = document.querySelector('.hero-body')
            
              var display = hero.style.display

            // console.log(display)
            hero.style.display = 'none'
            // console.log(display)
            hero.style.display = display

          } else {
            this.isTouch = false
          }
      } catch (error) {
            
      }
    },


    async logout() {

      await axios
        .post('/api/v1/token/logout/')
        .then(response => {

        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })

      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('team_name')
      localStorage.removeItem('team_id')
      localStorage.removeItem('isSuperUser')
      localStorage.removeItem('team_is_activated')
      localStorage.removeItem('team_agreed_to_terms')
      this.$store.commit('removeToken')
      this.$router.push('/log-in')


    }
  }
}

</script>

<style lang="scss">
@import '../node_modules/bulma/';
@import '../node_modules/@creativebulma/bulma-tooltip';


#pause-screen {
  position: absolute;
  width: 100%;
  height: 94.1%;
  left:5px;
  top: 56px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999
}

/*#pause-screen::before{
  content: 'Clique aqui para fechar o menu';
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: "Poppins";
  font-size: 1.5rem;
}*/

@media screen and (max-width: 767px) {
  .hero-body{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

}


.sidebar {
  border: 2px $primary-color solid;
  border-top: 0px;
  border-bottom: 0px;
  transition: 2s width;
  height: 100%;
  position: fixed !important;
  top: 0;
  z-index: 9;
  
}


.content-area {
  margin-left: 60px;
}

.reduzido{
  margin-left: 140px !important;
}

.hero-foot{
  z-index: -1;
}

#parent {
  position: relative;
  z-index: 5;
}

.sobre{
  position:absolute;
  z-index: 999;
}

.maximizado {
  margin-left: 0px !important;
  left: 0;
}

.tabs.is-boxed ul {
  border-bottom: 0.5px hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.50) solid !important;
}

.tabs.is-boxed li.is-active a {

  border-color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.50) !important;
  border-bottom-color: transparent !important;
}

.hero.is-fullheight .hero-body:has(.strech) {
  align-items: stretch;
}

.hero.is-fullheight .hero-body:not(:has(.strech)) {
  align-items: flex-start;
}


.view-container {
  background-color: white;
}

.button {
  font-family: "Poppins" !important;
  letter-spacing: 0.75px;
}

.thead_color {
  background-color: $primary-color !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 15;
}

.thead_color th {
  color: $secondary-color !important;
  font-weight: normal;
  font-family: 'Poppins';
}

.modal-content {
  border-radius: 0px 0px 5px 5px;
}

.modal-card-head {
  background-color: $primary-color !important;
}

.button:hover {
  display: inline-flex !important;
}

.tab-item {
  color: $primary-color !important;
}


html {
  overflow-y: auto;
}



#page {
  height: 100dvh !important;
  position: relative
}

html,
.has-y-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

html::-webkit-scrollbar,
.has-y-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

html::-webkit-scrollbar-track,
.has-y-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  display: none;
}

html::-webkit-scrollbar-thumb,
.has-y-scroll::-webkit-scrollbar-thumb {
  background: $primary-color;

}

#app {
  text-align: center;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto !important;
}

.fonte-1 {
  color: $primary-color;
  font-family: 'Poppins';
}

.fonte-2 {
  color: $secondary-color !important;
  font-family: 'Poppins';
  font-weight: 400;
}


//Status Styles
.is-ocupada {
  background-color: $ocupada-color !important;
  font-family: 'Poppins';
  color: white !important;
}

.is-pre-reserva {
  background-color: $pre-reserva-color !important;
  font-family: 'Poppins' !important;
  font-weight: 600;
  color: black !important;
}

.is-confirmada {
  background-color: $confirmada-color !important;
  font-family: 'Poppins';
  color: white !important;
}

.is-bloqueada {
  background-color: $bloqueada-color !important;
  font-family: 'Poppins';
  color: white !important;
}

.is-fechada {
  background-color: $fechada-color !important;
  font-family: 'Poppins';
  color: white !important;
}

.is-cancelada {
  background-color: $cancelada-color !important;
  font-family: 'Poppins';
  color: white !important;
}
</style>
