<template>
    <div class="columns is-centered is-vcentered is-multiline m-0 ">
        <div class="column is-12">
            <p class="title">Adicionar uma nova hospedagem</p>

        </div>

        <div class="column is-4">
            <div class="box">

                <form @submit.prevent="adicionarHospedagem">

                    <div class="field has-text-left">
                        <label for="nome" class="label fonte-1">Nome da Hospedagem</label>
                        <div class="control">
                            <input type="text" required v-model="nome" class="input" id="nome">
                        </div>

                    </div>
                    <div class="field has-text-left">
                        <label for="email" class="label fonte-1">E-mail</label>
                        <div class="control">
                            <input type="email" required v-model="email" class="input" id="email">
                        </div>

                    </div>

                    <button class="button is-fullwidth is-success">Adicionar</button>

                </form>

            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { toast } from 'bulma-toast';
export default {
    name: 'adicionarHospedagem',
    data() {
        return {

            nome: '',
            email: '',

        }
    },

    methods: {

        async adicionarHospedagem() {

            const cliente = {
                name: this.nome,
                email: this.email
            }

            await axios.post('api/v1/hospedagens/adicionar-hospedagem/', cliente)
                .then(response => {
                    //console.log(response)
                    const msg = `<i class='mdi mdi-bed mr-2'><span>Hospedagem adicionada</span>`

                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 3000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.columns {
    width: 100%;
}
</style>