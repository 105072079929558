<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card cartao" id="header_consultar_reserva">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <span class="fonte-2 mr-2">
                        Reserva {{ reserva?.id }}
                    </span>

                </p>
                <button class="delete is-large" aria-label="close" @click="close"></button>

            </header>
        </div>
        <div class="modal-content has-background-white has-y-scroll py-5 px-5" id="content_consultar_reserva">
            <div class="columns is-multiline">
                <div class="column is-12">
                    <div class="tabs is-boxed is-medium">
                        <ul>
                            <li :class="[tab == 1 ? 'is-active' : '']" @click="tab = 1">
                                <a class="tab-item fonte-1">
                                    <span class="icon is-small">
                                        <i class="mdi mdi-information-outline"></i>
                                    </span>
                                    <span>
                                        Informações
                                    </span>
                                </a>
                            </li>
                            <li :class="[tab == 2 ? 'is-active' : '']" @click="tab = 2"
                                v-if="reserva.status === 'Ocupada'">
                                <a class=" tab-item fonte-1">
                                    <i class="mdi mdi-cart"></i>
                                    <span>
                                        Consumo
                                    </span>
                                </a>
                            </li>
                            <li :class="[tab == 3 ? 'is-active' : '']" @click="tab = 3">
                                <a class="tab-item fonte-1">
                                    <span class="icon is-small">
                                        <i class="mdi mdi-book"></i>
                                    </span>
                                    <span>
                                        Históricos
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <template v-if="tab === 1">

                    <div class="column is-12" v-if="reserva?.status !== 'Pré-reserva' && reserva?.status !== 'Fechada'">
                        <div class="notification">
                            <div class="columns is-vcentered" v-if="!confirmacao">

                                <div class="column is-two-quarters"
                                    v-if="this.reserva?.status === 'Confirmada' && !this.checkinBloqueado">
                                    <p class="has-text-weight-bold has-text-left is-size-5 fonte-1">
                                        Deseja realizar o Check-in da Reserva?
                                    </p>
                                </div>
                                <div class="column is-two-quarters" v-else-if="this.reserva?.status === 'Ocupada'">
                                    <p class="has-text-weight-bold has-text-left is-size-5 fonte-1">
                                        Deseja realizar o Check-out da Reserva?
                                    </p>
                                </div>
                                <div class="column is-two-quarters" v-else>
                                    <p class="has-text-weight-bold has-text-left is-size-5 fonte-1">
                                        Deseja cancelar a reserva?
                                    </p>
                                </div>

                                <div class="buttons is-right pr-0">
                                    <button v-if="this.reserva?.status === 'Confirmada'"
                                        @click="ativarModalCancelarReserva" class=" button is-danger">

                                        <i class="icon mdi mdi-trash-can"></i>
                                        <span class="">Cancelar</span>


                                    </button>
                                    <button v-if="this.reserva?.status === 'Confirmada' && !this.checkinBloqueado"
                                        @click="ativarModalCheckIn" class=" button is-info">
                                        <i class="icon mdi mdi-open-in-app"></i>
                                        <span class="">Check-in</span>
                                    </button>

                                    <button v-else-if="this.reserva?.status === 'Ocupada'" @click="ativarModalCheckOut"
                                        class=" button is-danger">
                                        <i class="icon mdi mdi-exit-to-app"></i>
                                        <span class="">Check-out</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column is-12" v-else-if="reserva?.status == 'Fechada'">
                        <div class="buttons is-right">
                            <button @click="ativarModalCheckOut" class=" button is-info">
                                <i class="icon mdi mdi-book"></i>
                                <span class="">Ver resumo</span>
                            </button>
                        </div>
                    </div>

                    <div class="column is-12" v-else>
                        <div class="notification">
                            <div class="columns is-vcentered" v-if="!confirmacao">
                                <div class="column is-two-quarters">
                                    <p class="has-text-weight-bold has-text-left is-size-5 fonte-1">
                                        Deseja confirmar a Reserva?
                                    </p>
                                </div>

                                <div class="column pr-0">
                                    <div class="buttons is-right">
                                        <button class="button is-danger" @click="ativarModalCancelarReserva">
                                            <i class="icon mdi mdi-trash-can"></i>
                                            <span class="">Deletar</span>
                                        </button>

                                        <button class="button is-success" @click="ativarModalConfirmacao">
                                            <i class="icon mdi mdi-book-account"></i>
                                            <span class="">Confirmar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">
                        <div class="card">
                            <header class="card-header">
                                <p class="fonte-2 has-text-weight-bold mb-0 p-3">
                                    Dados da Reserva
                                </p>
                                <span class="tag is-medium" :class="`is-${formatarStatus(reserva?.status)}`">
                                    {{ reserva.status }}
                                </span>
                            </header>

                            <div class="card-content">

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Check-in: </strong> {{ dataCheckin }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Check-out: </strong> {{ dataCheckout }}
                                </p>

                                <template v-if="!this.reserva.cortesia">
                                    <p class="has-text-left fonte-1 pb-4">
                                        <strong>Valor Diária: </strong> {{ valorDiaria }}
                                    </p>
                                    <p class="has-text-left fonte-1 pb-4">
                                        <strong>Valor Diárias: </strong> {{ valorDiarias }}
                                    </p>
                                </template>
                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Qtd. Adultos: </strong> {{ reserva?.quantidade_adultos }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Qtd. Crianças: </strong> {{ reserva?.quantidade_criancas }}
                                </p>
                                <p class="has-text-left fonte-1">
                                    <strong>Canal: </strong> {{ reserva?.canal.opcao }}
                                </p>

                                <p class="has-text-left fonte-1 has-text-danger  pt-4 " v-if="this.reserva.cortesia">
                                    <i class="mdi mdi-information-outline" /> Essa reserva é uma Cortesia.
                                </p>



                            </div>

                        </div>
                    </div>


                    <div class="column is-6">
                        <div class="card">
                            <header class="card-header">
                                <p class="fonte-2 has-text-weight-bold mb-0 p-3">
                                    Dados do Titular
                                </p>
                            </header>

                            <div class="card-content">
                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Nome:</strong> {{ reserva?.hospede?.nome }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>CPF:</strong> {{ cpfFormatado }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Data de Nascimento:</strong> {{ dataNascimentoFormatada }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>Telefone:</strong> {{ telefoneFormatado }}
                                </p>

                                <p class="has-text-left fonte-1 pb-4">
                                    <strong>E-mail:</strong> {{ reserva?.hospede?.email }}
                                </p>

                                <p class="has-text-left fonte-1">
                                    <strong>Estado:</strong> {{ reserva?.hospede?.estado }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">

                        <div class="card">
                            <header class="card-header">
                                <p class="fonte-2 has-text-weight-bold mb-0 p-3">Confirmação</p>
                            </header>

                            <div class="card-content">
                                <div class="columns is-mobile is-multiline">
                                    <div class="column is-4">
                                        <p class="fonte-1 is-size-7 pb-0"><strong>Sinal Pago</strong>
                                        </p>
                                        <p class="is-size-5 has-text-success is-size-6-mobile fonte-1">
                                            {{ valorSinalF }}
                                        </p>
                                    </div>
                                    <div class="column is-4">
                                        <p class="fonte-1 is-size-7 pb-0"><strong>Restante</strong>
                                        </p>
                                        <p class="fonte-1 is-size-5 has-text-danger is-size-6-mobile">
                                            {{ valorFormatado(reserva?.valor_diarias - reserva?.valor_pago) }}
                                        </p>
                                    </div>
                                    <div class="column is-4">
                                        <p class=" fonte-1 is-size-7 pb-0"><strong>Data</strong>
                                        </p>
                                        <p class="fonte-1  is-size-5 is-size-6-mobile">
                                            {{ dataConfirmacaoFormatada }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="column is-6">

                        <div class="card">
                            <header class="card-header ">
                                <p class="fonte-2 has-text-weight-bold mb-0 p-3">Acomodação</p>
                            </header>
                            <div class="card-content">
                                <div class="columns is-mobile is-multiline">
                                    <div class="column is-4 is-12-touch">
                                        <p class="fonte-1 is-size-7 pb-0"><strong>UH</strong>
                                        </p>
                                        <p class="fonte-1 is-size-5 is-size-6-mobile">
                                            <span v-if="Number(reserva?.acomodacao?.nome)">Apt</span>
                                            {{ reserva?.acomodacao?.nome }}
                                        </p>
                                    </div>
                                    <div class="column is-4">
                                        <p class=" fonte-1 is-size-7 pb-0">
                                            <strong>Categoria</strong>
                                        </p>
                                        <p class=" fonte-1 is-size-5 is-size-6-mobile">
                                            {{ reserva?.acomodacao?.tipo_acomodacao?.descricao }}
                                        </p>
                                    </div>
                                    <div class="column is-4">
                                        <p class="fonte-1 is-size-7 pb-0"><strong>Valor base</strong>
                                        </p>
                                        <p class=" fonte-1 is-size-5 is-size-6-mobile">
                                            {{ valorFormatado(reserva?.acomodacao?.tipo_acomodacao?.valor_base) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="tab === 2">
                    <div class="column is-12" v-if="produtos.length > 0">

                        <div class="table-container">

                            <table class="table is-fullwidth">

                                <thead>
                                    <th class="fonte-1 is-size-5">Descrição</th>
                                    <th class="fonte-1 is-size-5">Valor Unitário</th>
                                    <th class="fonte-1 has-text-left is-size-5">Quantidade</th>
                                    <th class="fonte-1 is-size-5">Subtotal</th>

                                </thead>

                                <tbody>

                                    <tr v-for="produto in this.produtos" v-bind:key="produto.id">

                                        <td class="has-text-left is-size-5">{{ produto.descricao }}</td>
                                        <td class="has-text-left is-size-5">
                                            {{ valorFormatado(produto.valor_unitario) }}
                                        </td>
                                        <td class="has-text-centered is-size-5">

                                            <div class="field has-addons">

                                                <div class="control">
                                                    <button class="button is-small  is-danger is-outlined"
                                                        style="z-index: 9999;" @click="removerItem(produto)">
                                                        <i class="mdi mdi-minus-thick"></i>
                                                    </button>
                                                </div>

                                                <div class="control">
                                                    <input type="number" max="10" readonly
                                                        class="input lbl is-small has-text-weight-bold has-text-centered"
                                                        v-model="qtd_itens[produto.id]" id="qtd-input">
                                                </div>

                                                <div class="control">
                                                    <button class="button is-small is-success is-outlined"
                                                        @click="adicionarItem(produto)">

                                                        <i class="mdi mdi-plus-thick"></i>

                                                    </button>

                                                </div>

                                            </div>



                                        </td>

                                        <td class="has-text-left  is-size-5" style="max-width: fit-content;">
                                            <span class="hast-text-left">
                                                {{ valorFormatado(qtd_itens[produto.id] * produto.valor_unitario) }}
                                            </span>
                                        </td>

                                    </tr>

                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td class="has-text-left is-size-5 fonte-1">
                                            <strong>Total</strong>
                                        </td>
                                        <td class="has-text-left is-size-5 fonte-1" style="width:8rem;min-width: 8rem;">
                                            {{ valorFormatado(consumo_total) }}
                                        </td>
                                    </tr>

                                </tfoot>

                            </table>
                        </div>

                        <div class="level notification p-4" v-if="editarConsumo">
                            <div class="level-left">
                                <div class="level-item">
                                    <span class="fonte-1 is-size-4">Deseja salvar as alterações?</span>
                                </div>

                            </div>

                            <div class="level-right">
                                <div class="buttons">
                                    <button class="button " @click="descartarAtualizacoesConsumo">
                                        <span class="icon-text">
                                            <span>Cancelar</span>
                                        </span>
                                    </button>
                                    <button class="button is-success " @click="salvarAtualizacoesConsumo">
                                        <span class="icon-text">
                                            <i class="icon mdi mdi-content-save"></i>
                                            <span>Salvar</span>
                                        </span>
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="column is-12" v-else>


                        <div class="notification" id="acomod-notification">
                            <p class="is-size-5 has-text-left fonte-1">

                                <i class="mdi mdi-information-outline icon"></i>
                                <span>
                                    Sem produtos cadastrados. Adicione seus produtos na página de
                                    <router-link class=" has-text-weight-bold fonte-1" to="/gerenciamento">
                                        <span>Gerenciamento</span>
                                    </router-link>
                                    .
                                </span>
                            </p>
                        </div>

                    </div>

                </template>

                <template v-else>
                    <div class="column is-12">

                        <div class="table-container" id="tabela-historicos">
                            <table class="table is-hoverable is-bordered">
                                <thead>
                                    <tr>
                                        <th class=" fonte-1 is-narrow">Categoria</th>
                                        <th class="fonte-1">Descrição</th>
                                        <th class="fonte-1">Data e Hora</th>
                                        <th class="fonte-1 has-text-centered is-narrow">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="  historico  in  historicos " v-bind:key="historico.id">
                                        <td class="has-text-left fonte-1">
                                            {{ historico.categoria }}
                                        </td>
                                        <td class="has-text-left has-tooltip-arrow fonte-1 td-historico-descricao">
                                            <p class="historico-descricao">
                                                {{ historico.descricao }}
                                            </p>
                                        </td>
                                        <td class="has-text-left fonte-1">
                                            {{ this.formataDataHistorico(historico.criado_em) }}
                                        </td>
                                        <td class="">
                                            <div class="buttons is-centered">
                                                <button
                                                    @click="this.$refs.modalConsultarHistorico.ativarModal(historico)"
                                                    class=" button is-small is-responsive is-dark">
                                                    <i class="icon mdi mdi-eye"></i>
                                                    <span>Detalhes</span>
                                                </button>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="column is-12">
                        <hr>
                        <div class="buttons is-left">
                            <button class="button is-success"
                                @click="this.$refs.modalAdicionarHistorico.ativarModal(this.reserva)">
                                Novo Histórico
                            </button>
                        </div>

                    </div>
                </template>

            </div>

        </div>
    </div>

    <ModalConfirmarReserva ref="modalConfirmarReserva"
        @reserva-confirmada="getHistoricos(); $emit('reservaAtualizada'); this.verificarOcupacao" />
    <ModalCheckIn ref="modalCheckIn" @checkin-realizado="getHistoricos(); $emit('reservaAtualizada');" />
    <ModalCheckOut ref="modalCheckOut" @checkout-realizado="$emit('reservaAtualizada')" />
    <ModalCancelarReserva ref="modalCancelarReserva"
        @reserva-cancelada="$emit('reservaAtualizada'); this.modal_ativo = false" />

    <ModalAdicionarHistorico ref="modalAdicionarHistorico" @historico-adicionado="getHistoricos" />
    <ModalConsultarHistorico ref="modalConsultarHistorico" />
</template>


<script>

import axios from 'axios'

import ModalConfirmarReserva from './ModalConfirmarReserva.vue'
import ModalCheckIn from './ModalCheckIn.vue'
import ModalCancelarReserva from './ModalCancelarReserva.vue'
import ModalCheckOut from './ModalCheckOut.vue'
import { toast } from 'bulma-toast'
import ModalAdicionarHistorico from './ModalAdicionarHistorico.vue'
import ModalConsultarHistorico from './ModalConsultarHistorico.vue'

export default {

    name: "ModalConsultarReserva",


    components: {
        ModalConfirmarReserva,
        ModalCheckIn,
        ModalCheckOut,
        ModalCancelarReserva,
        ModalAdicionarHistorico,
        ModalConsultarHistorico
    },

    emits: ['reservaAtualizada'],

    data() {
        return {

            modal_ativo: false,
            mudanca: false,

            tab: 1,

            reserva: {},

            valor_sinal: 0.0,

            qtd_itens: {},
            qtd_itens_bkp: {},

            consumo_total: 0.0,
            consumo_total_bkp: 0.0,

            checkinBloqueado: false,

            editarConsumo: false,

            confirmacao: false,

            produtos: null,

            historicos: {},

            pre_reserva: false,

            cores_status: {

                'Pré-reserva': 'rgb(255, 189, 33)',
                'Confirmada': 'rgb(19, 153, 86)',
                'Ocupada': 'rgb(78, 105, 173)',
                'Bloqueada': 'rgb(173, 78, 78)',
            }

        }
    },

    computed: {

        dataCheckin() {
            return new Date(this.reserva?.data_entrada + "T00:00:00").toLocaleDateString('pt-BR')
        },

        dataCheckout() {
            return new Date(this.reserva?.data_saida + "T00:00:00").toLocaleDateString('pt-BR')
        },
        dataConfirmacaoFormatada() {
            if (this.reserva?.data_confirmacao) {
                return new Date(this.reserva?.data_confirmacao + "T00:00:00").toLocaleDateString('pt-BR')
            }
            return '-'
        },

        valorDiaria() {
            return this.reserva?.valor_diaria?.toLocaleString('pt-br', {
                style: "currency", currency: "BRL"
            })
        },
        valorDiarias() {
            return this.reserva?.valor_diarias?.toLocaleString('pt-br', {
                style: "currency", currency: "BRL"
            })
        },

        cpfFormatado() {
            return this.reserva?.hospede?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
        },

        dataNascimentoFormatada() {
            return new Date(this.reserva?.hospede?.data_nascimento + "T00:00:00").toLocaleDateString('pt-BR')
        },

        telefoneFormatado() {
            return this.reserva?.hospede?.telefone?.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/g, "(\$1) \$2 - \$3")
        },
        valorSinalF() {
            return this.reserva?.valor_sinal ? this.reserva?.valor_sinal?.toLocaleString('pt-br', {
                style: "currency", currency: "BRL"
            }) : '-'
        },


    },

    methods: {

        ativarModal(reserva) {

            this.reserva = reserva

            this.tab = 1

            this.getProdutos()

            this.getHistoricos()

            this.atualizaItems()

            this.pre_reserva = reserva.status === 'Pré-reserva'

            this.modal_ativo = true

            this.editarConsumo = false

            if (reserva.status === 'Confirmada') {

                this.verificarOcupacao()

            }


        },

        ativarModalConfirmacao() {

            this.$refs.modalConfirmarReserva.ativarModal(this.reserva)

        },
        ativarModalCheckIn() {

            this.$refs.modalCheckIn.ativarModal(this.reserva)

        },

        ativarModalCancelarReserva() {

            this.$refs.modalCancelarReserva.ativarModal(this.reserva)

        },

        ativarModalCheckOut() {
            this.$refs.modalCheckOut.ativarModal(this.reserva)
        },

        verificarOcupacao() {

            const filtro = {
                acomodacao: this.reserva.acomodacao.id,
                status: 'Ocupada'
            }

            axios.get(`api/v1/reservas/`, { params: filtro })
                .then(response => {

                    ////console.log(response.data.length > 0)

                    if (response.data.length > 0) {
                        this.checkinBloqueado = true
                    } else {
                        this.checkinBloqueado = false
                    }

                })
                .catch(error => {
                    console.log(error)
                })
        },

        confirmarReserva() {

            this.reserva.status = "Confirmada"

            this.reserva.valor_sinal = this.valor_sinal

            axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {

                    ////console.log(response)

                    this.pre_reserva = false

                    axios.post('api/v1/reservas/confirmar_reserva/', { id_reserva: this.reserva.id })
                        .catch(error => {
                            console.log(error)
                        })

                    this.mudanca = true
                    this.getHistoricos()
                })
                .catch(error => {

                    console.log(error)

                })
        },
        adicionarItem(produto) {

            ////console.log(JSON.stringify(this.qtd_itens_bkp) === JSON.stringify(this.qtd_itens))

            this.consumo_total -= produto.valor_unitario * this.qtd_itens[produto.id]
            this.qtd_itens[produto.id]++


            this.consumo_total += produto.valor_unitario * this.qtd_itens[produto.id]

            if (JSON.stringify(this.qtd_itens_bkp) === JSON.stringify(this.qtd_itens)) {
                this.editarConsumo = false
            } else {
                this.editarConsumo = true
            }

        },

        removerItem(produto) {

            if (this.qtd_itens[produto.id] > 0) {
                ////console.log(JSON.stringify(this.qtd_itens_bkp) === JSON.stringify(this.qtd_itens))


                this.consumo_total -= produto.valor_unitario
                this.qtd_itens[produto.id]--


                if (JSON.stringify(this.qtd_itens_bkp) === JSON.stringify(this.qtd_itens)) {
                    this.editarConsumo = false
                } else {
                    this.editarConsumo = true
                }
            }


        },

        salvarAtualizacoesConsumo() {

            this.atualizandoQtd = true

            const request = {

                id_reserva: this.reserva.id,
                itens: this.qtd_itens,
            }

            axios.post('api/v1/reservas/atualizar_item/', request)
                .then(response => {

                    ////console.log(response)

                })
                .catch(error => {
                    console.log(error)
                })

            this.atualizandoQtd = false

            this.editarConsumo = false

            this.qtd_itens_bkp = Object.assign({}, this.qtd_itens)

            this.consumo_total_bkp = this.consumo_total

            const msg = `<i class='mdi mdi-check-circle mr-2'><span>  Consumo atualizado com sucesso!</span>`

            toast({
                message: msg,
                type: 'is-success is-light',
                position: 'top-right',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            })

        },

        descartarAtualizacoesConsumo() {

            this.editarConsumo = false

            ////console.log(JSON.stringify(this.qtd_itens_bkp) === JSON.stringify(this.qtd_itens))

            this.qtd_itens = Object.assign({}, this.qtd_itens_bkp)
            this.consumo_total = this.consumo_total_bkp


        },

        valorFormatado(valor) {
            return valor.toLocaleString('pt-br', {
                style: "currency", currency:
                    "BRL"
            })
        },

        async getProdutos() {

            await axios.get('api/v1/produtos')
                .then(response => {
                    this.produtos = response.data

                    this.produtos.forEach(produto => {

                        this.qtd_itens[produto.id] = 0

                    })
                    this.atualizaItems()
                    ////console.log(this.qtd_itens)
                })

        },

        async atualizaItems() {

            const filtro = {
                reserva: this.reserva.id
            }

            ////console.log(filtro)

            await axios.get('api/v1/produtos_consumidos', { params: filtro })
                .then(response => {

                    var subtotal = 0

                    response.data.forEach(item => {
                        // ////console.log(item)
                        subtotal += item.valor_parcial
                        this.qtd_itens[item.produto.id] = item.quantidade

                    })
                    // //console.log(this.qtd_itens)
                    this.qtd_itens_bkp = Object.assign({}, this.qtd_itens)
                    this.consumo_total = this.consumo_total_bkp = subtotal

                })

                .catch(error => {

                    console.log(error)

                })

        },


        getHistoricos() {

            const request = {
                reserva: this.reserva.id
            }

            axios.get('api/v1/historicos', { params: request })
                .then(response => {

                    this.historicos = response.data

                })
                .catch(error => {

                    console.log(error)

                })

        },
        formataDataHistorico(data) {

            let dataObj = new Date(data);

            let dia = dataObj.getDate();
            let mes = dataObj.getMonth() + 1; // Os meses em JavaScript são indexados a partir de 0, então adicionamos 1 para obter o mês correto
            let ano = dataObj.getFullYear();
            let hora = dataObj.getHours();
            let minutos = dataObj.getMinutes();

            dia = dia < 10 ? "0" + dia : dia;
            mes = mes < 10 ? "0" + mes : mes;
            minutos = minutos < 10 ? "0" + minutos : minutos;

            return dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos;

        },

        close() {


            if (this.editarConsumo) {
                const msg = `<i class='mdi mdi-alert mr-2'><span> Existem alterações não salvas!</span>`

                toast({
                    message: msg,
                    type: 'is-warning is-light',
                    position: 'top-right',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                })
            } else {
                this.modal_ativo = false
            }

        },

        formatarStatus(status) {
            return status?.toLowerCase().replace('é', 'e')
        }



    }
}


</script>


<style lang="scss" scoped>
.titulo {
    color: white;
    box-shadow: inset black;
}


.tag {

    position: absolute;
    right: 0.75rem;
    top: 0.5rem
}

#tabela-historicos {
    max-height: 35rem !important;
    min-height: 35rem !important;
    overflow-y: auto;
    overflow-x: auto;
}

.card {
    height: 100%
}

.header {
    position: relative;
}

.td-historico-descricao {
    white-space: nowrap;
    max-width: 20rem;
}

.historico-descricao {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;


}

#header_consultar_reserva,
#content_consultar_reserva {
    width: 60%;

}

header {
    background-color: $primary-color !important;
}

@media only screen and (max-width: 1100px) {

    #header_consultar_reserva,
    #content_consultar_reserva {
        width: 97% !important;
    }


}


#content_consultar_reserva {
    height: 85%;
    overflow-y: auto;
}

.modal-card-title span {
    vertical-align: middle;
}
</style>