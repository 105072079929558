import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isSidebarMin: false,
    isProfileDropdown: false,
    isStaff: false,
    isUpdated: false,
    senhaAlterada: true,
    token: '',
    user: {
      id: 0,
      username: '',
      email: ''
    },
    hospedagem: {
      id: 0,
      nome: '',
      foto: '',
    },
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        // state.senhaAlterada = localStorage.getItem('isSenhaAlterada') === "true"
        state.user.username = localStorage.getItem('username')
        state.user.id = localStorage.getItem('userid')
        state.user.email = localStorage.getItem('email')
        state.hospedagem.nome = localStorage.getItem('hospedagem_nome')
        state.hospedagem.id = localStorage.getItem('hospedagem_id')
      } else {
        state.token = ''
        state.isAuthenticated = false
        state.user.username = ''
        state.user.email = ''
        state.user.id = 0
        state.hospedagem.nome = ''
        state.hospedagem.id = ''
        state.hospedagem.foto = ''

      }
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setIsUpdated(state, status) {
      state.isUpdated = status
    },
    setIsSidebarMin(state, status) {
      state.isSidebarMin = status
    },
    setIsProfileDropdown(state, status) {
      state.isProfileDropdown = status
    },
    setLastUpdate(state, last_update) {
      state.lastUpdate = last_update
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
      state.isStaff = false
    },
    logoutUser(state) {
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('email')

    },
    setUser(state, user) {
      state.user = user
    },

    setHospedagem(state, hospedagem) {
      state.hospedagem = hospedagem
    },
    setStaff(state, staff) {
      state.isStaff = staff
    },
    setSenhaAlterada(state, senhaAlterada) {
      state.senhaAlterada = senhaAlterada
    },

  },
  actions: {
  },
  modules: {
  }
})
