<template>
    <div class="modal is-active" v-if="modal_ativo">

        <div class="modal-background"></div>
        <div class="modal-card" id="header-checkOut">
            <header class="modal-card-head" style="background-color: rgb(173, 78, 78);">
                <p class="modal-card-title fonte-2 titulo">Resumo da Reserva</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>

            </header>
        </div>
        <div class="modal-content has-background-white has-y-scroll p-6 py-5 px-5" id="conteudo-checkOut">

            <div class="columns is-multiline m-0 p-0" id="printable">

                <div class="column is-12 ">
                    <div class="level">
                        <div class="level-left">
                            <h1 class="title is-size-5 has-text-left fonte-1">

                                <span class="icon-text">
                                    <i class="icon mdi mdi-information-outline"></i>
                                    <span>Informações:</span>

                                </span>

                            </h1>
                        </div>
                        <div class="level-right">
                            <button class="button is-dark" @click="imprimir" id="btn-imprimir">

                                <span class="icon">
                                    <i class="mdi mdi-printer"></i>
                                </span>
                                <span>Imprimir</span>

                            </button>

                        </div>

                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            <p class="fonte-1 has-text-left pb-3"><strong>Check-in:</strong> {{
        new Date(reserva?.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}</p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Check-out: </strong> {{ new
        Date(reserva?.data_saida + "T00:00:00").toLocaleDateString('pt-BR') }}</p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Estadia: </strong> {{
        diferencaDias(reserva?.data_entrada, reserva?.data_saida) }}
                                {{ diferencaDias(reserva?.data_entrada, reserva?.data_saida) > 1 ? 'dias' : 'dia' }}
                            </p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Valor Diária:</strong> {{
        reserva?.valor_diaria?.toLocaleString('pt-br', {
            style: "currency", currency: "BRL"
        })
    }}</p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Qtd. Adultos:</strong> {{
            reserva?.quantidade_adultos }}</p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Qtd. Crianças:</strong> {{
        reserva?.quantidade_criancas }}
                            </p>
                        </div>
                        <div class="column is-6">
                            <p class="fonte-1 has-text-left pb-3"><strong>CPF:</strong> {{
        reserva?.hospede?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
    }}
                            </p>
                            <p class="fonte-1 has-text-left pb-3"><strong>Nome:</strong> {{ reserva?.hospede?.nome }}
                            </p>
                            <p class="fonte-1 has-text-left pb-3"><strong>Data de Nascimento:</strong> {{
        new
            Date(reserva?.hospede?.data_nascimento + "T00:00:00").toLocaleDateString('pt-BR') }}
                            </p>

                            <p class="fonte-1 has-text-left pb-3"><strong>Estado:</strong> {{ reserva?.hospede?.estado
                                }}
                            </p>
                            <p class="fonte-1 has-text-left pb-3"><strong>Telefone:</strong> {{
        reserva?.hospede?.telefone?.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/g,
            "(\$1) \$2 - \$3") }}</p>

                            <p class="fonte-1 has-text-left"><strong>E-mail:</strong> {{
        reserva?.hospede?.email }}</p>
                        </div>
                    </div>

                    <hr class="my-1">
                </div>
                <div class="column is-12 ">
                    <h1 class="title is-size-5 has-text-left fonte-1">

                        <span class="icon-text">
                            <i class="icon mdi mdi-bed-outline"></i>
                            <span>Acomodação:</span>

                        </span>
                    </h1>
                    <br>

                    <div class="columns is-mobile is-multiline">
                        <div class="column is-4 is-12-touch">
                            <p class="subtitle fonte-1 is-size-7 pb-0">
                                <strong>UH</strong>
                            </p>
                            <p class="title fonte-1 is-size-5"> 
                                {{reserva?.acomodacao?.nome}}
                            </p>
                        </div>
                        <div class="column is-4">
                            <p class="subtitle fonte-1 is-size-7 pb-0"><strong>Categoria</strong>
                            </p>
                            <p class="title fonte-1 is-size-5">
                                {{ reserva?.acomodacao?.tipo_acomodacao?.descricao }}
                            </p>
                        </div>
                        <div class="column is-4">
                            <p class="subtitle fonte-1 is-size-7 pb-0"><strong>Valor base</strong>
                            </p>
                            <p class="title fonte-1 is-size-5">
                                {{ valorBase }}</p>
                        </div>
                    </div>

                    <hr class="my-1">
                </div>

                <div class="column is-12 ">

                    <p class="title is-size-5 sec has-text-left fonte-1">
                        <span class="icon-text">
                            <i class="icon mdi mdi-cart-outline"></i>
                            <span>Consumo:</span>

                        </span>
                    </p>
                    <template v-if="this.produtos.length > 0">
                        <div class="table-container">

                            <table class="table is-fullwidth">

                                <thead>
                                    <th>Descrição</th>
                                    <th>Valor Unit.</th>
                                    <th class="has-text-left pl-3">
                                        Quantidade
                                    </th>
                                    <th class="is-medium">Valor</th>

                                </thead>

                                <tbody>

                                    <tr v-for="produto in  produtos">

                                        <td class="has-text-left">
                                            {{ produto.descricao }}
                                        </td>
                                        <td class="has-text-left">
                                            {{ produto.valor_unitario.toLocaleString('pt-BR',{ style: 'currency', currency: 'brl' }) }}
                                        </td>
                                        <td class="has-text-left pl-6">
                                            {{ qtd_itens[produto.id] }}
                                        </td>

                                        <td class="has-text-left " style="max-width: fit-content;">
                                            <span class="hast-text-left">
                                                {{ (qtd_itens[produto.id] * produto.valor_unitario).toLocaleString('pt-BR',{ style: 'currency', currency: 'brl' }) }} 
                                            </span>
                                        </td>

                                    </tr>

                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td class="has-text-left pl-5">
                                            <strong>Subtotal:</strong>
                                        </td>
                                        <td class="has-text-left" style="width: 8rem;min-width: 8rem;">
                                            {{consumo_total.toLocaleString('pt-BR',{ style: 'currency', currency: 'brl' }) }}
                                        </td>
                                    </tr>

                                </tfoot>

                            </table>
                        </div>

                    </template>

                    <template v-else>

                        <div class="notification">

                            <p class="is-size-5 icon-text">
                                <i class="icon mdi mdi-block-helper"></i>
                                <span>Sem consumo</span>
                            </p>
                        </div>

                    </template>

                    <hr>
                </div>

                <div class="column is-12 ">

                    <h1 class="title is-size-5 has-text-left fonte-1">
                        <span class="icon-text">
                            <i class="icon mdi mdi-cash-multiple has-text-success"></i>
                            <span>Faturamento:</span>

                        </span>
                    </h1>
                    <br>
                    <div class="columns is-mobile is-multiline" v-if="!reserva.cortesia">
                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0">
                                <strong>Estadia</strong>
                            </p>
                            <p class="title fonte-1 is-size-5">
                                {{valor_diarias.toLocaleString('pt-br', {style: "currency", currency:"BRL"})}}
                            </p>
                        </div>
                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0">
                                <strong>Consumo</strong>
                            </p>
                            <p class="title fonte-1 is-size-5">
                                {{ consumo_total.toLocaleString('pt-br', {style: "currency", currency:"BRL"}) }}
                            </p>
                        </div>

                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0"><strong>Total</strong>
                            </p>
                            <p class="title fonte-1 is-size-5">
                                {{ (total_a_pagar)?.toLocaleString('pt-br',{style: "currency", currency:"BRL"}) }}
                            </p>
                        </div>
                    </div>

                    <template v-else>

                        <div class="notification">

                            <p class="is-size-5 is-size-6-mobile icon-text">
                                <i class="icon mdi mdi-information-outline"></i>
                                <span>Essa reserva é uma Cortesia</span>
                            </p>
                        </div>

                    </template>
                </div>

                <div class="column is-12 mt-5" id="info-adicional" v-if="this.reserva?.status != 'Fechada'">
                    <hr>
                    <form @submit.prevent="confirmacao_inativo = !confirmacao_inativo" id="form-checkout">

                        <div class="field has-text-left">
                            <div class="control">
                                <label for="forma-pagamento" class="label fonte-1 has-text-left">Forma de
                                    pagamento:</label>
                                <div class="select">
                                    <select name="" class="fonte-1" :disabled="reserva.cortesia"
                                        v-model="formaPagamento" id="">
                                        <option value="Transferência/Pix">Transferência/Pix</option>
                                        <option value="Dinheiro">Dinheiro</option>
                                        <option value="Crédito">Crédito</option>
                                        <option value="Débito">Débito</option>
                                        <option value="Cortesia">Cortesia</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="field mt-6 ">
                            <label class="label has-text-left fonte-1">Informação Adicional (Opcional):</label>
                            <div class="control">
                                <textarea class="textarea has-fixed-size" v-model="informacaoAdicional"
                                    :readonly="!confirmacao_inativo" placeholder="Máx. 255 caracteres"
                                    maxlength="255"></textarea>
                            </div>

                        </div>
                        <hr>
                        <div class="field is-grouped is-grouped-right">
                            <div class="control is-expanded has-text-left">
                                <button class="button is-fullwidth" @click="modal_ativo = !modal_ativo">
                                    <span class="icontext- has-text-weight-bold">
                                        <span>Cancelar</span>
                                    </span>
                                </button>
                            </div>

                            <div class="control is-expanded">
                                <button class="button is-fullwidth is-success" @click="confirmarCheckOut()" type="button">
                                    <span class="icon-text">
                                        <span>Check-out</span>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import CPFInput from '../inputs/CPFInput.vue'


export default {
    name: 'ModalCheckOut',
    data() {
        return {
            reserva: {},
            modal_ativo: false,
            confirmacao_inativo: true,
            cpf: '',
            informacaoAdicional: '',
            produtos: [],
            qtd_itens: {},
            qtd: '',
            consumo_total: 0,
            total_a_pagar: 0,
            valor_diarias: 0,
            produto_selecionado: null,
            formaPagamento: 'Transferência/Pix',
            itens_consumo: []
        };
    },

    computed:{
        valorBase(){
            return this.reserva?.acomodacao?.tipo_acomodacao?.valor_base !== undefined ? 
            this.reserva?.acomodacao?.tipo_acomodacao?.valor_base?.toLocaleString('pt-br', {style: "currency",currency: "BRL"}) : '-'
        },
    },


    emits: ['checkoutRealizado'],

    methods: {
        ativarModal(reserva) {
            this.reserva = reserva;
            this.modal_ativo = true;
            this.confirmacao_inativo = true;
            this.descricao = '';
            this.atualizaItems()
            this.consumo_total = 0.0
            this.qtd_itens = {}
            this.produtos = []
        },
        formataCPF(event) {
            const input = event.srcElement;
            input.value = input.value.replace(/[^\d,.-]+$/g, '');
            if ((input.value.length === 3 || input.value.length === 7) && event.code !== 'Backspace') {
                input.value += '.';
            }
            else if (input.value.length === 11 && event.code !== 'Backspace') {
                input.value += '-';
            }
        },
        async confirmarCheckOut() {

            this.reserva.status = "Fechada";
            this.reserva.valor_consumo = this.consumo_total

            if (this.consumo_total > 0) {
                var descricao = `O hóspede pagou o consumo no valor de R$${this.consumo_total} na forma de pagamento de ${this.formaPagamento}`;
            } else {
                var descricao = `O hóspede não consumiu nenhum produto`;
            }


            await axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {


                    const acomodacao = this.reserva.acomodacao
                    acomodacao.ocupado = false
                    acomodacao.status = 'Sujo'

                    axios.patch(`api/v1/acomodacoes/${acomodacao.id}/`, acomodacao)
                        .then(response => {
                            //console.log(response)
                            axios.post('api/v1/historicos/adicionar-historico/',
                                {
                                    id_reserva: this.reserva.id,
                                    informacao_adicional: this.informacaoAdicional,
                                    descricao: descricao,
                                    categoria: "Check-out"
                                })
                                .then(response => {

                                    const msg = `<i class='mdi mdi-check-circle mr-2'><span> Check-out realizado com sucesso!</span>`

                                    toast({
                                        message: msg,
                                        type: 'is-success is-light',
                                        position: 'top-right',
                                        duration: 6000,
                                        dismissible: true,
                                        pauseOnHover: true,
                                    })

                                    this.$emit('checkoutRealizado')
                                    this.modal_ativo = false;

                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        })
                        .catch(error => {
                            console.log(error)
                        })

                })
                .catch(error => {
                    console.log(error);
                });


        },


        async atualizaItems() {

            await axios.get('api/v1/produtos_consumidos', { params: { reserva: this.reserva.id } })
                .then(response => {

                    var subtotal = 0

                    response.data.forEach(item => {
                        //console.log(item)
                        subtotal += item.valor_parcial
                        this.qtd_itens[item.produto.id] = item.quantidade
                        this.produtos.push(item.produto)


                    })

                    this.consumo_total = subtotal
                    this.total_a_pagar = this.reserva.valor_diarias + this.consumo_total
                    this.valor_diarias = this.reserva.valor_diarias

                })

                .catch(error => {

                    console.log(error)

                })

        },
       

        async getProdutos() {

            await axios.get('api/v1/produtos')
                .then(response => {
                    this.produtos = response.data

                    this.produtos.forEach(produto => {

                        this.qtd_itens[produto.id] = 0

                    })

                    this.atualizaItems()
                })

        },

        diferencaDias(d1, d2) {

            const data1 = new Date(d1)
            const data2 = new Date(d2)

            return Math.ceil(Math.abs(data2 - data1) / (1000 * 60 * 60 * 24));

        },

        imprimir() {

        // var elem = document.getElementById('printable')

        // var domClone = elem.cloneNode(true);

        // var $printSection = document.getElementById("printSection");

        // if (!$printSection) {
        //     var $printSection = document.createElement("div");
        //     $printSection.id = "printSection";
        //     document.body.appendChild($printSection);
        // }

        // //console.log($printSection)
        // $printSection.innerHTML = "";
        // $printSection.appendChild(domClone);

        window.print();

        return false

    },
    },
    components: { CPFInput }
}

</script>



<style>
.sec {
    width: fit-content;
}

#conteudo-checkOut,
#header-checkOut,
#footer-checkOut {
    width: 45%;
}

@media only screen and (max-width: 1024px) {

    #conteudo-checkOut,
    #header-checkOut,
    #footer-checkOut {
        width: 97% !important;

    }

    #conteudo-checkOut {
        padding: 0.5rem !important;
    }

}


#conteudo-checkOut {
    height: 75%;

}

#footer-checkOut {
    display: block;
}


#control-cpf {
    text-align: left;
}

#qtd-input {
    width: 2.5rem;

}

@media screen {
    #printSection {
        display: none;
    }
}

@media print {
    body * {
        visibility: hidden;

    }


    .sidebar,
    nav,#btn-imprimir {
        display: none !important;

    }

    .modal-content * {
        visibility: visible;
        overflow: visible;

    }

    .modal {
        position: absolute;
        left: 3% !important;
        top: 2% !important;
        transform: translate(-7%, 0%);
        margin: 0px;
        
        padding: 0px;
        height: 50%;
        width: 100%;
        z-index: 999;
        visibility: visible;
        overflow: visible !important;
        /* Remove scrollbar for printing. */
    }

    #conteudo-checkOut {
        overflow: visible !important;
        visibility: visible !important;
        padding: 0.2rem !important;
    }

    hr {
        margin: 0px;
        display: none;
    }


    #conteudo-checkOut {

        padding: 2rem !important;
        margin: 0;
        width: 105%;
        height: 100%;

        /* Remove scrollbar for printing. */
    }

    #footer-checkOut,
    #btn-imprimir,
    #btn-confirmacao {
        visibility: hidden !important;
    }

    .modal-card-head,
    .modal-card-head * {
        overflow: hidden !important;
        visibility: hidden !important;
    }

    #btn-imprimir {
        display: none;
    }

    #info-adicional {
        display: none;

    }
}

.qtd-controler {
    display: flex;
    justify-content: center;
    vertical-align: middle;

}

#qtd-input::-webkit-outer-spin-button,
#qtd-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#fonte-1 {
    width: fit-content;
}
</style>
