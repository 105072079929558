<template>
    <div class="hero is-fullheight">
        <div class="hero-body">

            <div class="columns is-centered">
                <div class="column is-4 ">
                    <div class=" box p-6 form-box" v-show="!senhaRedefinida">
                        <p class="fonte-1 is-size-4 title has-text-centered">Redefinir Senha</p>
                        <hr>
                        <p class="fonte-1 is-size-6 title has-text-left">
                            Crie uma nova senha para sua conta.
                        </p>
                        <form @submit.prevent="submitForm">

                            <div class="field">
                                <label class="label fonte-1 has-text-left" for="new-password1">Nova senha:</label>
                                <InputSenha v-model="novaSenha1" class="fonte-1 is-medium" />
                            </div>
                            <br>
                            <div class="field">
                                <label class="label fonte-1 has-text-left" for="new-password2">
                                    Repita sua nova Senha:
                                </label>
                                <InputSenha v-model="novaSenha2" class="fonte-1 is-medium" />
                            </div>

                            <br>
                            <div class="notification is-danger is-light has-text-left " v-if="erros.length">
                                <ul>
                                    <li v-for="error in erros" class="is-size-6 mb-2" v-bind:key="error">{{ error }}
                                    </li>
                                </ul>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button class="button is-success is-medium fonte-1 is-fullwidth"
                                        :class="{ 'is-loading': this.carregando }" id="btn-entrar">
                                        <span class="icon-text">
                                            <span class="">
                                                Redefinir
                                            </span>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="box form-box p-6" v-show="senhaRedefinida">
                        <p class="fonte-1 is-size-4 title has-text-centered">
                            <span>Senha redefinida com sucesso!</span>
                        </p>
                        <hr>
                        <p class="fonte-1 is-size-5 subtitle has-text-centered">
                            <router-link to="/log-in">
                                <button class="button is-success fonte-1 is-fullwidth" id="btn-entrar">
                                    <span class="icon-text">
                                        <span class="">
                                            Voltar para o Log-in
                                        </span>

                                    </span>
                                </button>
                            </router-link>

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputSenha from '@/components/inputs/InputSenha.vue';
import axios from 'axios';
import { toast } from 'bulma-toast'
export default {

    name: 'redefinirSenha',
    components: { InputSenha },
    data() {
        return {
            token: '',
            erros: [],
            novaSenha1: '',
            novaSenha2: '',
            carregando: false,
            senhaRedefinida: false
        }
    },
    mounted() {
        this.token = this.$route.params.token
    },
    methods: {
        async submitForm() {
            this.erros = []
            this.carregando = true
            if (this.novaSenha1 === this.novaSenha2) {
                await axios.post('/api/v1/usuarios/redefinir-senha/', { nova_senha: this.novaSenha2, token: this.token })
                    .then(response => {



                        this.$store.commit('setSenhaAlterada', true)
                        localStorage.setItem('isSenhaAlterada', true)
                        const msg = `<i class='mdi mdi-lock mr-2'><span>  Sua senha foi redefinida com sucesso.</span>`


                        this.senhaRedefinida = true
                    })
                    .catch(error => {
                        if (error.response?.status === 400 || error.response?.status === 401) {
                            this.erros = error.response.data[0]
                            //console.log(error.response.data[0])
                        } else {
                            console.log(error)
                        }
                    })
            } else {

                this.erros.push('As senhas não conferem')
            }
            this.carregando = false
        },
    }
}
</script>

<style lang="scss" scoped>
.hero-body {
    background: rgb(122, 46, 46);
    background: linear-gradient(100deg, rgba(122, 46, 46, 1) 0%, rgba(87, 18, 18, 1) 100%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    align-items: center !important;
}

.columns {
    width: 100%;
}

#btn-entrar {
    background-color: rgb(122, 46, 46);
    color: $secondary-color;
    font-family: 'Poppins';
}

#btn-entrar {
    display: block !important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.form-box {
    border-radius: 30px;
    box-shadow: 6px 6px 2px $primary-color;
}

button:hover {
    display: block !important;
}
</style>