<template>
    <div>
        <canvas height="300" width="250" ref="mychart3" id="mychart3" />
    </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';

import { markRaw } from 'vue'
import plugin from 'chartjs-plugin-datalabels';

export default {
    name: 'graficoReservaPorCanal',
    data() {
        return {
            mychart: null,
            dados: []
        }
    },

    mounted() {
        const ctx = this.$refs.mychart3
        Chart.register(ChartDataLabels);
        const plugin = {
            id: 'graficoVazio',
            afterDraw(chart, args, options) {
                const { datasets } = chart.data;
                const { color, width, radiusDecrease } = options;
                let hasData = false;


                hasData |= datasets[0].data.some(el => el > 0);
                if (hasData === 0) {
                    const { chartArea: { left, top, right, bottom }, ctx } = chart;
                    const centerX = (left + right) / 2;
                    const centerY = (top + bottom) / 2;
                    const r = Math.min(right - left, bottom - top) / 2;

                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.arc(centerX, centerY, (r - radiusDecrease + 10), 0, 2 * Math.PI);

                    ctx.stroke();
                }
            }
        };
        const chartObj = new Chart(ctx, {
            type: 'pie',
            plugins: [plugin],

            data: {
                labels: ['Particular', 'Agência', 'Operadora', 'OTA'],
                datasets: [{
                    label: 'Reservas',
                    data: this.dados,
                    backgroundColor: ["#411F2D", "#BDA5AD", "hsl(34, 78%, 91%)", "#AA5E4D"],
                    // datalabels: {
                    //     align: 'start',
                    //     anchor: 'end',
                    // },

                }]
            },



            options: {
                maintainAspectRatio: false,

                plugins: {
                    graficoVazio: {
                        color: 'rgba(255, 128, 0, 0.5)',
                        width: 2,
                        radiusDecrease: 20
                    },
                    title: {
                        display: false,
                        text: 'Reservas por Canal',
                        font: {
                            family: 'Poppins',
                            size: 12,
                            weight: 'bold'

                        },
                        align: 'center'
                    },
                    legend: {
                        position: 'left',
                        display: true,

                        labels: {

                            color: '#411F2D',
                            font: {
                                weight: 'bold',
                                family: 'Poppins',
                                size: 15
                            },
                        },
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {

                            label: function (context) {
                                return context.dataset.data[context.dataIndex] + '%'
                            },

                        }
                    },
                    datalabels: {
                        display: function (context) {
                            return context.dataset.data[context.dataIndex] > 0
                        },
                        color: function (context) {
                            if (context.dataset.backgroundColor[context.dataIndex] === "#411F2D" ||
                                context.dataset.backgroundColor[context.dataIndex] === "#AA5E4D") {
                                return 'white'
                            } else {
                                return "#411F2D"
                            }
                        },
                        font: {
                            weight: 'bold',
                            family: 'Poppins',
                            size: 20
                        },

                        formatter: function (value, context) {
                            // return context.chart.data.labels[context.dataIndex] + ' ' + Math.round(value) + "%"
                            if (value) {
                                return Math.round(value) + "%"
                            } else {
                                return "Sem dados"
                            }

                        },
                        rotation: 0,
                    },
                },
            }
        });


        this.mychart = markRaw(chartObj);
        this.getInfo('week')
    },

    emits: ['graficoSemDados'],
    methods: {
        atualizarGrafico(dados) {
            if (dados == null) {
                this.mychart.data.datasets[0].data = [0, 0, 0, 0]
                // this.mychart.data.labels = []
            } else {
                this.mychart.data.datasets[0].data = dados
                this.mychart.data.labels = ['Particular', 'Agência', 'Operadora', 'OTA']
            }

            this.mychart.update();
        },

        async getInfo(intervalo) {
            await axios.post('api/v1/reservas/get-percentual-reservas-por-canal/', { intervalo: intervalo })
                .then(response => {
                    this.dados = response.data.dados
                    this.atualizarGrafico(this.dados)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped></style>