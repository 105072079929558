<template>
    <div class="columns is-multiline m-0 is-centered">

        <div class="column is-12">
            <form @submit.prevent="getReservas">
                <h1 class="title fonte-1 has-text-left">Filtros</h1>
                <div class="columns is-multiline p-0 ">
                    <div class="column is-4 is-narrow">
                        <div class="field has-addons">
                            <div class="control is-expanded">

                                <input type="text" v-model="filtro" class="input fonte-1"
                                    placeholder="Buscar reserva por Código, Hóspede, CPF ou Acomodação"
                                    id="input-buscar">
                            </div>
                            <div class="control">
                                <button class="button btn-search btn-control">
                                    <strong>
                                        <i class="icon mdi mdi-magnify" />
                                    </strong>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow-desktop is-12-mobile">
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button is-fullwidth is-static static-label">
                                    <span>Status</span>
                                </button>
                            </div>
                            <div class="control is-expanded has-icons-left">
                                <div class="select is-fullwidth">
                                    <select v-model="status" class="fonte-1 input-filtro" @change="getReservas">
                                        <option value="" selected>
                                            <p><i class="mdi mdi-trash-can"></i>---</p>
                                        </option>

                                        <option value="Pré-reserva" class="fonte-1">Pré-reserva
                                        </option>
                                        <option value="Confirmada" class="fonte-1">Confirmada
                                        </option>
                                        <option value="Ocupada" class="fonte-1">Ocupada</option>
                                        <option value="Fechada" class="fonte-1">Fechada</option>

                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <i class="mdi mdi-circle" :style="`color: ${this.cores_status[this.status]}`"></i>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="column  is-narrow-desktop is-12-mobile">
                        <div class="field has-addons">

                            <div class="control">
                                <button class="button is-fullwidth is-static static-label">
                                    <span class="icon-text">
                                        <i class="icon mdi mdi-open-in-app"></i>
                                        <span>Check-in</span>
                                    </span>

                                </button>
                            </div>
                            <div class="control is-expanded">
                                <input type="date" class="input input-filtro " v-model="f_data_checkin"
                                    @change="getReservas">
                            </div>

                            <div class="control is-expanded" v-if="f_data_checkin !== ''">

                                <button class="button is-danger" @click="f_data_checkin = '', getReservas()">
                                    <span class="icon-text">
                                        <i class="icon mdi mdi-trash-can"></i>
                                    </span>

                                </button>

                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button is-fullwidth is-static static-label">
                                    <span class="icon-text">
                                        <i class="icon mdi mdi-exit-to-app"></i>
                                        <span>Check-out</span>
                                    </span>

                                </button>
                            </div>
                            <div class="control is-expanded">
                                <input type="date" class="input input-filtro" v-model="f_data_checkout"
                                    @change="getReservas">
                            </div>
                            <div class="control" v-if="f_data_checkout !== ''">

                                <button class="button is-danger" @click="f_data_checkout = '', getReservas()">
                                    <span class="icon-text">
                                        <i class="icon mdi mdi-trash-can"></i>
                                    </span>

                                </button>

                            </div>
                        </div>
                    </div>

                </div>
            </form>

            <hr class="mb-1">

        </div>
        <div class="column is-12" v-if="reservas.length == 0">
            <div class="notification is-light  has-text-left">
                <span class=" is-size-5 fonte-1">
                    <i class="mdi mdi-information-outline icon" />
                    Nenhum registro de <strong>Reserva</strong> foi encontrado.
                </span>
            </div>
        </div>


        <div class=" column is-12" id="reservas-col" v-if="reservas.length > 0">

            <div id=" reservas-box">

                <div class="table-container" id="tabela-reservas">
                    <table class="table is-bordered is-fullwidth">
                        <thead id="colunas" class="thead_color rounded-thead">
                            <th class="has-text-centered">Código</th>
                            <th class="has-text-centered">Status</th>
                            <th class="">Hóspede</th>
                            <th class="has-text-centered">Acomodação</th>
                            <th class="has-text-centered">Tipo de Acomodação</th>
                            <!-- <th class="has-text-centered">Status UH</th> -->
                            <th class="has-text-centered">Check-in</th>
                            <th class="has-text-centered">Check-out</th>
                            <th class="has-text-centered is-narrow"></th>

                        </thead>

                        <tbody>

                            <template v-for="    reserva     in     reservas    " v-bind:key="reserva.id">

                                <tr class="is-narrow fonte-1" v-if="reserva.status !== 'Cancelada'">

                                    <td>{{ reserva.id }}</td>
                                    <td class="has-text-centered">
                                        <!-- <i class="mdi mdi-circle mr-3" /> -->
                                        <span class="tag is-medium has-text-left"
                                            :class="`is-${formatarStatus(reserva.status)}`">
                                            {{ reserva.status }}
                                        </span>

                                    </td>
                                    <td class="has-text-left">{{ reserva.hospede?.nome }}</td>

                                    <td>{{ reserva.acomodacao.nome }}</td>
                                    <td>{{ reserva.acomodacao.tipo_acomodacao.descricao }}</td>
                                    <td>
                                        {{ new Date(reserva.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}
                                    </td>
                                    <td>
                                        {{ new Date(reserva.data_saida + "T00:00:00").toLocaleDateString('pt-BR') }}
                                    </td>
                                    <td class="has-text-centered">
                                        <div class="buttons is-centered">
                                            <button @click="this.$refs.modalConsulta.ativarModal(reserva)"
                                                class=" button is-small is-dark">
                                                <i class="icon mdi mdi-eye"></i>
                                                <span>Consultar</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <ModalConsultarReserva ref="modalConsulta" />
</template>

<script>

import ModalConsultarReserva from '@/components/Modals/ModalConsultarReserva.vue';
import Navbar from '@/components/layout/Navbar.vue'
import axios from 'axios'

export default {
    name: "Reservas",
    data() {
        return {
            reservas: [],

            filtro: '',

            f_data_checkin: '',

            f_data_checkout: '',

            status: '',

            cores_status: {

                'Pré-reserva': 'rgb(255, 189, 33)',
                'Confirmada': 'rgb(19, 153, 86)',
                'Ocupada': 'rgb(78, 105, 173)',
                'Fechada': 'rgb(173, 78, 78)',
            }


        };
    },



    mounted() {
        this.getReservas();
    },
    methods: {
        async getReservas() {
            const filtros = {
                manutencao: false,
                id: this.filtro,
                hospede_ou_acomodacao: this.filtro,
                status: this.status,
                data_entrada_exata: this.f_data_checkin,
                data_saida_exata: this.f_data_checkout
            }

            await axios
                .get('/api/v1/reservas/', { params: filtros })
                .then(response => {
                    this.reservas = response.data;
                    // //console.log(this.reservas);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        formatarStatus(status) {
            return status.toLowerCase().replace('é', 'e')
        }
    },
    components: { Navbar, ModalConsultarReserva }
}


</script>

<style lang="scss" scoped>
.columns {
    width: 100%;
}

.tag {
    width: 7rem;
}

@media only screen and (max-width: 1920px) {

    #reservas-box {
        min-height: 75dvh !important;
        max-height: 75dvh !important;
    }
}

@media only screen and (max-width: 1430px) {

    #reservas-box {
        min-height: 67dvh !important;
        max-height: 67dvh !important;
    }
}

@media only screen and (max-width: 1280px) {

    #reservas-box {
        min-height: 75dvh !important;
        max-height: 75dvh !important;
    }


}

@media only screen and (max-width: 960px) {

    #reservas-box {
        min-height: 60dvh !important;
        max-height: 60dvh !important;
    }

    #tabela-reservas {
        min-height: 55dvh !important;
        max-height: 55dvh !important;
    }

}
.btn-search {
    background-color: $primary-color;
    color: $secondary-color;
}


#input-buscar {
    outline: none;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: 0px 0px;
    padding-left: 0;
}

.btn-control {
    border: 1px $primary-color solid !important;
}

.button:active,
.button.is-active,
.button:focus,
.button.is-focused {
    color: $secondary-color !important;
}


#filtro-box {
    border: 2px solid $primary-color;
}

.static-label {
    border: 1px $primary-color solid !important;
    background-color: $primary-color !important;
    color: $secondary-color !important;
}

#reservas-box {

    border-radius: 5px;
    bottom: 0;
    padding: 0;
    margin: 0 !important;

}

.input-filtro {
    border: 1px $primary-color solid !important;
}

.input-filtro:focus {
    border: 1px $primary-color solid;
    box-shadow: none !important;
}

.input-filtro:active {
    border: 1px $primary-color solid !important;
    box-shadow: none !important;
}


#filtro-box {
    border: 2px solid $primary-color;
    height: auto;
}
</style>