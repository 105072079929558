<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-confirmacao">
            <header class="modal-card-head" style="background-color: rgb(19, 153, 86);">
                <p class="modal-card-title titulo fonte-2">Confirmar Reserva {{ reserva?.id }}</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>
            </header>

        </div>
        <div class="modal-content has-background-white py-5 px-5" id="conteudo-confirmacao">

            <form @submit.prevent="confirmarReserva" id="form-confirmacao">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-12 pb-1">

                        <div class="field has-text-left">

                            <label class="label fonte-1" for="pagou-sinal">Hóspede pagou o Sinal?</label>

                            <div class="control" v-if="!this.reserva.cortesia">
                                <label class="radio fonte-1">
                                    <input type="radio" v-model="sinal_pago" value='0' name="pagou-sinal"
                                        id="pagou-sinal" checked>
                                    Não
                                </label>
                                <label class="radio fonte-1 ">
                                    <input type="radio" name="pagou-sinal" id="pagou-sinal" value='1'
                                        v-model="sinal_pago">
                                    Sim
                                </label>

                            </div>
                            <div class="control fonte-1 has-text-danger" v-else>
                                <i class="mr-1 mdi mdi-information-outline"></i>
                                <span>Essa reserva é uma cortesia.</span>
                            </div>
                        </div>

                    </div>
                    <div class="column is-6 pb-0">
                        <div class="field " style="width: fit-content;">

                            <label class="label fonte-1 has-text-left" for="currency-input">Valor do sinal:</label>

                            <money-input :disabled="this.sinal_pago === '0'" input-class="input" label-class="label"
                                v-model="valor_sinal" />

                        </div>
                    </div>

                    <div class="column is-6 has-text-left">

                        <label class="label fonte-1">Valor das diárias:</label>
                        <input class="input fonte-1" :disabled="this.sinal_pago === '0'"
                            :value="reserva.valor_diarias.toLocaleString('pt-br', { style: 'currency', currency: 'brl' })"
                            readonly>

                    </div>

                    <div class="column is-6">
                        <div class="field has-text-left">
                            <div class="control">
                                <label for="forma-pagamento" class="label fonte-1">Forma de pagamento:</label>
                                <div class="select">
                                    <select name="" :disabled="this.sinal_pago === '0'" v-model="formaPagamento" id="">
                                        <option value="Transferência/Pix">Transferência/Pix</option>
                                        <option value="Dinheiro">Dinheiro</option>
                                        <option value="Crédito">Crédito</option>
                                        <option value="Débito">Débito</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="column is-12">
                        <div class="field ">
                            <label class="label has-text-left fonte-1" for="textAreaOpcional">Informações adicionais
                                (Opcional):</label>
                            <div class="field">
                                <div class="control">
                                    <textarea id="textAreaOpcional" class="textarea has-fixed-size"
                                        v-model="informacao_adicional" placeholder="Máx. 255 caracteres"
                                        maxlength="255"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



            </form>
            <hr>
            <div class="field is-grouped">
                <div class="control is-expanded has-text-left">
                    <button class="button" @click="modal_ativo = false">
                        Cancelar
                    </button>
                </div>
                <div class="control is-expanded">
                    <button class="button is-success is-fullwidth" form="form-confirmacao">
                        Confirmar
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import MoneyInput from '../inputs/MoneyInput.vue';
import { toast } from 'bulma-toast'

export default {
    name: 'ModalConfirmarReserva',
    components: {
        SvgIcon,
        MoneyInput,
    },

    emits: ['reservaConfirmada'],
    data() {

        return {

            reserva: {},
            sinal_pago: '0',

            valor_sinal: '',

            modal_ativo: false,

            informacao_adicional: '',

            formaPagamento: 'Transferência/Pix'

        }

    },

    computed: {

        valorSinalBRL() {
            return (Number.parseFloat(this.valor_sinal.replace(',', '.'))).toLocaleString('pt-br', { style: "currency", currency: "brl" })
        }
    },

    methods: {
        ativarModal(reserva) {

            this.reserva = reserva

            //console.log(this.valorSinalBRL)

            this.sinal_pago = '0'

            this.valor_sinal = ''

            this.modal_ativo = true

            this.descricao = ''

        },
        async confirmarReserva() {

            this.reserva.status = "Confirmada"
            this.reserva.data_confirmacao = (new Date()).toLocaleDateString('en-ca')

            if (this.sinal_pago === '1') {
                this.reserva.valor_sinal = Number.parseFloat(this.valor_sinal.replace(',', '.'))
                this.reserva.valor_pago += this.reserva.valor_sinal
                var descricao = `O hóspede realizou o pagamento do sinal no valor de 
                                     ${this.valorSinalBRL} na forma de pagamento ${this.formaPagamento}.`
            }
            else {
                var descricao = `A reserva foi confirmada sem o pagamento de sinal.`
            }

            await axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {

                    axios.post('api/v1/historicos/adicionar-historico/',
                        {
                            id_reserva: this.reserva.id,
                            informacao_adicional: this.informacao_adicional,
                            descricao: descricao,
                            categoria: 'Confirmação'
                        })
                        .then(response => {

                            const msg = `<i class='mdi mdi-check-circle mr-2'><span>  Status foi atualizada para <strong>Confirmada</strong>!</span>`

                            toast({
                                message: msg,
                                type: 'is-success is-light',
                                position: 'top-right',
                                duration: 5000,
                                dismissible: true,
                                pauseOnHover: true,
                            })

                            this.modal_ativo = false
                            this.$emit('reservaConfirmada')

                        })
                        .catch(error => {
                            console.log(error)
                        })


                })
                .catch(error => {

                    console.log(error)

                })
        },
    }
}

</script>

<style>
@media only screen and (max-width: 1024px) {

    #conteudo-confirmacao,
    #header-confirmacao {
        width: 97% !important;

    }
}


#conteudo-confirmacao {
    height: auto;
}

#fonte-1 {
    width: fit-content;
}
</style>