<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-adicionar-acomodacoes">
            <header class="modal-card-head">
                <p class="modal-card-title has-text-left fonte-2 ml-1">Adicionar Acomodações</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>
            </header>
        </div>
        <div class="modal-content has-background-white p-5" id="body-adicionar-acomodacoes">

            <section>

                <form @submit.prevent="salvar_acomodacao" id="form-adicionar-acomodacao">

                    <div class="field is-grouped">
                        <div class="control">
                            <label class="label fonte-1 has-text-left" for="quantidade">Quantidade:</label>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <button type="button" class="button is-normal fonte-1 botao-adicionar"
                                        style="z-index: 9999;"
                                        @click="function () { if (quantidade > 1) { quantidade-- } }" id="quantidade">
                                        <i class="mdi mdi-minus-thick"></i>
                                    </button>
                                </div>

                                <div class="control">
                                    <input type="number" required readonly min="1" max="10"
                                        class="input has-text-centered fonte-1 has-text-weight-bold"
                                        v-model="quantidade" id="qtd-input">
                                </div>

                                <div class="control">
                                    <button type="button" class="button is-normal fonte-1 botao-remover "
                                        @click="quantidade++">

                                        <i class="mdi mdi-plus-thick"></i>

                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="control is-expanded">

                            <label for="" class="label fonte-1 has-text-left">Tipo:</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select name="" v-model="tipo_acomodacao" id="" required>

                                        <option v-for="tipo in this.tipos_acomodacao" class="fonte-1" :value="tipo.id"
                                            v-bind:key="tipo.id">
                                            {{ tipo.descricao }}</option>

                                    </select>
                                </div>
                            </div>
                        </div>


                    </div>
                    <hr class="my-5">

                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <button class="button is-fullwidth" @click="modal_ativo = false">Cancelar</button>
                        </div>
                        <div class="control is-expanded">
                            <button class="button is-fullwidth is-success" form="form-adicionar-acomodacao">
                                <i class="icon mdi mdi-plus-thick"></i>
                                <span>
                                    Adicionar
                                </span>
                            </button>
                        </div>
                    </div>

                </form>


            </section>
        </div>

    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'


export default {
    name: 'Modaladicionar-acomodacoes',
    emits: ['atualizarAcomodacoes'],
    data() {
        return {
            modal_ativo: false,
            tipos_acomodacao: {},
            tipo_acomodacao: '',
            quantidade: 1
        };
    },
    methods: {
        ativarModal() {

            this.modal_ativo = true;
            this.get_tipos_acomodacoes();
            this.descricao = '';
            this.quantidade = 1
        },



        async get_tipos_acomodacoes() {

            await axios.get('api/v1/tiposAcomodacoes')
                .then(response => {
                    this.tipos_acomodacao = response.data
                    this.tipo_acomodacao = this.tipos_acomodacao[0].id
                })
        },

        salvar_acomodacao() {

            const dados = {
                quantidade: this.quantidade,
                tipo_acomodacao: this.tipo_acomodacao
            }

            axios.post("api/v1/acomodacoes/salvar_acomodacoes/", dados)
                .then(response => {

                    const msg = `<i class='mdi mdi-check-circle mr-2'><span>  As acomodações foram adicionadas com sucesso!</span>`


                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 3000,
                        dismissible: false,
                        pauseOnHover: true,
                    })

                    this.$emit('atualizarAcomodacoes')

                    this.modal_ativo = false

                })
                .catch(error => {
                    console.log(error)
                })


        }


    },
}

</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1024px) {

    #header-adicionar-acomodacoes,
    #body-adicionar-acomodacoes {
        width: 90% !important;

    }
}

#header-adicionar-acomodacoes,
#body-adicionar-acomodacoes {
    width: 450px;

}


.titulo {
    font-weight: bold;
}
</style>