<template>
    <div class="columns mx-0 is-centered is-multiline" id="container">
        <div class="column is-12 pb-0">
            <div class="box px-5 box-grafico">
                <div class="columns m-0 is-multiline">
                    <div class="column is-6">
                        <p class="title fonte-1 is-size-5 is-size-6-touch has-text-left ">
                            Faturamento de {{ getMesFaturamentoToStr }}, {{ dataFaturamento.ano }}
                        </p>
                    </div>
                    <div class="column is-6 has-text-right">
                        <MonthAndYearPicker v-model="dataFaturamento" label="Mês de consulta"
                            @update:model-value="getFaturamento()"
                            :year-array="Array.from({ length: 4 }, (_, i) => dataHoje.getFullYear() - 3 + i)" />
                    </div>
                    <div class=" column is-3 is-12-touch">
                        <div>
                            <div class="box box-faturamento has-text-centered">
                                <p class="is-size-6 fonte-1">
                                    <span class="has-text-weight-bold ml-2">Total de Reservas</span>
                                    <span class="has-tooltip-right has-tooltip-text-left-desktop has-tooltip-arrow has-tooltip-multiline"
                                        data-tooltip="Calculado com base no valor do sinal e no valor da diárias.">
                                        <i class="icon mdi mdi-help-circle-outline"></i>
                                    </span>
                                </p>
                                <p class="is-size-3 fonte-1 has-text-weight-bold has-text-centered">
                                    <i class="mdi mdi-cash-multiple has-text-success"></i>
                                    {{ faturamentoReservas }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class=" column is-3 is-12-touch">
                        <div>
                            <div class="box box-faturamento" id="box-diaria-media">
                                <p class="is-size-6 fonte-1 has-text-centered">
                                    <span class="has-text-weight-bold ml-2">Diária Média</span>
                                    <span class="has-tooltip-right has-tooltip-text-left-desktop has-tooltip-arrow has-tooltip-multiline"
                                        data-tooltip="Calculado com base no valor de cada diária paga no mês.">
                                        <i class="icon mdi mdi-help-circle-outline"></i>
                                    </span>
                                </p>
                                <p class="is-size-3 fonte-1 has-text-weight-bold has-text-centered">
                                    <i class="mdi mdi-cash-multiple has-text-info"></i>
                                    {{ diariaMedia }} 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class=" column is-3 is-12-touch">
                        <div>
                            <div class="box box-faturamento" id="box-diaria-media">
                                <p class="is-size-6 fonte-1 has-text-centered">
                                    <span class="has-text-weight-bold ml-2">Cortesias</span>
                                    <span class="has-tooltip-right has-tooltip-text-left-desktop has-tooltip-arrow has-tooltip-multiline"
                                        data-tooltip="Quantidade de diárias gratuitas no mês de consulta.">
                                        <i class="icon mdi mdi-help-circle-outline"></i>
                                    </span>
                                </p>
                                <p class="is-size-3 fonte-1 has-text-weight-bold has-text-centered">
                                    <i class="mdi mdi-currency-usd-off has-text-warning"></i>
                                    {{ cortesias }} 
                                    <span class="is-size-4">
                                        diárias
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3 is-12-touch">
                        <div class="box box-faturamento">

                            <p class="is-size-6 fonte-1 has-text-centered">
                                <span class="has-text-weight-bold ml-2">Total de A/B</span>
                                <span class="has-tooltip-right has-tooltip-text-left-desktop has-tooltip-arrow has-tooltip-multiline"
                                    data-tooltip="Calculado com base no valor do consumo dos hóspedes.">
                                    <i class="icon mdi mdi-help-circle-outline"></i>
                                </span>
                            </p>
                            <p class="is-size-3 fonte-1 has-text-weight-bold has-text-centered">
                                <i class="mdi mdi-food has-text-danger"></i>
                                {{ faturamentoAB }}

                            </p>
                        </div>
                    </div>
                    <div class="column is-12">
                        <hr class="my-0">
                    </div>
                    <div class="column is-3-desktop is-6-tablet is-12-mobile ">
                        <div class="box p-3 box-metricas">
                            <p class="is-size-7 has-text-left fonte-2">
                                Disponibilidade
                            </p>
                            <p class="fonte-2 is-size-2 has-text-centered has-text-weight-bold">
                                <i class="mdi mdi-home"></i>
                                {{ percentualDisponibilidade }} %
                            </p>
                        </div>
                    </div>
                    <div class="column is-3-desktop is-6-tablet is-12-mobile ">
                        <div class="box p-3 box-metricas">
                            <p class="is-size-7 has-text-left fonte-2">
                                Check-ins do mês
                            </p>
                            <p class="fonte-2 is-size-2 has-text-centered has-text-weight-bold">
                                <i class="mdi mdi-open-in-app has-text-info"></i>
                                {{ checkinsDoMes }}
                            </p>
                        </div>
                    </div>
                    <div class="column is-3-desktop is-6-tablet is-12-mobile ">
                        <div class="box p-3 box-metricas">
                            <p class="is-size-7 has-text-left fonte-2 ">
                                Reservas Confirmadas
                            </p>
                            <p class="fonte-2 is-size-2 has-text-centered has-text-weight-bold">
                                <i class="mdi mdi-book-account has-text-success"></i>
                                {{ reservasConfirmadas }}
                            </p>
                        </div>
                    </div>
                    <div class="column is-3-desktop is-6-tablet is-12-mobile">
                        <div class="box p-3 box-metricas">
                            <p class="is-size-7 has-text-left fonte-2 ">
                                Pré-reservas
                            </p>
                            <p class="fonte-2 is-size-2 has-text-centered has-text-weight-bold">
                                <i class="mdi mdi-book-account has-text-warning"></i>
                                {{ preReservas }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-4 is-12-touch pb-0">
            <div class="box box-grafico">
                <div class="columns is-vcentered is-multiline">
                    <div class="column is-8-desktop is-12-touch pr-0">
                        <p class="title fonte-1 is-size-6 is-size-6-touch has-text-left  ">
                            Reservas confirmadas por Canal
                        </p>
                    </div>
                    <div class="column is-4-desktop is-12-touch">
                        <div class="field has-addons-right has-addons has-text-right">
                            <div class="control">
                                <button class="button is-static is-small"><i class="mdi mdi-filter"></i></button>
                            </div>
                            <div class="control">
                                <div class="select is-small" id="select-ano">
                                    <select name="" id="" v-model="filtroGraficoCanal"
                                        @change="this.$refs.grafico3.getInfo(filtroGraficoCanal)">

                                        <option value="week">Esta semana</option>
                                        <option value="month">Este mês</option>
                                        <option value="year">Este ano</option>
                                        <option value="today">Hoje</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GraficoReservasPorCanal ref="grafico3" @grafico-sem-dados="" />
            </div>

        </div>

        <div class="column is-4 is-12-touch pb-0">
            <div class="box box-grafico">
                <div class="columns is-multiline is-vcentered">
                    <div class="column is-8-desktop is-12-touch pr-0">
                        <p class="title fonte-1 is-size-6 is-size-6-touch has-text-left ">
                            Taxa de ocupação por mês em {{ anoFiltro }}</p>
                    </div>

                    <div class="column is-4-desktop is-12-touch">
                        <div class="field has-addons-right has-addons has-text-right">
                            <div class="control">
                                <button class="button is-static is-small"><i class="mdi mdi-filter"></i></button>
                            </div>
                            <div class="control">
                                <div class="select is-small" id="select-ano">
                                    <select name="" id="" v-model="anoFiltro"
                                        @change="this.$refs.grafico1.getOcupacaoPorMes(this.anoFiltro)">

                                        <option :value="ano"
                                            v-for="ano in Array.from({ length: 5 }, (_, i) => this.dataHoje.getFullYear() - 1 + i)"
                                            v-bind:key="ano">
                                            {{ ano }}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <GraficoOcupacaoPorMes ref="grafico1" />
                </div>
            </div>
        </div>
        <div class="column is-4 is-12-touch pb-0">
            <div class="box box-grafico">

                <div class="columns is-vcentered is-multiline">
                    <div class="column is-8-desktop is-12-touch pr-0">
                        <p class="title fonte-1 is-size-6 is-size-6-touch has-text-left ">
                            Taxa de ocupação por dia em {{ meses[mesFiltro2] }}
                        </p>
                    </div>
                    <div class="column is-4-desktop is-12-touch">
                        <div class="field has-addons-right has-addons has-text-right">
                            <div class="control">
                                <button class="button is-static is-small"><i class="mdi mdi-filter"></i></button>
                            </div>
                            <div class="control">
                                <div class="select is-small" id="select-ano">
                                    <select name="" id="" v-model="mesFiltro2"
                                        @change="this.$refs.grafico2.getOcupacaoPorDia(this.mesFiltro2 + 1, this.anoFiltro)">

                                        <option class="fonte-1" :value="index" v-for="mes, index in meses"
                                            v-bind:key="index">
                                            {{ mes }}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <GraficoOcupacaoPorDia ref="grafico2" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import GraficoOcupacaoPorMes from '@/components/graficos/GraficoOcupacaoPorMes.vue';
import GraficoOcupacaoPorDia from '@/components/graficos/GraficoOcupacaoPorDia.vue';
import MonthAndYearPicker from '@/components/inputs/MonthAndYearPicker.vue';
import GraficoReservasPorCanal from '@/components/graficos/GraficoReservasPorCanal.vue';

export default {
    name: "insights",
    data() {
        return {
            //Métricas
            disponibilidade: 0,
            preReservas: 0,
            reservasConfirmadas: 0,
            checkinsDoMes: 0,
            faturamentoReservas: 0,
            diariaMedia:0,
            cortesias:0,
            faturamentoAB: 0,
            //Dados
            reservasPorMes: [],
            ocupacaoPorMes: [],
            ocupacaoPorDia: [],
            dataFaturamento: {},
            //Filtros
            mesFiltro2: '',
            dataInicioIntervalo: '',
            dataFinalIntervalo: '',
            anoFiltro: '',
            filtroGraficoCanal: 'week',
            //Infos
            dataHoje: new Date(),
            meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],




        };
    },
    mounted() {
        this.user = this.$store.state.user;
        this.hospedagem = this.$store.state.hospedagem;

        this.anoFiltro = this.dataHoje.getFullYear()
        this.mesFiltro2 = this.dataHoje.getMonth()

        this.dataFaturamento = {
            mes: this.dataHoje.getMonth(),
            ano: this.dataHoje.getFullYear()
        }

        this.$refs.grafico1.getOcupacaoPorMes(this.anoFiltro)
        this.$refs.grafico2.getOcupacaoPorDia(this.mesFiltro2 + 1, this.anoFiltro)

        this.getFaturamento();
        this.getDisponibilidade();
        this.getReservas();
        this.getChekinsDoMes();

    },
    computed: {
        getMesFaturamentoToStr() {
            const mes = this.dataFaturamento.mes
            return this.meses[mes]
        },
        percentualDisponibilidade() {
            //console.log(isNaN(this.disponibilidade))
            if (isNaN(this.disponibilidade)) {
                return 0
            }
            return Math.round(this.disponibilidade * 100) / 100;
        }
    },
    methods: {
        async getDisponibilidade() {
            await axios.get("api/v1/acomodacoes")
                .then(response => {
                    let disponiveis = response.data.filter(acomodacao => !acomodacao.ocupado).length;
                    let total = response.data.length
                    this.disponibilidade = disponiveis / total * 100;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async getChekinsDoMes() {

            const filtro = {
                data_entrada_range: 'month',
                status__in: 'Ocupada,Fechada'
            }

            await axios.get("api/v1/reservas", { params: filtro })
                .then(response => {
                    //console.log(response.data)
                    this.checkinsDoMes = response.data.length;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async getReservas() {
            await axios.get("api/v1/reservas")
                .then(response => {
                    this.reservas = response.data;
                    this.reservasConfirmadas = this.reservas.filter(reserva => reserva.status == "Confirmada").length;
                    this.preReservas = this.reservas.filter(reserva => reserva.status == "Pré-reserva").length;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getFaturamento() {

            await axios.post("api/v1/reservas/get_faturamento_do_mes/", { mes: this.dataFaturamento.mes + 1, ano: this.dataFaturamento.ano })
                .then(response => {

                    this.faturamentoReservas = response.data.faturamento_reservas.toLocaleString("pt-br", {
                        style: 'currency',
                        currency: 'brl'
                    })
                    this.faturamentoAB = response.data.faturamento_ab.toLocaleString("pt-br", {
                        style: 'currency',
                        currency: 'brl'
                    })

                    this.diariaMedia = response.data.diaria_media.toLocaleString("pt-br", {
                        style: 'currency',
                        currency: 'brl'
                    })

                    this.cortesias = response.data.cortesias



                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    components: { GraficoOcupacaoPorMes, GraficoReservasPorCanal, GraficoOcupacaoPorDia, MonthAndYearPicker }
}

</script>

<style scoped lang="scss">


.columns {
    width:100% !important;
}


#box-diaria-media {
    position: relative;
}

#texto-cortesias {
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    width: 100%;
}

#container {
    overflow-x: hidden;
    max-width: auto;
}

#opcoes_perfil {
    background-color: $primary-color !important;
}

.box-metricas {
    background-color: $primary-color;

}

.box-faturamento {

    border: $primary-color 2px dashed;

}

.box-grafico {
    height: 100%;
    border: $primary-color 1px solid;
}



.opcao:hover span {
    color: $primary-color !important;

}

#perfil {

    background-color: $primary-color;
    /* border-radius: 40px 0px 0px 0px; */

}

#avatar {
    border: $primary-color 2px solid !important;
    border-radius: 5px;
}

.title-box {
    background-color: rgb(65, 31, 45);
}

p.title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

p.fonte-1  span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.titulo {
    color: $secondary-color;
}

#filtro-mes-1 {
    background-color: var(--brand) !important;

}

#filtro-mes-1[type="month"]::-webkit-inner-spin-button,
#filtro-mes-1[type="month"]::-webkit-calendar-picker-indicator {
    /* display: none;
    -webkit-appearance: none; */
    cursor: pointer !important;
    margin: 0;
    filter: invert(100%);
}

#filtro-mes-1[type="month"]::-webkit-calendar-picker-indicator {
    padding-left: 10px;
    border: white 1px dashed !important;

}

#filtro-mes-1[type="month"]::-webkit-datetime-edit {
    display: none;
}

/*
.hospede-box:hover {
    box-shadow: 3px 3px rgb(0, 0, 0, 0.2);
    transform: translate(-5px, 0px)
}*/
</style>
