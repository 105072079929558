<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="columns">
                <div class="column is-4 is-offset-4-desktop">
                    <div class=" box p-6 form-box" v-show="!emailEnviado">
                        <p class="fonte-1 is-size-5 title has-text-centered">Esqueci minha senha</p>
                        <hr>
                        <p class="fonte-1 is-size-6 subtitle has-text-left">
                            Para redefinir sua senha, informe o seu e-mail
                            usado no cadastro e enviaremos as instruções.
                        </p>
                        <form @submit.prevent="submitForm">

                            <div class="field">
                                <!-- <label class="label fonte-1 has-text-left" for="new-password2">
                                    E-mail
                                </label> -->
                                <div class="control has-icons-left ">

                                    <input type="e-mail" name="email" placeholder="exemplo@email.com" required
                                        class="input is-medium" v-model="email">

                                    <span class="icon is-left is-large">
                                        <i class="mdi mdi-lock " />
                                    </span>
                                </div>
                            </div>

                            <br>
                            <div class="notification is-danger is-light has-text-left " v-if="erros.length">
                                <ul>
                                    <li v-for="error in erros" class="is-size-6 mb-2" v-bind:key="error">{{ error }}
                                    </li>
                                </ul>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button class="button is-success fonte-1 is-fullwidth"
                                        :class="{ 'is-loading': this.carregando }" id="btn-continuar">
                                        <span class="icon-text">
                                            <span class="">
                                                Continuar
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="field mt-5 ml-1">
                                <div class="control has-text-left">
                                    <router-link to="/log-in" class="fonte-1">
                                        Cancelar
                                    </router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="box p-6 form-box" v-show="emailEnviado">
                        <p class="fonte-1 is-size-4 title has-text-centered">

                            <span>E-mail enviado!</span>
                        </p>
                        <hr>
                        <p class="fonte-1 is-size-5 subtitle has-text-centered">
                            Enviamos as instruções de redefinição de senha para o e-mail informado. Caso não tenha
                            recebido <a @click="emailEnviado = false">clique aqui</a> para reenviar.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "resetarSenha",
    data() {
        return {
            email: '',
            erros: [],
            carregando: false,
            emailEnviado: false,
        }
    },

    methods: {
        async submitForm() {
            this.erros = []
            this.carregando = true
            await axios.post('api/v1/usuarios/solicitar-redefinicao-senha/', { email: this.email })
                .then(response => {

                    //console.log(response)
                    this.emailEnviado = true
                })
                .catch(error => {
                    this.erros.push(error.response?.data[0])
                })
            this.carregando = false
        }
    }
}
</script>

<style lang="scss" scoped>
.hero-body {
    background: rgb(122, 46, 46);
    background: linear-gradient(100deg, rgba(122, 46, 46, 1) 0%, rgba(87, 18, 18, 1) 100%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    align-items: center !important;
}

.columns {
    width: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.form-box {
    border-radius: 30px;
    min-height: 30dvh;
    box-shadow: 6px 6px 2px $primary-color;
}

button:hover {
    display: block !important;
}

#btn-continuar {
    background-color: rgb(122, 46, 46);
    color: $secondary-color;
    font-family: 'Poppins';
}
</style>