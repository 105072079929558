<template>
    <div class="columns is-multiline">
        <div class="column is-12 has-text-left">

            <h1 class="title fonte-1 is-size-4-touch pb-1">
                <span class="icon-text">
                    <i class="icon mdi mdi-home-group " />
                    <span>Acomodações</span>
                </span>
            </h1>
            <h2 class="subtitle is-size-5-touch fonte-1">Gerencie as acomodações de sua hospedagem.</h2>
            <hr class="my-2">
        </div>

        <div class="column is-12 has-text-left py-2" v-show="tiposCadastrados">
            <button class="button is-medium is-responsive is-success" @click="this.$refs.modalAddAcomod.ativarModal()">
                <i class="mdi mdi-plus icon" />
                <span>Adicionar</span>
            </button>
        </div>


        <div class="column is-6-desktop"  v-if="acomodacoes.length > 0">

            <div class="table-container">

                <table class="table is-fullwidth">

                    <thead class="thead_color">

                        <th class="has-text-left">Nome</th>
                        <th class="has-text-left">Tipo UH</th>
                        <!-- <th class="has-text-left">Status</th> -->
                        <th class="has-text-centered "></th>

                    </thead>

                    <tbody>
                        <tr v-for="acomodacao in acomodacoes" v-bind:key="acomodacao.id">
                            <td class="has-text-left fonte-1">
                                <span>
                                    {{ acomodacao.nome }}
                                </span>
                            </td>

                            <td class="has-text-left fonte-1">{{ acomodacao.tipo_acomodacao.descricao }}</td>
                            <!-- <td class="has-text-left fonte-1">{{ acomodacao.status }}</td> -->

                            <td class="has-text-centered fonte-1">
                                <div class="buttons is-right">
                                    <button class="button is-small has-tooltip-left"
                                        @click="this.$refs.modalEditarAcomodacao.ativarModal(acomodacao)">

                                        <span class="is-hidden-desktop ">
                                            <i class="mdi mdi-pencil icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch has-text-weight-bold">
                                             <i class="mdi mdi-pencil icon" />
                                             <span>
                                                 Editar
                                             </span>
                                         </span>

                                    </button>
                                    <button class="button is-small is-danger has-tooltip-left"
                                        @click="acomodacaoSelecionada = acomodacao; modalApagarAtivo = true">

                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-delete icon" />
                                        </span>

                                        <span class="is-hidden-touch">
                                            <i class="mdi mdi-delete icon" />
                                            <span>
                                                Apagar
                                            </span>
                                        </span>

                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="column is-12" v-else>
            <div class="notification is-warning is-light" v-if="!tiposCadastrados">

                <p class="fonte-1 is-size-5">
                    <i class="icon mdi mdi-alert mr-2"></i>
                    <span>Para adicionar acomodações é necessário que pelo menos um <strong>Tipo de Acomodação</strong>
                        esteja cadastrado.</span>
                </p>
            </div>
            <div class="notification" v-else>
                <p class="fonte-1 is-size-5">
                    <i class="icon mdi mdi-information-outline mr-2"></i>
                    <span>Você ainda não cadastrou nenhuma <strong>Acomodação</strong>.</span>
                </p>
            </div>
        </div>

    </div>

    <div class="modal is-active" v-if="modalApagarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body" id="modal_exclusao">

                <p class="modal-card-title has-text-left  is-size-5 fonte-1 ">

                    Deseja mesmo <span class="has-text-danger">apagar</span> a acomodação <strong>{{
            acomodacaoSelecionada.nome }}</strong>?

                </p>
                <hr class="">
                <div class="notification is-danger is-light">
                    <p class="has-text-left has-text-danger is-size-6 fonte-1">
                        <span class="icon">
                            <i class="mdi mdi-alert">
                            </i>
                        </span> Essa ação irá apagar todas as reservas vinculadas a essa acomodação.
                    </p>
                </div>
                <hr>
                <div class="field is-grouped">
                    <div class="control is-expanded">
                        <button class="button is-fullwidth has-text-weight-bold "
                            @click="modalApagarAtivo = !modalApagarAtivo">Cancelar</button>
                    </div>
                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-danger"
                            @click="excluirAcomodacao(acomodacaoSelecionada.id)">Apagar</button>
                    </div>

                </div>
            </section>
        </div>
    </div>


    <ModalAdicionarAcomodacoes ref="modalAddAcomod" @atualizar-acomodacoes="this.getAcomodacoes" />
    <ModalEditarAcomodacao ref="modalEditarAcomodacao" @acomodacao-atualizada="this.getAcomodacoes" />
</template>

<script>

import axios from 'axios';
import { toast } from 'bulma-toast';
import ModalEditarAcomodacao from '@/components/Modals/ModalEditarAcomodacao.vue';
import ModalAdicionarAcomodacoes from '@/components/Modals/ModalAdicionarAcomodacoes.vue';

export default {

    name: 'tabAcomodacoes',
    components: { ModalAdicionarAcomodacoes, ModalEditarAcomodacao },
    data() {
        return {
            acomodacoes: [],
            tiposCadastrados: true,
            modalApagarAtivo: false,
            acomodacaoSelecionada: null,
        }
    },

    mounted() {

        this.getAcomodacoes()
        this.verificaTiposAcomodacao()
    },

    methods: {

        async getAcomodacoes() {

            await axios.get('api/v1/acomodacoes/')
                .then(response => {

                    this.acomodacoes = response.data

                })
        },

        async verificaTiposAcomodacao() {

            await axios.get('api/v1/tiposAcomodacoes/')
                .then(response => {

                    this.tiposCadastrados = response.data.length > 0

                })
        },


        async excluirAcomodacao(id) {

            await axios.delete(`api/v1/acomodacoes/${id}`)
                .then(response => {

                    toast({
                        message: '<span class="mdi mdi-trash-can"></span> Acomodação excluída!',

                        type: 'is-success-outline',
                        position: 'top-right',
                        dismissible: false,
                        pauseOnHover: true,
                    })


                    this.getAcomodacoes()
                    this.modalApagarAtivo = false


                })
                .catch(error => {
                    console.log(error)
                })
        },

    }



}
</script>

<style lang="scss" scoped>
.modal-card-body {
    border-radius: 5px;
    padding: 2rem;
}

@media screen and (max-width: 763px) {
    table .button {
        width: auto !important;
        aspect-ratio: 1;
    }
    .modal-card-body {
        border-radius: 5px;
        margin: 1rem !important;
        width: auto !important;
    }
}



table .button {
    width: 7rem;
}


table td {

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

table {
    table-layout: fixed;
}

</style>