<template>
    <div class="modal is-active" v-if="modal_ativo">

        <div class="modal-background"></div>
        <div class="modal-card cartao">
            <header class="modal-card-head" id="header-consultarReserva">
                <p class="modal-card-title fonte-2 titulo">Manutenção</p>
                <button class="delete is-large" aria-label="close"
                    @click="this.modal_ativo = !this.modal_ativo"></button>

            </header>
        </div>
        <div class="modal-content cartao-manutencao has-background-white">
            <section class="section p-5">

                <div class="columns is-multiline is-mobile">

                    <div class="column is-6-desktop is-12-touch">
                        <p class="subtitle has-text-left fonte-1 is-size-6-desktop is-size-7-touch">
                            <strong>Data de Início:</strong>
                        </p>
                        <p class="title has-text-left fonte-1 is-size-5">
                            {{ new Date(reserva?.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}
                        </p>

                    </div>
                    <div class="column is-6-desktop is-12-touch">
                        <p class="subtitle has-text-left fonte-1 is-size-6-desktop is-size-7-touch">
                            <strong>Previsão de Conclusão:</strong>
                        </p>
                        <p class="title fonte-1 has-text-left is-size-5">
                            {{ new Date(reserva?.data_saida + "T00:00:00").toLocaleDateString('pt-BR') }}
                        </p>
                    </div>

                    <!-- <div class="column is-6-desktop is-12-touch">
                        <p class="subtitle has-text-left fonte-1 is-size-6-desktop is-size-7-touch pb-0">
                            <strong>Acomodação:</strong>
                        </p>
                        <p class="title has-text-left fonte-1 is-size-5"> <span
                                v-if="Number(reserva?.acomodacao?.nome)">Quarto</span>
                            {{ reserva?.acomodacao?.nome ? reserva?.acomodacao?.nome : '-' }}</p>
                    </div> -->
                    <!-- <div class="column is-6-desktop is-12-touch">
                        <p class="subtitle has-text-left fonte-1 is-size-6-desktop is-size-7-touch">
                            <strong>Categoria:</strong>
                        </p>
                        <p class="title has-text-left fonte-1 is-size-5">{{
        reserva?.acomodacao?.tipo_acomodacao?.descricao
            ?
            reserva?.acomodacao?.tipo_acomodacao?.descricao : '-' }}</p>
                    </div> -->


                    <div class="column is-12">

                        <p class="is-size-6-desktop is-size-7-touch fonte-1 has-text-left mb-2">
                            <strong>Descrição:</strong>
                        </p>
                        <article class="message is-dark">
                            <div class="message-body ">
                                <p class="title fonte-1 has-text-left is-size-6">
                                    {{ this.reserva?.descricao }}
                                </p>
                            </div>
                        </article>
                    </div>

                    <div class="column is-12">
                        <!-- <div class="buttons is-right" v-if="!confirmacao">
                            <button class="button is-medium is-fullwidth" id="btn-concluir"
                                @click="confirmacao = !confirmacao">
                                Concluir Manutenção
                            </button>
                        </div> -->
                        <hr class="mt-1">
                        <div class="" id="box_confirmacao notification">
                            <div class="columns is-vcentered">

                                <div class="column">
                                    <p class="title has-text-left is-size-6-desktop is-size-6-touch fonte-1 ">
                                        Deseja concluir essa manutenção?
                                    </p>
                                </div>

                                <div class="column is-narrow">
                                    <div class="buttons is-right ">
                                        <button class="button is-small" @click="modal_ativo = !modal_ativo">
                                            <span class="icon-text has-text-weight-bold">
                                                <span>Cancelar</span>
                                            </span>
                                        </button>

                                        <button class="button is-info is-small" @click="concluirManutencao">
                                            <span>Concluir</span>
                                        </button>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </section>

        </div>
    </div>
</template>


<script>

import axios from 'axios'


import { toast } from 'bulma-toast'

export default {

    name: "ModalConsultarManutencao",


    components: {
    },

    data() {
        return {

            modal_ativo: false,
            mudanca: false,


            reserva: {},

            valor_sinal: 0.0,

            confirmacao: false,

            // showConfirmacao: false,

            historicos: {},

            pre_reserva: false,

            cores_status: {

                'Pré-reserva': 'rgb(255, 189, 33)',
                'Confirmada': 'rgb(19, 153, 86)',
                'Ocupada': 'rgb(78, 105, 173)',
                'Bloqueada': 'rgb(173, 78, 78)',
            }


        }
    },
    emits: ['manutencaoConcluida'],

    methods: {

        ativarModal(reserva) {

            this.reserva = reserva

            //console.log(reserva)

            this.confirmacao = false
            this.pre_reserva = reserva.status === 'Pré-reserva'

            this.modal_ativo = true

        },



        concluirManutencao() {

            const acomodacao = this.reserva.acomodacao
            acomodacao.status = 'Sujo'

            axios.delete(`api/v1/reservas/${this.reserva.id}`)
                .then(response => {
                    axios.patch(`api/v1/acomodacoes/${acomodacao.id}/`, acomodacao)

                        .then(response => {
                            const msg = `<i class='mdi mdi-check-circle mr-2'><span> Manutenção concluída.</span>`

                            toast({
                                message: msg,
                                type: 'is-success is-light',
                                position: 'top-right',
                                duration: 6000,
                                dismissible: true,
                                pauseOnHover: true,
                            })

                            this.$emit('manutencaoConcluida')
                            this.modal_ativo = false;
                        })

                        .catch(error => {
                            console.log(error)
                        })


                    // this.$router.go()
                })
                .catch(error => {

                    console.log(error)

                })

        },


        get_historicos() {

            // //console.log(this.reserva)
            const request = {
                reserva: this.reserva.id
            }

            axios.post('api/v1/historicos/get_historicos/', request)
                .then(response => {

                    this.historicos = response.data.historicos

                })
                .catch(error => {

                    console.log(error)

                })

        },

        close() {

            if (mudanca) {
                this.$router.go()
            }
            else {
                this.modal_ativo = false
            }

        },


    }
}


</script>

<style scoped lang="scss">
@media only screen and (max-width: 1024px) {

    .cartao,
    .cartao-manutencao {
        width: 97% !important;

    }
}

.cartao {

    height: auto;
}

.cartao-manutencao {
    height: auto !important;
    border-radius: 0 0 5px 5px;
}

#btn-concluir {
    background-color: $primary-color;
    color: white;
    box-shadow: rgb(0, 0, 0) 1px 1px 3px;
    border-radius: 5px;
    border: 1px #411F2D solid;
    font-family: 'Poppins';

}

#btn-concluir:hover {
    box-shadow: rgb(0, 0, 0) 5px 5px 5px;

}


#box_confirmacao {
    background-color: #411F2D !important;

}


#header-consultarReserva {
    background-color: #411F2D;
}

.titulo {

    color: white;
    box-shadow: inset black;
}
</style>