<template>
    <div class="hero is-fullheight">
        <div class="hero-body">
            <div class="container px-0 is-fluid has-text-centered">
                <div class="columns m-0  is-centered">
                    <div class="column  is-narrow-widescreen is-8-desktop   px-0 is-hidden-tablet-only is-hidden-touch">
                        <img src="@/assets/login_foto.jpg" alt="Foto do Olimpo" id="foto">
                        <!-- <figure class="image is-3by2">
                        </figure> -->
                    </div>

                    <div class="column   is-4-desktop  is-narrow-tablet is-12-mobile px-0"  id="form-column">
                        <div class="box login-box ">
                            <div class="form-container column is-9-widescreen is-10-desktop is-12-tablet">
                                <!-- <p class="title fonte-1 mt-0">Atena's Hub</p> -->
                                
                                <p class="has-text-centered">
                                    <img src="@/assets/logo.png"  alt="Logo Atena Hub" id="logo">
                                </p>
                                
                               
                                <hr>
                                <form @submit.prevent="submitForm">
                                    <div class="field">
                                        <label class="label fonte-1 has-text-left" for="email">E-mail</label>
                                        <div class="control has-icons-left">
                                            <input type="email" autocomplete="username" class="input  "
                                                name="email" placeholder='exemplo@email.com' required v-model="email"
                                                id="email">
                                            <span class="icon is-left is-large">
                                                <i class="mdi mdi-email" />
                                            </span>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="field">
                                        <label class="label fonte-1 has-text-left" for="senha">Senha</label>
                    
                                        <InputSenha v-model="password" class="fonte-1" />

                                        <div class="control has-text-right mt-3">
                                            <router-link to="/esqueci-minha-senha" id="resetar-senha">
                                               <span class="is-size-7"> Esqueceu sua senha?</span>
                                            </router-link>

                                        </div>

                                    </div>

                                    <br>
                                    <div class="notification is-danger is-light" v-if="errors.length">
                                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                                    </div>


                                    <button class="button is-block is-fullwidth"
                                        :class="{ 'is-loading': this.carregando }" id="btn-entrar">
                                        <span class="icon-text">
                                            <span class="">
                                                Entrar
                                            </span>
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>

        </div>
    </div>
</template>

<script>
import InputSenha from '@/components/inputs/InputSenha.vue';
import router from '@/router';
import axios from 'axios';

export default {
    name: "LogIn",
    components: {
        InputSenha
    },
    data() {
        return {
            email: '',
            password: '',
            is_superuser: false,
            errors: [],
            error: true,
            user: {
                id: '',
                nome: '',
            },
            usuario: null,
            token: '',
            carregando: false
        }
    },

    mounted() {
        if (this.$store.state.isAuthenticated) {
            router.push('/painel')
        }
    },

    methods: {
        async submitForm() {

            this.carregando = true
            this.errors = []

            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')

            const formData = {
                email: this.email,
                password: this.password
            }

            await axios
                .post('/api/v1/token/login/', formData)

                .then(response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common['Authorization'] = 'Token ' + token
                    localStorage.setItem('token', token)
                })
                .catch(error => {
                    if (error.response) {
                        this.error = true

                        this.errors.push('E-mail ou Senha incorreto(s)')

                    } else {
                        this.error = true
                        this.errors.push('Algo deu errado. Tente novamente!')
                    }
                })
            if (this.errors.length == 0) {

                await axios
                    .get('/api/v1/users/me')
                    .then(response => {
                        this.$store.commit('setUser', { 'id': response.data.id, 'username': response.data.username, 'email': response.data.email })
                        localStorage.setItem('email', response.data.email)
                        localStorage.setItem('userid', response.data.id)
                        localStorage.setItem('firstLogin', response.data.last_login === null)
                        const id = response.data.id
                        axios.get(`/api/v1/usuarios/${id}`).then(response => {

                            this.usuario = response.data
                            this.$store.commit('setStaff', response.data.is_staff)
                            localStorage.setItem('isStaff', this.$store.state.isStaff)
                            this.$store.commit('setSenhaAlterada', this.usuario.senha_alterada)
                            localStorage.setItem('isSenhaAlterada', this.usuario.senha_alterada)

                            axios.get('/api/v1/hospedagens', { params: { user: this.$store.state.user.id } })
                                .then(response => {

                                    const hospedagem = response.data[0]

                                    localStorage.setItem('hospedagem_nome', hospedagem.nome)
                                    localStorage.setItem('hospedagem_id', hospedagem.id)
                                    this.$store.commit('setHospedagem', { 'id': hospedagem.id, 'nome': hospedagem.nome })

                                    if (this.$store.state.senhaAlterada) {
                                        this.$router.push('/painel')
                                    } else {
                                        this.$router.push('/alterar-senha')
                                    }

                                })

                        })

                    })
                    .catch(error => {

                        console.log(error)

                    })

            }

            this.error = true
            this.username = ''
            this.password = ''
            this.carregando = false

        }
    }
}

</script>


<style lang="scss" scoped>
.hero-body {
    background: rgb(122, 46, 46);
    background: linear-gradient(100deg, rgba(122, 46, 46, 1) 0%, rgb(65, 13, 13) 100%);
    animation: gradient 10s ease infinite;
    background-size: 400% 400%;
    align-items: center !important;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.login-box {
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 6px 2px $primary-color;
    height: 100%;
}


#btn-entrar {
    background-color: #7a2e2e;
    color: #faebd7;
    font-family: 'Poppins';
}

#btn-entrar {
    display: block !important;
}

.columns {
    width: 100%;
}


#foto {
    border-radius: 10px 0px 0px 10px;
    box-shadow: 6px 6px 2px $primary-color;
    height: 100%;
    max-height: 100%

}

#logo{
    height: 200px !important;
    width: 200px !important;

}

@media screen and (max-width: 1023px) {
    .columns {
        width: 100%;
    }

    .form-container{
        padding: 1rem
    }

    .login-box {
        border-radius: 10px !important;
    }

}

@media screen and (max-width: 400px) {
    .columns {
        width: auto;
    }



}

#resetar-senha {
    font-family: Poppins;
}


</style>