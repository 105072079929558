<template>
    <canvas height="300" width="250" ref="mychart" id="myChart"></canvas>
</template>


<script>
import { Chart } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios'

export default {
    name: 'GraficoOcupacaoPorMes',
    data() {
        return {
            dados: [],
            colunas: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

            meses: {

            },


            chart: {},

        }
    },

    mounted() {
        const ctx = this.$refs.mychart
        Chart.register(ChartDataLabels);

        var sum = 0
        var cont = 0
        for (var i = 0; i < this.dados.length; i++) {
            sum += this.dados[i]
            cont++
        }
        const backgroundColors = []
        const borderColors = []
        const lineData = []
        const lineData2 = []
        const media = sum / cont

        for (i = 0; i < this.dados.length; i++) {
            if (this.dados[i] >= media) {
                backgroundColors.push('rgba(75, 192, 192, 0.5)')
                borderColors.push('rgba(75, 192, 192)')
                // } else if (this.dados[i] < media && this.dados[i] > 10) {
                //     backgroundColors.push("rgba(255, 159, 64, 0.5)")
                //     borderColors.push("rgba(255, 159, 64)")
            } else {
                backgroundColors.push('rgba(255, 99, 132, 0.5)')
                borderColors.push('rgba(255, 99, 132)')
            }
            // lineData.push(parseInt(media.toFixed(0)))
            lineData.push(media)
            lineData2.push(10)
        }

        // //console.log(lineData)
        let meses_ticks = this.colunas

        this.mychart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.colunas,
                datasets: [{
                    type: 'bar',
                    label: 'Ocupação',
                    data: this.dados,
                    borderWidth: 2,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,

                    order: 2,

                    datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }
                }]
            },

            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {

                        ticks: {
                            callback:
                                function (value, index, ticks) {
                                    return meses_ticks[index].substring(0, 3);

                                },
                        },
                        beginAtZero: true,


                    },
                    y: {

                        ticks: {
                            callback:
                                function (value, index, ticks) {
                                    return value + '%';

                                },
                            font: {
                                size: 8
                            },
                            stepSize: 25
                        },
                        title: {
                            text: "Ocupação",
                            display: true,
                            font: {
                                family: 'Poppins',
                                size: 15,
                                weight: 'bold'

                            },
                            padding: { top: 0, left: 0, right: 0, bottom: 10 }
                        },

                        suggestedMax: 100,


                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    title: {
                        display: false,
                        text: 'Taxa de ocupação por mês ',
                        font: {
                            family: 'Poppins',
                            size: 14,
                            weight: 'bold'

                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 10 },
                        align: 'center'

                    },
                    legend: {
                        display: false,
                        align: 'start',
                        position: 'bottom',
                    },

                    tooltip: {
                        displayColors: false,
                        callbacks: {

                            label: function (context) {
                                return "Ocupação " + context.dataset.data[context.dataIndex] + '%'
                            },

                        }
                    },

                    datalabels: {
                        color: 'black',
                        font: {
                            weight: 'bold'
                        },
                        display: function (context) {
                            return context.dataset.data[context.dataIndex] > 0
                        },
                        formatter: function (value, context) {
                            return value + "%"
                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 1 }

                    },


                }
            },

        });

    },
    methods: {
        atualizarLabels(labels) {
            this.mychart.data.labels = labels
            this.mychart.update();
        },
        atualizarGrafico(dados, ano) {
            var sum = 0
            var cont = 0
            for (var i = 0; i < dados.length; i++) {
                sum += dados[i]
                cont++
            }
            const backgroundColors = []
            const borderColors = []
            const lineData = []

            const media = sum / cont

            for (i = 0; i < dados.length; i++) {
                if (dados[i] >= media) {
                    backgroundColors.push('rgb(65, 31, 45,0.5)')
                    borderColors.push('rgb(65, 31, 45,0.7)')

                } else {
                    backgroundColors.push('rgba(255, 99, 132, 0.5)')
                    borderColors.push('rgba(255, 99, 132)')
                }
            }


            this.mychart.data.datasets[0].data = dados
            // this.mychart.options.plugins.title.text = 'Taxa de Ocupação por mês em ' + ano
            this.mychart.data.datasets[0].backgroundColor = backgroundColors
            this.mychart.data.datasets[0].borderColor = borderColors

            // this.mychart.data.datasets[1].data = this.dados

            this.mychart.update();
        },

        async getOcupacaoPorMes(ano) {


            await axios.post('api/v1/reservas/get_ocupacao_por_mes/', { ano: ano })
                .then(response => {

                    this.dados = response.data
                    this.atualizarGrafico(this.dados, ano)
                })

                .catch(error => {
                    console.log(error)
                })
        }
    }
}


</script>

<style></style>