<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-acomodacao">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2 has-text-left ml-1">Editar Acomodação</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>

            </header>

        </div>
        <div class="modal-content has-background-white p-5" id="body-acomodacao">
            <section>
                <form @submit.prevent="salvarAlteracoes" id="form-editar-acomodacao">

                    <div class="field is-grouped has-text-left">
                        <div class="control is-expanded">
                            <label for="nome" class="label fonte-1">
                                Nome:
                            </label>
                            <input type="text" v-model="acomodacao_form.nome" @input="verificarAlteracoes"
                                class="input fonte-1" id="nome">
                        </div>


                        <div class="control is-expanded">
                            <label for="categoria" class="label fonte-1">
                                Tipo:
                            </label>
                            <div class="select is-fullwidth">
                                <select name="" class="is-medium" v-model="acomodacao_form.tipo_acomodacao"
                                    @change="verificarAlteracoes" id="categoria">
                                    <option :value="acomodacao.tipo_acomodacao" class="fonte-1"
                                        v-if="acomodacao.tipo_acomodacao.arquivado">

                                        {{ acomodacao.tipo_acomodacao.descricao }} (Arquivado)
                                    </option>
                                    <option class="fonte-1" :value="categoria" v-bind:key="categoria.id"
                                        v-for="categoria in categorias">
                                        {{ categoria.descricao }}
                                    </option>
                                </select>
                            </div>

                        </div>

                    </div>
                    <hr>
                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <button class="button is-fullwidth" @click="modal_ativo = false">Cancelar</button>
                        </div>

                        <div class="control is-expanded">

                            <button class="button is-fullwidth is-info" form="form-editar-acomodacao"
                                :disabled="!alteracao_feita">
                                <span class="icon-text">
                                    <span class="icon">
                                        <i class="icon mdi mdi-content-save" />
                                    </span>
                                    <span>
                                        Salvar
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>




            </section>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'


export default {
    name: 'Modal Editar Acomodacao',
    data() {
        return {
            modal_ativo: false,
            acomodacao: null,
            acomodacao_form: null,
            categorias: null,
            categoria_form: null,
            alteracao_feita: false,

        };
    },
    emits: ['acomodacaoAtualizada'],
    methods: {
        ativarModal(acomodacao) {
            this.modal_ativo = true;
            this.acomodacao = acomodacao;
            this.categoria_form = this.acomodacao.tipo_acomodacao;
            this.acomodacao_form = Object.assign({}, this.acomodacao)
            this.alteracao_feita = false
            this.get_tipos_acomodacoes()

        },

        get_tipos_acomodacoes() {
            axios
                .get('/api/v1/tiposAcomodacoes/')
                .then(response => {
                    this.categorias = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        verificarAlteracoes() {

            if (JSON.stringify(this.acomodacao) !== JSON.stringify(this.acomodacao_form)) {
                //console.log('aqui')
                this.alteracao_feita = true
            } else {
                this.alteracao_feita = false
            }

        },

        async salvarAlteracoes() {

            this.acomodacao.nome = this.acomodacao_form.nome
            this.acomodacao.tipo_acomodacao = this.acomodacao_form.tipo_acomodacao

            await axios.put(`api/v1/acomodacoes/${this.acomodacao.id}/`, this.acomodacao)
                .then(response => {

                    const msg = `<i class='mdi mdi-check-circle mr-2'><span>  A acomodacão <strong>${this.acomodacao.nome}</strong> foi atualizada com sucesso!</span>`

                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                    this.$emit('acomodacaoAtualizada')
                    this.modal_ativo = false
                })

                .catch(error => {
                    console.log(error)

                })
        }

    },
}

</script>

<style>
@media only screen and (max-width: 1024px) {

    #header-acomodacao,
    #body-acomodacao {
        width: 97% !important;

    }
}

#header-acomodacao,
#body-acomodacao {
    width: 450px;
}
</style>