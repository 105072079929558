<template>
    <div class="dropdown is-hoverable" :class="{ 'is-right': isRight }">
        <div class="dropdown-trigger">
            <a aria-haspopup="true" aria-controls="dropdown-menu" id="calendar-trigger"
                @click="dropdownAtivo = !dropdownAtivo">

                <i class="mdi mdi-calendar-month icon "></i>
                <span class="is-hidden-touch" v-if="label">{{ label }}</span>


            </a>
        </div>

        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content" id="picker-content">
                <div class="columns is-multiline is-mobile is-vcentered mt-2 mb-2 mx-5">
                    <div class="column is-5 pt-0 px-1">
                        <hr class="my-1 mx-0">
                    </div>
                    <div class="column is-2 pt-0 px-0">
                        <p class="fonte-1 has-text-weight-bold has-text-centered">Ano</p>
                    </div>
                    <div class="column is-5 pt-0 px-1">
                        <hr class="my-1 mx-0">
                    </div>
                    <div class="column is-3 is-narrow pb-1 px-1" v-bind:key="ano" v-for="ano in yearArray">
                        <div class="box-picker is-clickable p-2" :class="[year == ano ? 'selecionado' : '']"
                            @click="selecionarAno(ano)">
                            <p class="is-size-7 has-text-centered ">{{ ano }}</p>
                        </div>

                    </div>
                    <div class="column is-5 px-1">
                        <hr class="my-1 mx-0">
                    </div>
                    <div class="column is-2 px-0">
                        <p class="fonte-1 has-text-weight-bold has-text-centered">Mês</p>
                    </div>
                    <div class="column is-5 px-1">
                        <hr class="my-1 mx-0">
                    </div>

                    <div class="column is-4 px-1 py-1 is-narrow" v-for="mes, index in meses " v-bind:key="index">

                        <div class="box-picker py-2 is-clickable" :class="[month == index ? 'selecionado' : '']"
                            @click="selecionarMes(index)">
                            <p class=" is-size-7 has-text-centered">{{ mes.slice(0, 3) }}</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Month and Year picker',
    props: {
        modelValue: {
            type: Object
        },
        label: {
            type: String,
            default: null
        },
        yearArray: {
            type: Array,
            default: []
        },
        isRight: {
            type: Boolean,
            default: true
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {

            data_hoje: new Date(),
            meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

            year: '',
            month: null,
            dropdownAtivo: false,

        }
    },
    mounted() {
        this.year = this.data_hoje.getFullYear()
        // this.month = this.data_hoje.getMonth()
    },
    methods: {

        selecionarAno(ano) {
            if (ano !== this.year) {
                this.year = ano
                const data = {
                    ano: this.year,
                    mes: this.month
                }
                this.dropdownAtivo = false
                this.$emit('update:modelValue', data)
            }
        },

        selecionarMes(mes) {

            if (mes !== this.month) {

                this.month = mes
                const data = {
                    ano: this.year,
                    mes: this.month
                }
                this.dropdownAtivo = false
                this.$emit('update:modelValue', data)
            }

        }
    }
}

</script>

<style scoped lang="scss">
.columns {
    width: auto;
}

#picker-content {
    top: 15px;
    width: 20rem;
    position: relative;
    border-radius: 5px;
    border: 1px $primary-color solid;
}


/* Customização dos itens de mês e ano*/
.box-picker {
    box-shadow: 1px 1px 2px gray;
    border-radius: 3px;
    background-color: rgba(234, 234, 234, 0.4);
}

.box-picker p {
    font-family: 'Poppins';
    font-weight: bold;
    letter-spacing: 1.5px;
    color: $primary-color;
}

.box-picker:hover {
    background-color: rgb(179, 176, 177);
}

.box-picker.selecionado {
    background-color: $primary-color;
}

.box-picker.selecionado p {
    color: $secondary-color;
}

/*Customização do botão que aciona o dropdown*/
#calendar-trigger {
    color: $secondary-color !important;
    letter-spacing: 1.2px;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px $primary-color;
    background-color: $primary-color;
}

#calendar-trigger span {
    font-family: 'Poppins';


}

#calendar-trigger {
    border: $primary-color 1px solid;
}
</style>