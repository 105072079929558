<template>
    <footer class="footer py-3">
        <p class="fonte-1 has-text-centered">
            <strong>ATENA HUB</strong> by Agência Md1 - Versão: 1.0.0
        </p>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
footer {
    color: antiquewhite;
}
</style>