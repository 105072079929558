<template>
    <canvas height="300" width="250" ref="mychart3" id="myChart3"></canvas>
</template>


<script>
import { Chart } from 'chart.js/auto'
import axios from 'axios'
import { mdiBlackMesa } from '@mdi/js'

export default {
    data() {
        return {
            dados: [],
            colunas: [],
            hoje: new Date(),
            chart: {},
            meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

        }
    },

    name: 'GraficoOcupacaoPorDia',
    mounted() {


        const ctx = this.$refs.mychart3

        var sum = 0
        var cont = 0
        for (var i = 0; i < this.dados.length; i++) {
            sum += this.dados[i]
            cont++
        }
        const backgroundColors = []
        const borderColors = []
        const lineData = []
        const lineData2 = []
        const media = sum / cont

        for (i = 0; i < this.dados.length; i++) {
            if (this.dados[i] >= media) {
                backgroundColors.push('rgba(75, 192, 192, 0.5)')
                borderColors.push('rgba(75, 192, 192)')

            } else {
                backgroundColors.push('rgba(255, 99, 132, 0.5)')
                borderColors.push('rgba(255, 99, 132)')
            }
            lineData.push(media)
            lineData2.push(10)
        }

        let data_hoje = this.hoje

        this.mychart2 = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.colunas,
                datasets: [{

                    label: 'Consultas',
                    data: this.dados,
                    borderWidth: 2,
                    pointBorderWidth: 1,

                    pointBackgroundColor: function (context) {
                        return context.parsed?.y >= 50 ? 'rgba(75, 192, 192)' : 'rgba(255, 99, 132)'

                    },
                    pointBorderColor: function (context) {
                        return context.parsed?.y >= 50 ? 'rgba(75, 192, 192)' : 'rgba(255, 99, 132)'

                    },
                    segment: {
                        borderColor: function (context) {
                            return context.p1.parsed.y >= 50 ? 'rgba(75, 192, 192)' : 'rgba(255, 99, 132)'
                        },

                        backgroundColor: function (context) {
                            return context.p1.parsed.y >= 50 ? 'rgba(75, 192, 192,0.2)' : 'rgba(255, 99, 132,0.2)'
                        },

                    },
                    fill: true,

                    datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }

                },]
            },

            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {

                            font: {
                                size: 12
                            },
                            stepSize: 1
                        },
                    },

                    y: {
                        suggestedMax: 100,


                        ticks: {
                            callback:
                                function (value, index, ticks) {
                                    return value + '%';

                                },
                            font: {
                                size: 8
                            },
                            stepSize: 25
                        },
                        title: {
                            text: "Ocupação",
                            display: true,
                            font: {
                                family: 'Poppins',
                                size: 15,
                                weight: 'bold'

                            },
                            padding: { top: 0, left: 0, right: 0, bottom: 10 }
                        },
                    }

                },

                plugins: {
                    legend: {
                        display: false,
                        align: 'start',
                        position: 'bottom'
                    },

                    title: {
                        text: 'Taxa de ocupação por dia',
                        display: false,
                        font: {
                            family: 'Poppins',
                            size: 14,
                            weight: 'bold'

                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 10 },
                        align: 'center',
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: function (context) {
                                //console.log()
                                data_hoje.setDate(context[0].label)
                                return `${data_hoje.getDate()} de ${data_hoje.toLocaleDateString('pt-br', { month: 'long' })} \n${data_hoje.toLocaleDateString('pt-br', { weekday: 'long' })}`
                            },


                            label: function (context) {
                                return "Ocupação " + context.dataset.data[context.dataIndex] + '%'
                            },

                        }
                    },
                    datalabels: {

                        display: function (context) {
                            return context.dataset.data[context.dataIndex] > 0
                        },

                        color: 'black',

                        font: {
                            weight: 'bold',
                            size: 10
                        },
                        formatter: function (value, context) {
                            return Math.round(value) + "%"
                        },

                        rotation: -45,



                    },
                },

            },

        });

    },
    methods: {
        atualizarLabels(labels) {

            this.mychart2.data.labels = labels

            this.mychart2.update();
        },
        atualizarData(data, mes, ano) {
            var sum = 0
            var cont = 0
            for (var i = 0; i < data.length; i++) {
                sum += data[i]
                cont++
            }
            const backgroundColors = []
            const borderColors = []
            const lineData = []

            const media = sum / cont

            for (i = 0; i < data.length; i++) {
                if (data[i] >= media) {
                    backgroundColors.push('rgba(75, 192, 192, 0.9)')
                    borderColors.push('rgba(75, 192, 192)')

                } else {
                    backgroundColors.push('rgba(255, 99, 132, 0.9)')
                    borderColors.push('rgba(255, 99, 132)')
                }
                lineData.push(media)
            }


            this.mychart2.data.datasets[0].data = data
            // this.mychart2.options.plugins.title.text = 'Taxa de Ocupação por dia em ' + this.meses[mes - 1] + ' de ' + ano

            this.mychart2.data.labels = Array.from({ length: data.length }, (_, index) => index + 1)
            this.mychart2.data.datasets[0].backgroundColor = backgroundColors
            this.mychart2.data.datasets[0].borderColor = borderColors

            // this.mychart2.data.datasets[1].data = lineData

            this.mychart2.update();
        },


        async getOcupacaoPorDia(mes, ano) {
            //console.log(mes + ' ' + ano)
            await axios.post('api/v1/reservas/get_ocupacao_por_dia_do_mes/', { mes: mes, ano: ano })
                .then(response => {
                    // //console.log(response.data)
                    //console.log(this.hoje.toLocaleDateString('en-ca'))
                    this.atualizarData(response.data, mes, ano)

                })

                .catch(error => {
                    console.log(error)
                })
        }
    }
}


</script>

<style></style>