<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head" style="background-color: rgb(173, 78, 78);">
                <p class="modal-card-title titulo fonte-2">Cancelar Reserva {{ reserva?.id }}</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>

            </header>
        </div>
        <div class="modal-content has-background-white py-5 px-5">

            <form @submit.prevent="cancelarReserva" id="form-cancelamento">

                <div class="field ">

                    <label class="label has-text-left fonte-1">Motivo do cancelamento:</label>
                    <div class="control">
                        <textarea class="textarea has-fixed-size" v-model="descricao" required
                            placeholder="Máx. 255 caracteres" maxlength="255"></textarea>
                    </div>

                </div>
            </form>
            <hr>
            <div class="field is-grouped">
                <div class="control is-expanded has-text-left">
                    <button class="button is-fullwidth" @click="modal_ativo = false">
                        <span class="">
                            Cancelar
                        </span>
                    </button>
                </div>
                
                <div class="control is-expanded">
                    <button class="button is-success is-fullwidth " form="form-cancelamento">
                        <span class="">
                            Confirmar
                        </span>
                    </button>

                </div>
      

            </div>



        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'ModalCancelarReserva',
    data() {

        return {

            reserva: {},
            modal_ativo: false,
            descricao: '',

        }

    },

    emits: ['reservaCancelada'],

    methods: {
        ativarModal(reserva) {

            this.reserva = reserva

            this.modal_ativo = true

            this.descricao = ''

        },

        cancelarReserva() {

            this.reserva.status = 'Cancelada'

            axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {

                    axios.post('api/v1/historicos/adicionar-historico/', { id_reserva: this.reserva.id, descricao: 'Reserva Cancelada', informacao_adicional: this.descricao, categoria: "Cancelamento" })
                        .then(response => {

                            ////console.log(response)

                            const msg = `<i class='mdi mdi-check-circle mr-2'><span> A reserva foi cancelada.</span>`

                            toast({
                                message: msg,
                                type: 'is-warning is-light',
                                position: 'top-right',
                                duration: 6000,
                                dismissible: true,
                                pauseOnHover: true,
                            })

                            this.$emit('reservaCancelada')
                            this.modal_ativo = false;

                        })
                        .catch(error => {
                            console.log(error)
                        })

                })
                .catch(error => {

                    console.log(error)

                })
        },

        async confirmarReserva() {

            this.reserva.status = "Confirmada"

            if (this.sinal_pago === '1') {
                this.reserva.valor_pago = this.valor_sinal
            }

            await axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {

                    ////console.log(response)

                    axios.post('api/v1/reservas/confirmar_reserva/', { id_reserva: this.reserva.id, descricao: this.descricao })
                        .then(response => {

                            ////console.log(response)

                        })
                        .catch(error => {
                            console.log(error)
                        })


                })
                .catch(error => {

                    console.log(error)

                })

            this.modal_ativo = false
            this.$router.go()
        },
    }
}

</script>

<style lang="scss" scoped>

@media only screen and (max-width: 1024px) {

    .modal-content,
    .modal-card{
        width: 90% !important;

    }
}


#conteudo-confirmacao {
    height: auto;
}

#fonte-1 {
    width: fit-content;
}
</style>