<template>
    <div class="columns m-0 is-multiline is-mobile">
        <div class="column is-12" v-if="this.acomodacoes.length == 0">
            <div class="notification is-light  has-text-left">
                <span class="is-size-5 fonte-1">
                    <i class="mdi mdi-information-outline icon" />
                    Nenhuma acomodação cadastrada. Por favor, configure sua hospedagem na página de
                    <router-link class=" has-text-weight-bold fonte-1" to="/gerenciamento">
                        <span>Gerenciamento</span>
                    </router-link>
                    para visualizar suas <strong>Acomodações.</strong>
                </span>
            </div>
        </div>

        <div class="column is-12-desktop" v-else>
            <div class="columns is-centered is-multiline is-mobile">
                <div class="column is-narrow  ">
                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-total">
                            Acomodações
                        </div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ total }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="column is-narrow ">
                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-ocupacao">Ocupação</div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ ocupacao }}%
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column is-narrow  ">

                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-livre">Livres</div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ livre }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="column is-narrow ">
                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-ocupados">Ocupado</div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ ocupados }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column is-narrow ">
                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-sujos">Sujo</div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ sujos }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="column is-narrow ">
                    <div class="tags has-addons">
                        <div class="tag has-text-light is-medium info-manutencao">Manutenção</div>
                        <div class="tag is-medium">
                            <p class="fonte-1 has-text-weight-bold">
                                <span>
                                    {{ manutencao }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="my-1 mx-2">
        </div>

        <div class="column is-12 pt-0 acomods-container">
            <div class="acomods has-y-scroll">
                <div class="columns is-multiline is-mobile is-vcentered">

                    <nav class="column  is-2-widescreen acomod"
                        @mouseover="selecionarAcomodacao(acomodacao)"
                        v-for="acomodacao in this.acomodacoes" v-bind:key="acomodacao.id">

                        <nav class="panel p-0">

                            <p class="panel-heading has-text-centerered nome-quarto py-2"
                                :class="`is-${acomodacao.status}`" :style="{ backgroundColor: setColor(acomodacao) }">

                            <form @submit.prevent="confirmarEdicaoNome(acomodacao)">
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input type="text" class="input input-nome has-text-centered "
                                            :readonly="this.acomodacoesEditControl[`${acomodacao.id}` + ''].status === false"
                                            v-model="acomodacoesEditControl[`${acomodacao.id + ''}`]['novo']"
                                            :id="`input-nome-${acomodacao.id}`"
                                            :style="{ backgroundColor: setColor(acomodacao) }" readonly
                                            autocomplete="off" @blur="cancelarEdicaoNome(acomodacao.id)"
                                            @click="ativarEdicaoNome(acomodacao.id)">

                                        <span class="icon is-right ">
                                            <i class="mdi  mdi-content-save has-text-white button-confirm"
                                                :id="`button-confirm-${acomodacao.id}`"
                                                v-show="this.acomodacoesEditControl[`${acomodacao.id}` + ''].status === true"
                                                @mousedown="confirmarEdicaoNome(acomodacao)"
                                                :class="[this.acomodacoesEditControl[`${acomodacao.id}` + ''].antigo === this.acomodacoesEditControl[`${acomodacao.id}` + ''].novo ? 'is-desactive' : 'is-clickable']"></i>
                                        </span>

                                    </div>
                                </div>
                            </form>
                            </p>

                            <div class="panel-block" :class="{ 'inativo': !acomodacao.ocupado }">
                                <p class="panel-txt nome-hospede has-text-left">
                                    <span class="icon mr-1">
                                        <i class="mdi mdi-account" />
                                    </span>
                                    <span class="" v-if="acomodacao.ocupado">
                                        {{ reservaPorAcomodacao(acomodacao.id)?.hospede.nome }}
                                    </span>
                                    <span class="" v-else>
                                        Sem Hóspede
                                    </span>
                                </p>
                            </div>
                            <div class="panel-block" :class="{ 'inativo': !acomodacao.ocupado }">
                                <p class="panel-txt icon-text">
                                    <span class="icon has-text-success mr-1">
                                        <i class="mdi mdi-calendar-import" />
                                    </span>
                                    <span v-if="acomodacao.ocupado">
                                        {{ formatarData(reservaPorAcomodacao(acomodacao.id)?.data_entrada) }}
                                    </span>
                                    <span class="ml-1" v-else>
                                        --/--/----
                                    </span>
                                </p>
                            </div>
                            <div class="panel-block" :class="{ 'inativo': !acomodacao.ocupado }">
                                <p class="panel-txt icon-text">
                                    <span class=" icon has-text-danger mr-1">
                                        <i class="mdi mdi-calendar-export" />
                                    </span>
                                    <span v-if="acomodacao.ocupado">
                                        {{ formatarData(reservaPorAcomodacao(acomodacao.id)?.data_saida) }}
                                    </span>
                                    <span class="ml-1" v-else>
                                        --/--/----
                                    </span>
                                </p>
                            </div>

                            <div class="panel-block" id="panel-actions">
                                <button class="button is-info  is-small mr-0 is-fullwidth has-tooltip-bottom "
                                    v-if="acomodacao.ocupado" @click="consultarReserva(acomodacao)">
                                    <!-- <SvgIcon type="mdi" class="icon" :path="icons.book" /> -->
                                    <i class="icon mdi mdi-book-account"></i>
                                    <span>Consultar Reserva</span>
                                </button>

                                <button class="button info-manutencao has-text-white is-small mr-0 is-fullwidth"
                                    v-else-if="acomodacao.status === 'Manutenção'"
                                    @click="consultarManutencao(acomodacao)">

                                    <i class="icon mdi mdi-wrench"></i>
                                    <span>Consultar Manutenção</span>
                                </button>

                                <button class="button info-sujos is-small mr-0 is-fullwidth"
                                    v-else-if="acomodacao.status === 'Sujo'" @click="limpeza_ativo = !limpeza_ativo">
                                    <i class="icon mdi mdi-broom"></i>
                                    <span>Realizar Limpeza</span>
                                </button>
                                <button class="button acao is-dark is-static is-small is-fullwidth" v-else>
                                    <span>Acomodação Livre</span>
                                </button>

                            </div>



                        </nav>

                    </nav>

                </div>
            </div>
        </div>

    </div>

    <div class="modal is-active" v-if="confirmacao_ativo">
        <div class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body" id="modal_exclusao">

                <p class="modal-card-title  is-size-3 ">

                    Deseja mesmo apagar a acomodação?

                </p>
                <hr>
                <p class="subtitle has-text-left has-text-danger mx-4">
                    <span class="icon">
                        <i class="mdi mdi-alert">

                        </i>
                    </span> Atenção: Essa ação irá apagar todas as reservas vinculadas a essa acomodação.
                </p>

                <hr>
                <div class="buttons is-right">
                    <button class="button  has-text-weight-bold is-medium "
                        @click="confirmacao_ativo = !confirmacao_ativo">Cancelar</button>

                    <button class="button is-danger is-medium  "
                        @click="excluirAcomodacao(acomodacao_selecionada.id)">Apagar</button>
                </div>
            </section>
        </div>
    </div>

    <div class="modal is-active" v-if="limpeza_ativo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body" id="modal_limpeza">
                <p class="modal-card-title has-text-left fonte-1  is-size-4">
                    Confirmar limpeza da acomodação?
                </p>
                <hr>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control is-expanded">
                        <button class="button has-text-weight-bold is-fullwidth is-responsive"
                            @click="limpeza_ativo = !limpeza_ativo">Cancelar</button>
                    </div>
                    <div class="control is-expanded">
                        <button class="button is-success is-fullwidth is-responsive"
                            @click="limparAcomodacao(acomodacao_selecionada.id)">Confirmar</button>
                    </div>
                </div>
            </section>
        </div>
    </div>



    <ModalConsultarReserva ref="modalConsultar" @reserva-atualizada="this.get_acomodacoes" />
    <ModalConsultarManutencao ref="modalManutencao" @manutencao-concluida="get_acomodacoes" />
</template>

<script>

import Navbar from '@/components/layout/Navbar.vue'
import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiBed, mdiBookAccount, mdiBroom, mdiDotsVerticalCircleOutline, mdiEngine, mdiPercent, mdiWrench } from '@mdi/js';
import { toast } from 'bulma-toast'
import ModalConsultarReserva from '@/components/Modals/ModalConsultarReserva.vue';
import ModalManutencao from '@/components/Modals/ModalManutencao.vue';
import ModalConsultarManutencao from '@/components/Modals/ModalConsultarManutencao.vue';


export default {
    name: "Acomodações",

    data() {
        return {
            acomodacoes: [],
            reservasOcupadas: [],
            acomodacao_selecionada: '',
            confirmacao_ativo: false,
            limpeza_ativo: false,
            configuracao_ativo: false,
            acomodacoesEditControl: {},
            acomodacaoEditID: '',
            icons: {
                bed: mdiBed,
                wrench: mdiWrench,
                percent: mdiPercent,
                book: mdiBookAccount,
                broom: mdiBroom,
                dots: mdiDotsVerticalCircleOutline,

            },
            config: false,
            livre: 0,
            ocupados: 0,
            sujos: 0,
            manutencao: 0,
            total: 0,
            nomeNovo: '',
        };
    },

    computed: {
        ocupacao() {
            const ocupacao = Math.round((this.ocupados + this.manutencao) / this.total * 100)
            //console.log(isNaN(ocupacao))
            return isNaN(ocupacao) ? 0 : ocupacao
        }
    },

    mounted() {
        this.get_acomodacoes();
        this.getReservasAtuais();

    },

    methods: {

        async getReservasAtuais() {

            axios.get('/api/v1/reservas/', { params: { status: 'Ocupada' } })
                .then(response => {

                    this.reservasOcupadas = response.data;

                })
        },

        reservaPorAcomodacao(id) {

            return this.reservasOcupadas.filter(reserva => { return reserva.acomodacao.id === id })[0]

        },

        consultarReserva(acomodacao) {

            const filtro = {
                acomodacao: acomodacao.id,
                status: 'Ocupada'
            }

            axios
                .get('/api/v1/reservas/', { params: filtro })
                .then(response => {
                    const reserva = response.data[0]
                    this.$refs.modalConsultar.ativarModal(reserva)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        consultarManutencao(acomodacao) {

            const filtro = {
                acomodacao: acomodacao.id,
                status: 'Bloqueada'
            }

            axios
                .get('/api/v1/reservas/', { params: filtro })
                .then(response => {
                    const reserva = response.data[response.data.length - 1]
                    this.$refs.modalManutencao.ativarModal(reserva)
                })
                .catch(error => {
                    console.log(error)
                })
        },


        async get_acomodacoes() {

            this.ocupados = 0
            this.manutencao = 0
            this.livre = 0
            this.total = 0

            await axios.get('/api/v1/acomodacoes')
                .then(response => {
                    this.acomodacoes = response.data;

                    this.acomodacoes.forEach(acomodacao => {

                        this.acomodacoesEditControl[acomodacao.id] = {}
                        this.acomodacoesEditControl[acomodacao.id]['status'] = false
                        this.acomodacoesEditControl[acomodacao.id]['antigo'] = acomodacao.nome
                        this.acomodacoesEditControl[acomodacao.id]['novo'] = acomodacao.nome

                    })

                })
                .catch(error => {
                    console.log(error);
                });


            Array.from(this.acomodacoes).forEach(uh => {

                if (uh.ocupado) {
                    this.ocupados++
                } else if (uh.status === "Manutenção") {
                    this.manutencao++
                } else if (uh.status === "Sujo") {
                    this.sujos++
                } else {
                    this.livre++
                }

                this.total++

            })

        },

        get_tipos_acomodacoes() {

            axios
                .get('/api/v1/tiposAcomodacoes/',)
                .then(response => {
                    this.categorias = response.data

                })
                .catch(error => {
                    console.log(error)

                })

        },


        setColor(uh) {

            if (uh.ocupado) {
                return "rgb(78, 105, 173)";
            } else if (uh.status === "Manutenção") {
                return "rgb(173, 78, 78)";
            } else if (uh.status === "Sujo") {
                return "hsl(269, 55%, 32%)"
            }
            else {
                return "hsl(0, 0%, 44%)"
            }
        },

        selecionarAcomodacao(acomodacao) {

            this.acomodacao_selecionada = acomodacao


        },
        limparAcomodacao() {

            this.acomodacao_selecionada.status = "Limpo"
            axios.patch(`api/v1/acomodacoes/${this.acomodacao_selecionada.id}/`, this.acomodacao_selecionada)
                .then(response => {

                    toast({
                        message: '<span class="mdi mdi-information-outline"> </span><strong>Atualização:</strong> Limpeza confirmada.',
                        type: 'is-info is-light',
                        position: 'top-right',
                        dismissible: true,
                        pauseOnHover: true,
                    })


                    this.get_acomodacoes()
                    this.limpeza_ativo = false


                })
                .catch(error => {
                    console.log(error)
                })
        },



        excluirAcomodacao(id) {

            axios.delete(`api/v1/acomodacoes/${id}`)
                .then(response => {

                    toast({
                        message: '<span class="mdi mdi-trash-can"></span> Acomodação excluída!',

                        type: 'is-success-outline',
                        position: 'top-right',
                        dismissible: false,
                        pauseOnHover: true,
                    })


                    this.get_acomodacoes()
                    this.confirmacao_ativo = false


                })
                .catch(error => {
                    console.log(error)
                })
        },
        ativarEdicaoNome(id) {

            this.acomodacoesEditControl[id + ''].status = true
            this.acomodacaoEditID = id

            var input = document.getElementById('input-nome-' + id)

            input.focus()

        },

        confirmarEdicaoNome(acomodacao) {
            this.acomodacoesEditControl[acomodacao.id + ''].antigo = this.acomodacoesEditControl[acomodacao.id + ''].novo
            this.acomodacoesEditControl[acomodacao.id + ''].status = false
            acomodacao.nome = this.acomodacoesEditControl[acomodacao.id + ''].novo

            axios.patch(`api/v1/acomodacoes/${acomodacao.id}/`, acomodacao)
                .then(response => {

                    const msg = `<i class="mdi mdi-content-save pr-3"><span> Alteração salva</span>`

                    toast({
                        message: msg,
                        type: 'is-info is-light',
                        position: 'top-right',
                        duration: 6000,
                        dismissible: true,
                        pauseOnHover: true,
                    })



                })
                .catch(error => {
                    console.log(error)
                })

        },

        formatarData(data) {
            return new Date(data + "T00:00:00").toLocaleDateString('pt-BR')
        },

        cancelarEdicaoNome(id) {
            if (document.getElementById('button-confirm-' + id) !== document.activeElement) {
                this.acomodacoesEditControl[id + ''].status = false
                this.acomodacoesEditControl[id + ''].novo = this.acomodacoesEditControl[id + ''].antigo
            }
        }

    },
    components: { Navbar, SvgIcon, ModalConsultarReserva, ModalManutencao, ModalConsultarManutencao }
}

</script>

<style lang="scss" scoped>
.acomods-container {
    position: relative;

}

@media only screen and (max-width: 1100px) {
    .tags .tag:first-child {
        width: 15rem !important;
    }

    .tags .tag:last-child {
        width: none !important;
    }

}

.columns {
    width: 100%;
}

.acomods {
    position: relative;
    padding: 5px;
    height: 65vh;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
}

/*
.acomods::-webkit-scrollbar {

    z-index: 9999;
    width: 10px;
    height: 10px;
    border-radius: 0 20px 20px 20px;
    background: $primary-color;
    visibility: hidden;
}

.acomods::-webkit-scrollbar-track {
    background: $primary-color;
    border-radius: 10px;
    display: block;
}

.acomods::-webkit-scrollbar-thumb {
    border-radius: 20px !important;
    background: rgb(120, 93, 104);
}

.acomods::-webkit-scrollbar-corner {
    border-radius: 0 0 20px;
}

.acomods::-webkit-scrollbar-track-piece {
    background-color: $primary-color;
    border-radius: 20px;
    margin: 20px;
}
*/

.box-info {
    border-radius: 5px;
    width: 13rem;

}



.tags .tag:first-child {
    width: 10rem;
}

.tags .tag:last-child {
    width: 3rem;
    background-color: white;
    border-width: 1px 1px 1px 0px;
    border-style: solid;


}


.info-total {
    background-color: rgb(52, 52, 52);

}

.info-ocupados {
    //background-color: rgb(165, 42, 42);
    background-color: $ocupada-color;


}

.info-sujos {
    background-color: hsl(269, 55%, 32%);
    color: white;
    //background-color: hsl(45, 80%, 54%);
}

.info-livre {

    //background-color: rgb(79, 178, 60);
    background-color: $fechada-color;

}

.info-ocupacao {
    background-color: rgb(42, 42, 42);

}

.info-manutencao {
    //background-color: rgb(230, 161, 34);
    background-color: $bloqueada-color;

}

.nome-hospede {
    font-family: 'Poppins';
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

#mensagem {
    position: absolute;
    top: 40%;
}

.nome-quarto {
    font-family: 'Poppins';
    color: white;
    font-weight: normal;

}

[readonly].input-nome.input {
    outline: none;
    border: none;
    cursor: pointer;
    box-shadow: none;
    background-color: transparent !important;

}

.input-nome {
    color: white;
    outline: white 1px solid;
    border: none;
    box-shadow: none;
    font-family: 'Poppins';
    font-weight: bold;
    padding-left: 30px;
    padding-bottom: 5px;
    border-radius: 9px;
    letter-spacing: 2px;

}

.input-nome:focus {
    color: white;
    border-bottom: none;
    outline: white 1px solid;
}

.control.has-icons-right .input:focus~.icon {
    color: white;
}

.button-edit,
.button-confirm {
    border-radius: 9px !important;
    z-index: 99;
}

.button-edit {
    opacity: 0%;
}

.button-edit:hover {
    opacity: 100%;
}

.button-confirm.is-desactive {
    opacity: 50%;
}

.acao {
    border: 0px;
}

.acao:hover {
    border: 0px;
}

.panel-txt {
    font-family: 'Poppins';
    color: black;
    background-color: white;
}

#panel-categoria,
#panel-actions {
    width: 100%;
    display: block;
}

.removido {
    color: grey;
}

.panel-block {
    background-color: white;
}

.btns {
    border-radius: 10px !important;
    width: 180px;
    font-family: 'Poppins';
    font-weight: bold;
    border-radius: 10px !important;
    border: $primary-color 1px solid;
    color: $primary-color;
    z-index: 25;

}

.btns:hover i {
    transform: rotate(360deg) !important;
}

.btns:hover,
.btns:focus {
    outline: none;
    border: $primary-color 1px solid;
    box-shadow: $primary-color 2px 2px 3px !important;
}


#plus-icon {
    z-index: 10;
    transition: 1.2s;
}


.btns-inverse {
    border-radius: 30px;
    font-family: 'Poppins';
    background-color: $primary-color;
    border: $primary-color 2px solid;
    color: $secondary-color;
    z-index: 11;
}

.sujo {
    color: red;
}

.inativo {
    color: grey !important;
    opacity: 42%;
}

.espelhado {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.btns-inverse:hover {

    color: $secondary-color;

}

#modal_exclusao {
    border-radius: 5px;
    width: auto;
}

#modal_limpeza {
    border-radius: 5px;
    width: auto;
}

.btns-inverse:hover i {

    transform: rotate(360deg) !important;
}

.btns-inverse:focus {
    color: $secondary-color;
    border: $primary-color 2px solid;
}

#icon-cog {
    z-index: 10;
    transition: 1.2s;

}
</style>