<template>
    <input type='text' required placeholder="" v-maska="cpfMascara" @maska="validarCPF" data-maska="###.###.###-##"
        :value="modelValue" maxlength="14" v-bind="$attrs" class="input input-cpf fonte-1"
        @input="this.$emit('update:modelValue', $event.target.value)" :class="inputClass" id="input-cpf">

    <!-- <p class="help is-danger has-text-left pl-1" v-if="aviso_cpf">CPF inválido, informe novamente!</p> -->
</template>


<script setup>
defineProps({
    label: {
        type: [String, Boolean],
        default: false,
    },
    inputClass: {
        type: String,
        default: "",
    },
    cpfMascara: {
        type: Object,
        default: null,
    },
    modelValue: {
        type: String,
        default: "",
    },
});
</script>


<script>
import { vMaska } from "maska"

export default {

    name: "CPF Input",
    directives: { maska: vMaska },
    emits: ['cpfValido', 'cpfIncompleto', 'cpfInvalido', 'update:modelValue'],
    data() {

        return {
            aviso_cpf: false,
        }
    },

    methods: {

        validarCPF() {

            if (this.cpfMascara.completed == false) {

                this.$emit('cpfIncompleto')

            }

            else {

                var Soma = 0
                var Resto

                var strCPF = this.cpfMascara.unmasked

                if ([
                    '00000000000',
                    '11111111111',
                    '22222222222',
                    '33333333333',
                    '44444444444',
                    '55555555555',
                    '66666666666',
                    '77777777777',
                    '88888888888',
                    '99999999999',
                ].indexOf(strCPF) !== -1) {
                    this.$emit('cpfInvalido')
                    return
                }
                for (var i = 1; i <= 9; i++)
                    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

                Resto = (Soma * 10) % 11

                if ((Resto == 10) || (Resto == 11))
                    Resto = 0

                if (Resto != parseInt(strCPF.substring(9, 10))) {
                    this.$emit('cpfInvalido')
                    return
                }

                Soma = 0

                for (i = 1; i <= 10; i++)
                    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)

                Resto = (Soma * 10) % 11

                if ((Resto == 10) || (Resto == 11))
                    Resto = 0

                if (Resto != parseInt(strCPF.substring(10, 11))) {
                    this.$emit('cpfInvalido')
                }
                else {
                    this.$emit('cpfValido')
                }
            }
        },

    }

}

</script>

<style scoped lang="scss"></style>