import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import MapaReservas from '../views/MapaReservas.vue'
import Acomodacoes from '../views/Acomodacoes.vue'
import Gerenciamento from '../views/Gerenciamento.vue'
import Hospedes from '../views/Hospedes.vue'
import Produtos from '../views/Produtos.vue'
import LogIn from '../views/LogIn.vue'
import AlterarSenha from '../views/AlterarSenha.vue'
import EsqueciMinhaSenha from '../views/EsqueciMinhaSenha.vue'
import RedefinirSenha from '../views/RedefinirSenha.vue'
import AdicionarHospedagem from '../views/AdicionarHospedagem.vue'
import Reservas from '../views/Reservas.vue'
import Painel from '../views/Painel.vue'
import Insights from '../views/Insights.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/mapa',
    name: 'Mapa de Reservas',
    component: MapaReservas,
    meta: {
      requireLogin: true,
      title: "Mapa de Reservas"
    }
  },
  {
    path: '/acomodacoes',
    name: 'Acomodações',
    component: Acomodacoes,
    meta: {
      requireLogin: true,
      title: "Acomodações"
    }
  },
  {
    path: '/gerenciamento',
    name: 'Gerenciamento',
    component: Gerenciamento,
    meta: {
      requireLogin: true,
      title: "Gerenciamento"
    }
  },

  {
    path: '/hospedes',
    name: 'Hóspedes',
    component: Hospedes,
    meta: {
      requireLogin: true,
      title: "Hóspedes"
    }
  },
  {
    path: '/reservas',
    name: 'Reservas',
    component: Reservas,
    meta: {
      requireLogin: true,
      title: "Reservas"
    }
  },
  {
    path: '/alterar-senha',
    name: 'Alterar Senha',
    component: AlterarSenha,
    meta: {
      requireLogin: true,
      title: "Alterar Senha"
    }
  },
  {
    path: '/esqueci-minha-senha',
    name: 'Esqueci Minha Senha',
    component: EsqueciMinhaSenha,
    meta: {
      requireLogin: false,
      title: "Esqueci minha senha"
    }
  },
  {
    path: '/redefinir-senha/:token',
    name: 'Redefinir Senha',
    component: RedefinirSenha,
    meta: {
      requireLogin: false,
      title: "Redefinir Senha"
    }
  },
  {
    path: '/painel',
    name: 'Meu Painel',
    component: Painel,
    meta: {
      requireLogin: true,
      title: "Meu Painel"
    }
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
    meta: {
      title: 'Log-in'
    }
  },
  {
    path: '/adicionar-hospedagem',
    name: 'Adicionar Hospedagem',
    component: AdicionarHospedagem,
    meta: {
      title: 'Nova Hospedagem',
      requireLogin: true,
      staffAccess: true,
    }
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights,
    meta: {
      requireLogin: true,
      title: 'Insights'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title
  // console.log('senhaAlterada:', store.state.senhaAlterada)
  if (title) {
    document.title = title
  }

  store.commit('setIsSidebarMin', true)
  localStorage.setItem('isSidebarMin', true)

  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/log-in')

  }
  else if (to.name === 'home' && store.state.isAuthenticated) {
    next('/painel')
  }
  else if (to.name === 'Redefinir Senha' && store.state.isAuthenticated) {
    next('/painel')
  }
  else if (!store.state.senhaAlterada && to.name !== 'Alterar Senha' && store.state.isAuthenticated) {
    next('/alterar-senha')
  }

  else if (to.matched.some(record => record.meta.staffAccess) && store.state.isAuthenticated) {
    axios.get('/api/v1/usuarios/is-staff/').then(response => {

      if (response.data.is_staff) {
        next()
        store.state.isStaff = true
      } else {
        history.back()
      }

    })
  }
  else {
    next()
  }

})

export default router
