<template>
    <div class="modal is-active" v-if="modalAtivo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-consultar-historico">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2 has-text-left pl-1">
                    Histórico de {{ historico.categoria }}
                </p>
                <button class="delete is-large" aria-label="close" @click="modalAtivo = false"></button>
            </header>
        </div>
        <div class="modal-content has-background-white p-5" id="content-consultar-historico">


            <p class="fonte-1 has-text-left title is-size-6 pb-3">Descrição:</p>
            <p class="fonte-1 has-text-left subtitle is-size-6">
                {{ historico.descricao }}
            </p>

            <template v-if="historico.informacao_adicional">
                <p class="fonte-1 has-text-left title is-size-6 pt-5 pb-3">Informação Adicional:</p>
                <p class="fonte-1 has-text-left subtitle is-size-6">
                    {{ historico.informacao_adicional }}
                </p>
            </template>
            <hr>
            <p class="has-text-left fonte-1 is-size-6-desktop">Histórico criado no dia {{ dataCriacao }} às {{
        horaCriacao }}.</p>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'

export default {

    name: 'modal-consultar-historico',
    emits: ['historicoAdicionado'],
    data() {

        return {
            modalAtivo: false,
            historico: {
                id_reserva: null,
                categoria: 'Atualização',
                descricao: '',
            },
            reserva: null,
            isLoading: false,
        }

    },

    computed: {
        data() {
            return new Date(this.historico.criado_em)
        },

        dataCriacao() {
            return this.data.toLocaleDateString('pt-br')
        },

        horaCriacao() {
            return this.data.getHours() + ':' + this.data.getMinutes() + ':' + this.data.getSeconds()
        }
    },

    methods: {

        ativarModal(historico) {

            this.modalAtivo = true

            this.historico = historico

        },

    }

}


</script>

<style scoped>
@media only screen and (max-width: 1024px) {

    #header-consultar-historico,
    #content-consultar-historico {
        width: 90% !important;

    }


}
</style>