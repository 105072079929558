<template>
    <div class="columns is-centered is-multiline m-0 strech">
        <div class="column is-7 is-12-touch">
            <div class="box hosp-box has-y-scroll ">
                <h1 class="title fonte-1 has-text-left">Hóspedes</h1>
                <hr>
                <div class="columns is-multiline" id="hsp-table">
                    <div class="column is-6 is-narrow">
                        <form @submit.prevent="get_hospedes">
                            <label for="" class="label fonte-1 has-text-left">Buscar Hóspede:</label>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input type="text" v-model="filtro" class="input fonte-1 "
                                        placeholder="Nome, CPF ou e-mail" id="input-buscar">
                                </div>

                                <div class="control">
                                    <button class="button btn-search">
                                        <strong>
                                            <i class="mdi mdi-magnify"></i>
                                        </strong>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <template v-if="hospedes.length > 0">
                    
                    <div class="table-container">
                        <table class="table is-fullwidth  is-hoverable">
                            <thead class="thead_color">
                                <th class="">Nome</th>
                                <th class="has-text-left">CPF</th>
                                <th class="has-text-left">E-mail</th>
                            </thead>

                            <tbody>
                                <template v-for="hospede in hospedes" v-bind:key="hospede.id">
                                    <tr class="is-clickable fonte-1" @click="selecionarHospede($event, hospede)"
                                        :id="`tr-${hospede.id}`">

                                        <td class="has-text-left">
                                            {{ hospede.nome }}
                                        </td>
                                        <td class="has-text-left">
                                            {{ hospede.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4") }}
                                        </td>
                                        <td class="has-text-left">
                                            {{ hospede.email }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div v-show="hospede_selecionado === null">
                        <div class="notification is-light fonte-1 is-size-5">
                            <i class="mdi mdi-lightbulb-outline" />
                            <span>
                                Sem hóspedes registrados
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="column is-5 is-12-touch">
            <div class="box  hosp-box-2 ">
                <h1 class="title fonte-1 has-text-left">Detalhes do Hóspede</h1>
                <hr>
                <div v-if="this.hospede_selecionado !== null">
                    <div class="tabs is-toggle is-fullwidth mb-4">
                        <ul>
                            <li :class="[tab == 1 ? 'is-active' : '']" @click="tab = 1">
                                <a class="tab-item fonte-1">
                                    <span class="icon is-small">
                                        <i class="mdi mdi-information-outline"></i>
                                    </span>
                                    <span>
                                        Dados
                                    </span>
                                </a>
                            </li>
                            <li :class="[tab == 2 ? 'is-active' : '']" @click="tab = 2">
                                <a class="tab-item fonte-1">
                                    <span class="icon is-small">
                                        <i class="mdi mdi-book-account"></i>
                                    </span>
                                    <span>
                                        Histórico
                                    </span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div class="has-y-scroll" id="form-hospede" v-if="tab == 1">
                        <div class="column is-12 ">
                            <form @submit.prevent="atualizarHospede" id="form-editar-hospede">
                                <div class="columns is-multiline">
                                    <div class="field column is-6-widescreen is-12-tablet is-12-touch has-text-left">
                                        <label class="is-size-6 label fonte-1 has-text-left">Nome</label>
                                        <div class="control">
                                            <input class="input fonte-1 info-input has-text-left"
                                                @input="verificarAlteracoes" v-model="hospede_edit.nome">
                                        </div>
                                    </div>

                                    <div class="field column is-6-widescreen is-12-tablet is-12-touch has-text-left ">
                                        <div class="control">
                                            <label class="is-size-6 subtitle label fonte-1 has-text-left">CPF</label>
                                            <CPFInput ref="cpf" :cpf-mascara="cpfMascara" v-model="hospede_edit.cpf"
                                                input-class="fonte-1 info-input  mb-0"
                                                :class="{ 'input-invalido': this.cpfInvalido }"
                                                @update:model-value="verificarAlteracoes"
                                                @cpf-invalido="this.cpfInvalido = true"
                                                @cpf-valido="this.cpfInvalido = false" />
                                            <p class="help is-size-7 mt-2 is-danger has-text-left" v-if="cpfInvalido">
                                                O CPF informado é inválido!
                                            </p>
                                        </div>

                                    </div>
                                
                                    <div class="field column is-6-widescreen is-12-tablet is-12-touch has-text-left has-text-left">
                                        <div class="control is-expanded">
                                            <label class="is-size-6 subtitle label fonte-1 has-text-left">Telefone</label>
                                            <input @input="verificarAlteracoes" v-maska="telefoneMascara"
                                                v-model="hospede_edit.telefone"
                                                data-maska="['(##) #####-####','(##) ####-####']"
                                                class=" input fonte-1 info-input "
                                                :class="{ 'input-invalido': telefoneInvalido }" id="telefone" type="text"
                                                required>

                                            <p class="help is-size-7 mt-2 is-danger has-text-left" v-if="telefoneInvalido">
                                                O Telefone informado é inválido!
                                            </p>
                                        </div>
                                    </div>


                                    <div class="field column is-6-widescreen is-12-tablet is-12-touch has-text-left has-text-left">
                                        <div class="control is-expanded">
                                            <label class=" subtitle label fonte-1 has-text-left">E-mail</label>
                                            <input v-model="hospede_edit.email" placeholder="Não informado"
                                                class=" input fonte-1 info-input " @input="verificarAlteracoes" required />
                                        </div>
                                    </div>
                                    <div class="field column is-4-widescreen is-12-desktop has-text-left has-text-left">
                                        <div class="control is-expanded">
                                            <label class=" subtitle label fonte-1 has-text-left">Data de
                                                Nascimento</label>
                                            <input type="date" id="data-nascimento" v-model="hospede_edit.data_nascimento"
                                                class=" input fonte-1 info-input " @change="verificarAlteracoes" required />
                                        </div>
                                    </div>

                                    <div class="field column is-4-widescreen is-12-desktop is-offset-4-widescreen has-text-left has-text-left">
                                        <label class="is-size-6 subtitle label fonte-1 has-text-left">Estado</label>
                                        <div class="control">
                                            <div class="select is-fullwidth">
                                                <select id="select-estados" class=" info-input fonte-1"
                                                    v-model="hospede_edit.estado" :disabled="!this.editar_info"
                                                    @change="verificarAlteracoes">

                                                    <option :value="estado" v-for=" estado  in  estados ">
                                                        {{estado}}
                                                    </option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <hr class="my-1">
                                <div class="field mt-5 is-grouped is-grouped-right">


                                    <div class="control">
                                        <button class="button is-normal is-responsive" @click="desfazerAlteracoes"
                                            type="button" :disabled="!alteracaoFeita">

                                            <span class="fonte-1">Cancelar</span>
                                        </button>
                                    </div>
                                    <div class="control">
                                        <button type="submit" class="button is-normal  is-responsive is-info"
                                            form="form-editar-hospede" :disabled="!alteracaoFeita">
                                            <i class="icon mdi mdi-content-save"></i>
                                            <span>Salvar</span>
                                        </button>
                                    </div>
                                </div>


                                <p class="has-text-left is-size-7 has-text-weight-bold has-text-danger" v-if="telefoneInvalido || cpfInvalido">
                                    Corrija os erros encontrados no formulário.
                                </p>
                            
                            </form>
                        </div>
                    </div>

                    <div class="has-y-scroll" v-else-if="tab == 2" id="tabela-historico-reservas">
                        <div v-show="this.hospede_selecionado !== null">
                            <template v-if="reservas.length > 0">
                                <div class="table-container">
                                    <table class="table  is-fullwidth">
                                        <thead class="thead_color" id="header-tabela">

                                            <th class="has-text-centered">Status</th>
                                            <th class="has-text-centered">UH</th>
                                            <th class="has-text-centered">Check-in</th>
                                            <th class="has-text-centered">Check-out</th>
                                            <th class="has-text-centered">Consultar</th>

                                        </thead>

                                        <tbody>

                                            <template v-for="  reserva   in   reservas  " v-bind:key="reserva.id">

                                                <tr class="is-narrow" v-if="reserva.status !== 'Cancelada'">
                                                    <td class="has-text-centered">
                                                        <span class="tag is-small" :class="`is-${formatarStatus(reserva?.status)}`">
                                                            {{ reserva.status }}
                                                        </span>
                                                    </td>

                                                    <td>{{ reserva.acomodacao.nome }}</td>
                                                    <td>
                                                        {{ new Date(reserva.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}
                                                    </td>

                                                    <td>
                                                        {{ new Date(reserva.data_saida + "T00:00:00").toLocaleDateString('pt-BR') }}
                                                    </td>
                                                    <td class="has-text-centered">
                                                        <div class="buttons is-centered">
                                                            <button
                                                                @click="this.$refs.modalConsulta.ativarModal(reserva)"
                                                                class=" button is-small is-dark">
                                                                <i class="mdi mdi-eye"></i>
                                                            </button>


                                                        </div>
                                                    </td>

                                                </tr>

                                            </template>


                                        </tbody>
                                    </table>
                                </div>
                            </template>
                            <template v-else>
                                <div class="has-text-left notification">
                                    <p class="icon-text fonte-1">
                                        <span class="icon">
                                            <i class="mdi mdi-information-outline"></i>
                                        </span>
                                        <span>
                                            Nenhum <strong>histórico de reserva</strong> encontrado.
                                        </span>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div v-show="hospede_selecionado === null">
                    <div class="notification is-light fonte-1 is-size-5">
                        <i class="mdi mdi-lightbulb-outline"/>
                        <span>
                            Selecione um Hóspede
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ModalConsultarReserva ref="modalConsulta"></ModalConsultarReserva>
</template>

<script>
import ModalConsultarReserva from '@/components/Modals/ModalConsultarReserva.vue';
import CPFInput from '@/components/inputs/CPFInput.vue';
import Navbar from '@/components/layout/Navbar.vue';
import axios from 'axios'
import { toast } from 'bulma-toast'
import { vMaska } from "maska"

export default {
    name: "Hospedes",
    components: { Navbar, CPFInput, ModalConsultarReserva },
    directives: { maska: vMaska },
    data() {

        return {

            hospedes: [],
            reservas: null,
            filtro: '',

            modalApagarAtivo: false,

            tab: 1,

            hospede_selecionado: null,

            hospede_edit: null,
            hospede_edit_copy: null,

            cpfMascara: {
                masked: '',
                unmasked: '',
                completed: false
            },

            cpfInvalido: false,
            telefoneInvalido: false,

            telefoneMascara: {
                masked: '',
                unmasked: '',
                completed: false
            },

            editar_info: false,
            alteracaoFeita: false,

            cores_status: {
                'Pré-reserva': 'rgb(255, 189, 33)',
                'Confirmada': 'rgb(19, 153, 86)',
                'Ocupada': 'rgb(78, 105, 173)',
                'Fechada': 'rgb(173, 78, 78)',
            },

            estados: [
                "Acre",
                "Alagoas",
                "Amapá",
                "Amazonas",
                "Bahia",
                "Ceará",
                "Distrito Federal",
                "Espirito Santo",
                "Goiás",
                "Maranhão",
                "Mato Grosso do Sul",
                "Mato Grosso",
                "Minas Gerais",
                "Pará",
                "Paraíba",
                "Paraná",
                "Pernambuco",
                "Piauí",
                "Rio de Janeiro",
                "Rio Grande do Norte",
                "Rio Grande do Sul",
                "Rondônia",
                "Roraima",
                "Santa Catarina",
                "São Paulo",
                "Sergipe",
                "Tocantins",
            ],
        }
    },

    mounted() {
        this.get_hospedes()
    },

    methods: {
        async get_hospedes() {

            const filtros = {
                nome_cpf_email: this.filtro
            }

            await axios
                .get('/api/v1/hospedes/', { params: filtros })
                .then(response => {
                    this.hospedes = response.data;
                    //console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async get_reservas() {

            const filtros = {
                hospede: this.hospede_selecionado.id
            }

            await axios
                .get('/api/v1/reservas/', { params: filtros })
                .then(response => {
                    this.reservas = response.data;
                    // //console.log(this.reservas);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        selecionarHospede(event, hospede) {
            this.hospede_selecionado = hospede

            //console.log(hospede)

            try {
                document.querySelector('.is-selected').classList.remove('is-selected')
            } catch {

            }
            document.getElementById('tr-' + hospede.id).classList.add('is-selected')

            this.hospede_edit = Object.assign({}, this.hospede_selecionado)

            this.hospede_edit.cpf = this.hospede_edit.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
            this.hospede_edit.telefone = this.hospede_edit.telefone.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/g, "(\$1) \$2-\$3")

            if (this.hospede_edit.email === null) {
                this.hospede_edit.email = ''
            }

            this.hospede_edit_copy = Object.assign({}, this.hospede_edit)

            this.get_reservas()
        },


        atualizarHospede() {
            this.telefoneInvalido = false
            if (!this.telefoneMascara.completed) {
                this.telefoneInvalido = true
            }

            if (!this.cpfMascara.completed) {
                this.cpfInvalido = true
            }


            if (this.telefoneInvalido || this.cpfInvalido) {
                return
            }

            this.hospede_selecionado.nome = this.hospede_edit.nome
            this.hospede_selecionado.cpf = this.cpfMascara.unmasked
            this.hospede_selecionado.telefone = this.telefoneMascara.unmasked
            this.hospede_selecionado.email = this.hospede_edit.email
            this.hospede_selecionado.data_nascimento = this.hospede_edit.data_nascimento
            this.hospede_selecionado.estado = this.hospede_edit.estado

            //console.log(this.hospede_selecionado)


            axios.patch(`api/v1/hospedes/${this.hospede_selecionado.id}/`, this.hospede_selecionado)
                .then(response => {

                    this.editar_info = false
                    const msg = `<i class="mdi mdi-content-save pr-3"><span>  Os dados do hóspede foram atualizados.</span>`

                    toast({
                        message: msg,
                        type: 'is-info is-light',
                        position: 'top-right',
                        duration: 6000,
                        dismissible: true,
                        pauseOnHover: true,
                    })
                    // //console.log(response)

                    this.hospede_edit_copy = Object.assign({}, this.hospede_edit)

                })
                .catch(error => {
                    console.log(error)
                })
        },
        verificarAlteracoes() {

            if (JSON.stringify(this.hospede_edit) !== JSON.stringify(this.hospede_edit_copy)) {

                this.alteracaoFeita = true;


            } else {
                this.alteracaoFeita = false;
            }

        },

        desfazerAlteracoes() {
            this.telefoneInvalido = false
            this.cpfInvalido = false
            this.hospede_edit = Object.assign({}, this.hospede_edit_copy)
            this.alteracaoFeita = false
        },


        formatarStatus(status) {
            return status?.toLowerCase().replace('é', 'e')
        }



    },
}

</script>


<style scoped lang="scss">
li.is-active a {
    background-color: $primary-color !important;
    color: $secondary-color !important;
    border-color: $primary-color !important;
}


.columns {
    width: 100%;
}
</style>

<style scoped lang="scss">
#input-buscar {
    outline: none;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: 0px 0px;
    padding-left: 0;
}

.tag{
    width: 5rem;
}


.btn-search {
    background-color: $primary-color;
    color: $secondary-color;
}

#button-editar {
    z-index: 999;
    position: absolute;
    right: 0%;
    top: 0%;

}

#msg {
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.input-invalido {
    border: red 1px solid !important;
}

.btn-search:hover,
.btn-edit:focus i {
    color: $secondary-color;
}

#reservas-hospede {
    max-height: 50vh;
}

#hsp-table {
    z-index: 11;
}


.is-selected {
    background-color: $secondary-color !important;
    color: $primary-color !important;
    border-color: grey !important;
}


#select-estados:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}


.hosp-box,
.hosp-box-2 {
    border: 2px $primary-color solid;
    position: relative;
    height: 100%;
    width: 100%
}

#tabela-historico-reservas {
    height: 100%;
}

#header-tabela {
    position: sticky !important;
    top: 0 !important;
    z-index: 30;

}

.reservas-box {
    height: 30vh;
}
</style>