<template>
    <div class="modal is-active" v-if="modalAtivo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-produtos">
            <header class="modal-card-head">
                <p class="modal-card-title ml-3 fonte-2 has-text-left">Adicionar Produto</p>
                <button class="delete is-large" aria-label="close" @click="modalAtivo = !modalAtivo"></button>
            </header>
        </div>
        <div class="modal-content has-background-white p-5" id="body-produtos">
            <section>

                <form @submit.prevent="salvarNovoProduto()" id="form-categoria" class="mx-2">

                    <div class="field is-grouped has-text-left ">

                        <div class="control is-expanded">
                            <label class="label has-text-left fonte-1" for="input-nome">Nome:</label>
                            <input class="input fonte-1" placeholder="Ex.: Água Mineral, Refrigerante..." required
                                v-model="descricao" type="text">
                        </div>


                        <MoneyInput ref="money" inputClass="input has-text-left fonte-1 money_input"
                            labelClass="label has-text-left fonte-1" label="Valor Unitário:" v-model="valor_unitario" />
                    </div>

                    <hr class="my-5">
                    <div class="field is-grouped is-grouped-right">
                        <div class="control is-expanded">
                            <button type="button" class="button is-normal is-fullwidth " @click="modalAtivo = false">
                                <span>
                                    Cancelar
                                </span>
                            </button>
                        </div>
                        <div class="control is-expanded">
                            <button class="button is-success is-fullwidth" form="form-categoria">
                                <i class="icon mdi mdi-plus-thick"></i>
                                <span>Adicionar</span>
                            </button>
                        </div>

                    </div>

                </form>




            </section>
        </div>

    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';



export default {
    name: 'Modal Adicionar Produto',
    emits: ['produtoAdicionado'],
    data() {
        return {
            modalAtivo: false,
            descricao: '',
            valor_unitario: ''

        };
    },

    methods: {
        ativarModal() {
            this.modalAtivo = true;
            this.nome = '';
            this.valor = ''
            this.op_excluir = false


        },

        salvarNovoProduto() {
            const produto = {
                descricao: this.descricao,
                valor_unitario: Number.parseFloat(this.valor_unitario.replace(',', '.'))
            };
            axios.post("api/v1/produtos/", produto)
                .then(response => {


                    const msg = `<i class='mdi mdi-check-circle mr-2'><span>  Produto <strong>${this.descricao}</strong> foi adicionado com sucesso!</span>`

                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                    this.$emit('produtoAdicionado')
                    this.modalAtivo = false
                })
                .catch(error => {
                    console.log(error);
                });
        }

    },
    components: { MoneyInput }
}

</script>

<style scoped lang="scss">
@media only screen and (max-width: 1024px) {

    #header-produtos,
    #body-produtos {
        width: 97% !important;

    }
}

#header-produtos,
#body-produtos {
    width: 450px;

}
</style>