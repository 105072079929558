<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-categorias">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2 ml-1 has-text-left">Adicionar Tipo</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>
            </header>
        </div>
        <div class="modal-content has-background-white p-5" id="body-categorias">
            <section>
                <form @submit.prevent="salvarNovaCategoria()" id="form-categoria">
                    <div class="field is-grouped ">
                        <div class="control is-expanded">
                            <label class="label has-text-left fonte-1" for="input-nome">Nome:</label>
                            <input class="input fonte-1" required placeholder="Ex.: Standard, Master..."
                                v-model="descricao" type="text">
                        </div>
                        <MoneyInput ref="money" inputClass="input has-text-left fonte-1 money_input"
                            labelClass="label has-text-left fonte-1" label="Valor base:" v-model="valor_base" />
                    </div>

                    <hr class="">
                    <div class="field is-grouped is-grouped-right">
                        <div class="control is-expanded">
                            <button type="button" class="button is-normal is-fullwidth " @click="modal_ativo = false">
                                <span>
                                    Cancelar
                                </span>
                            </button>
                        </div>
                        <div class="control is-expanded">
                            <button class="button is-success is-fullwidth" form="form-categoria">
                                <i class="icon mdi mdi-plus-thick"></i>
                                <span>Adicionar</span>
                            </button>
                        </div>

                    </div>


                </form>



            </section>
        </div>

    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';



export default {
    name: 'modalAdicionarTipoUH',

    emits: ['novoTipoAdicionado'],
    data() {
        return {
            modal_ativo: false,
            descricao: '',
            valor_base: ''


        };
    },

    methods: {
        ativarModal() {
            this.modal_ativo = true;
            this.descricao = '';
            this.valor_base = ''
        },

        salvarNovaCategoria() {
            const categoria = {
                descricao: this.descricao,
                valor_base: Number.parseFloat(this.valor_base.replace(',', '.'))
            };
            axios.post("api/v1/tiposAcomodacoes/", categoria)
                .then(response => {

                    const msg = `<i class='mdi mdi-check-circle mr-2'><span>  O tipo de UH <strong>${this.descricao}</strong> foi adicionada com sucesso!</span>`


                    toast({
                        message: msg,
                        type: 'is-success is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                    this.$emit('novoTipoAdicionado')
                    this.modal_ativo = false

                })
                .catch(error => {
                    console.log(error);
                });
        }

    },
    components: { MoneyInput }
}

</script>

<style lang="scss">
@media only screen and (max-width: 1024px) {

    #header-categorias,
    #body-categorias {
        width: 97% !important;

    }
}

#header-categorias,
#body-categorias {
    width: 450px;

}


#categorias button {

    border: #411F2D 1.5px solid;
    transition: 0.3s;

}


#categorias button:focus {
    border: #411F2D 1.5px solid;
    box-shadow: none !important;
    outline: none !important;
}



#categorias button:hover {
    background-color: gray;
    color: antiquewhite !important;
}

#categorias button:hover span {
    color: antiquewhite !important;
}


#categorias:has(button.ativado) button:not(.ativado) {
    opacity: 0.7;
}

#categorias:has(button.ativado) button:is(.ativado) {
    background-color: #411F2D;
    color: antiquewhite;
}
</style>