<template>
    <div class="columns is-multiline">
        <div class="column is-12 has-text-left">
            <h1 class="title fonte-1 is-size-4-touch pb-1">
                <i class="icon mdi mdi-cart" />
                <span>
                    Produtos e Serviços
                </span>
            </h1>
            <h2 class="subtitle is-size-5-touch fonte-1">Gerencie os produtos e serviços ofertados na sua hospedagem.</h2>
            <hr class="my-2">
        </div>

        <div class="column is-12 has-text-left py-2">
            <button class="button is-medium is-responsive is-success" @click="this.$refs.modalAdicionarProduto.ativarModal()">
                <i class="mdi mdi-plus icon" />
                <span>Adicionar</span>
            </button>
        </div>

        <div class=" column is-6-desktop" v-if="produtos.length > 0">

            <div class="table-container">

                <table class="table is-fullwidth">

                    <thead class="thead_color">

                        <th class="has-text-left">Nome</th>
                        <th class="has-text-left is-hidden-touch">Valor Unitário</th>
                        <th class="has-text-left is-hidden-desktop">Valor Unt</th>
                        <th class="has-text-left"></th>

                    </thead>

                    <tbody>
                        <tr v-for="produto in produtos" v-bind:key="produto.id"
                            :class="{ 'tr-arquivado': produto.arquivado }">
                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': produto.arquivado }">
                                <span>
                                    {{ produto.descricao }}
                                </span>
                                <span v-if="produto.arquivado">
                                    (<i class="icon mdi mdi-archive" /> Arquivado)
                                </span>
                            </td>

                            <td class="has-text-left fonte-1" :class="{ 'td-arquivado': produto.arquivado }">
                                {{valorUnitarioBRL(produto.valor_unitario) }}
                            </td>

                            <td class="has-text-centered fonte-1 is-narrow">
                                <div class="buttons is-right">
                                    <button class="button is-small has-tooltip-left"
                                        @click="this.$refs.modalEditarProduto.ativarModal(produto)"
                                        v-if="!produto.arquivado">

                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-pencil icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch has-text-weight-bold">
                                             <i class="mdi mdi-pencil icon" />
                                             <span>
                                                 Editar
                                             </span>
                                         </span>

                                    </button>
                                    <button class="button is-small is-dark has-tooltip-left" v-if="!produto.arquivado"
                                        @click="produtoSelecionado = produto; modalArquivarAtivo = true">
                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-archive icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch">
                                             <i class="mdi mdi-archive icon" />
                                             <span>
                                                 Arquivar
                                             </span>
                                         </span>
                                    </button>
                                    <button class="button is-small is-dark" v-else
                                        @click="produtoSelecionado = produto; modalDesarquivarAtivo = true">
                                    
                                        <span class="is-hidden-desktop">
                                            <i class="mdi mdi-archive-arrow-up icon" />
                                        </span>
                                         
                                         <span class="is-hidden-touch">
                                             <i class="mdi mdi-archive-arrow-up icon" />
                                             <span>
                                                 Recuperar
                                             </span>
                                         </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
 
        </div>
        <div class="column is-12" v-else>
            <div class="notification" >
                <p class="fonte-1 is-size-5">
                    <i class="icon mdi mdi-information-outline mr-2"></i>
                    <span>Você ainda não cadastrou nenhum <strong>Produto/Serviço</strong>.</span>
                </p>
            </div>
        </div>


    </div>

    <div class="modal is-active" v-if="modalArquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left fonte-1  is-size-5 ">

                    Deseja <strong>arquivar</strong> o item <span class="has-text-weight-bold">{{
                produtoSelecionado.descricao
            }}</span> ?

                </p>
                <hr class="mx-0">
                <div class="field is-grouped is-grouped-centered mx-0">

                    <div class="control is-expanded">
                        <button class="button is-fullwidth has-text-weight-bold "
                            @click="modalArquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="arquivarProduto">
                            <i class="icon mdi mdi-archive"></i>
                            <span>Arquivar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>
    <div class="modal is-active" v-if="modalDesarquivarAtivo">
        <div class="modal-background"></div>
        <div class="modal-card">

            <section class="modal-card-body">
                <p class="modal-card-title has-text-left fonte-1  is-size-5 ">

                    Deseja <strong>recuperar</strong> o item <span class="has-text-weight-bold">
                    {{produtoSelecionado.descricao}}</span> ?

                </p>
                <hr class="mx-0">
                <div class="field is-grouped is-grouped-centered mx-0">
                    <div class="control is-expanded">
                        <button class="button is-fullwidth  has-text-weight-bold "
                            @click="modalDesarquivarAtivo = false">Cancelar</button>
                    </div>

                    <div class="control is-expanded">
                        <button class="button is-fullwidth is-dark " @click="desarquivarProduto">
                            <i class="icon mdi mdi-archive-arrow-up" />
                            <span>Recuperar</span>
                        </button>
                    </div>

                </div>

            </section>
        </div>

    </div>



    <ModalAdicionarProduto ref="modalAdicionarProduto" @produto-adicionado="this.getProdutos()" />
    <ModalEditarProduto ref="modalEditarProduto" />
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
import ModalAdicionarProduto from './Modals/ModalAdicionarProduto.vue';
import ModalEditarProduto from './Modals/ModalEditarProduto.vue';

export default {
    name: 'tabProdutoServicos',
    data() {
        return {
            produtos: [],
            produtoSelecionado: [],
            modalArquivarAtivo: false,
            modalDesarquivarAtivo: false,
        };
    },

    mounted() {
        this.getProdutos()
    },
    methods: {
        getProdutos() {
            axios
                .get('/api/v1/produtos/todos-produtos/')
                .then(response => {
                    this.produtos = response.data;
                    //console.log(this.produtos)
                })
                .catch(error => {
                    console.log(error);
                });
        },
        valorUnitarioBRL(valor) {
            return valor.toLocaleString('pt-br', { style: 'currency', currency: 'brl' });
        },
        async arquivarProduto() {

            this.produtoSelecionado.arquivado = true

            await axios.patch(`api/v1/produtos/${this.produtoSelecionado.id}/`, this.produtoSelecionado)
                .then(response => {
                    this.getProdutos()
                    this.modalArquivarAtivo = false

                    //console.log(response)

                    const msg = `<i class='mdi mdi-archive mr-2'><span> <strong>${this.produtoSelecionado.descricao}</strong> foi arquivado.</span>`

                    toast({
                        message: msg,
                        type: ' is-light',
                        position: 'top-right',
                        duration: 2000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })


        },


        async desarquivarProduto() {
            await axios.post(`api/v1/produtos/desarquivar-produto/`, { id: this.produtoSelecionado.id })
                .then(response => {
                    this.getProdutos()
                    this.modalDesarquivarAtivo = false

                    const msg = `<i class='mdi mdi-archive-arrow-up mr-2'><span> <strong>${this.produtoSelecionado.descricao}</strong> foi recuperado.</span>`

                    toast({
                        message: msg,
                        type: 'is-light',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: false,
                        pauseOnHover: true,
                    })
                })
        },


    },
    components: { ModalAdicionarProduto, ModalEditarProduto }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 763px) {
    table .button {
        width: auto !important;
        aspect-ratio: 1;
    }
    .modal-card-body {
        border-radius: 5px;
        margin: 1rem !important;
        width: auto !important;
    }
}

.modal-card-body {
    border-radius: 5px;
    padding: 2rem;
}


.tr-arquivado {
    font-family: 'Poppins';
    background-color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.03);
}

.td-arquivado {
    color: hsl($primary-color-hue, $primary-color-saturation, $primary-color-light, 0.6);
    font-family: 'Poppins';
}

table .button {
    width: 7rem;
}

table td {

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

table {
    table-layout: fixed;
}
</style>