<template>
    <article class="panel is-hidden" id="popup">
        <p class="panel-heading fonte-2">
            <span class="mr-2">
                Reserva {{ reserva?.id }}
            </span>
            <span class="tag is-small" :class="`is-${formatarStatus(reserva?.status)}`">
                {{ reserva.status }}
            </span>
        </p>

        <a class="panel-block">
            <span class="panel-icon has-text-dark" v-if="!reserva.manutencao">
                <i class="mdi mdi-account"></i>
            </span>
            <span class="panel-icon has-text-dark" v-else>
                <i class="mdi mdi-wrench"></i>
            </span>

            <p v-if="!reserva.manutencao">
                {{ reserva?.hospede?.nome }}
            </p>

            <p v-else>
                Manutenção
            </p>

        </a>

        <a class="panel-block">

            <span class="panel-icon has-text-success">
                <i class="mdi mdi-calendar-import"></i>
            </span>
            {{ new Date(reserva?.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}
        </a>

        <a class="panel-block">

            <span class="panel-icon has-text-danger">
                <i class="mdi mdi-calendar-export"></i>
            </span>
            {{ new Date(reserva?.data_saida + "T00:00:00").toLocaleDateString('pt-BR') }}
        </a>

        <!-- <a class="panel-block">

            <span class="panel-icon" :style="{ color: this.get_status_color(reserva.status) }">
                <i class="mdi mdi-circle"></i>
            </span> 

        </a> -->


    </article>
</template>


<script>

export default {

    name: 'Popup Reserva',
    data() {
        return {

            cont: 0,
            reserva: {},
            cores_status: {

                pre_reserva: 'rgb(255, 189, 33)',
                confirmada: 'rgb(19, 153, 86)',
                ocupada: 'rgb(78, 105, 173)',
                bloqueada: 'rgb(173, 78, 78)',
            }
        }
    },

    methods: {
        show(x, y, left, reserva = null) {


            document.getElementById('popup').classList.toggle('is-hidden')

            document.getElementById('popup').style.top = y - 200 + 'px'

            if (left) {

                document.getElementById('popup').style.left = x - 50 + 'px'

            } else {
                document.getElementById('popup').style.right = window.innerWidth - x + 'px'
                document.getElementById('popup').style.left = 'none'
            }

            if (reserva !== null) {
                this.reserva = reserva
            }

        },
        hide() {
            document.getElementById('popup').classList.toggle('is-hidden')
            document.getElementById('popup').style = ""

            this.reserva = {}
        },

        get_status_color(string) {

            if (string === 'Pré-reserva') {
                return this.cores_status.pre_reserva
            }

            if (string === 'Confirmada') {

                return this.cores_status.confirmada

            }

            if (string === 'Ocupada') {

                return this.cores_status.ocupada

            }

            if (string === 'Fechada') {

                return this.cores_status.bloqueada

            }

        },

        formatarStatus(status) {
            return status?.toLowerCase().replace('é', 'e')
        }

    }





}


</script>




<style lang="scss" scoped>
article {

    position: absolute;
    z-index: 999;
    box-shadow: $primary-color 2px 2px 5px;
    background-color: white;
}

a {
    font-family: 'Poppins';

    width: 100%;

}

.panel-heading span {
    vertical-align: middle !important;
}

.panel-heading {
    background-color: $primary-color;

}
</style>