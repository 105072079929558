<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-checkin">
            <header class="modal-card-head" style="background-color: rgb(78, 105, 173);">
                <!-- <p class="modal-card-title titulo">Check-in Reserva {{ reserva?.id }}</p> -->
                <p class="modal-card-title titulo fonte-2">Check-in</p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>

            </header>
        </div>

        <div class="modal-content has-background-white has-y-scroll py-6 px-6" id="conteudo-checkin">

            <div class="column is-12 pb-3">
                <h1 class="title is-size-5 has-text-left fonte-1">
                    <span class="icon-text">
                        <i class="icon mdi mdi-book-outline"></i>
                        <span>Resumo da reserva:</span>
                    </span>
                </h1>
            </div>
            <div class="column is-12">
                <div class="columns">
                    <div class="column is-6 is-12-mobile-only">
                        <p class="fonte-1 has-text-left pb-2">
                            <strong>Check-in:</strong> 
                            {{new Date(reserva?.data_entrada + "T00:00:00").toLocaleDateString('pt-BR') }}
                        </p>

                        <p class="fonte-1 has-text-left pb-2">
                            <strong>Check-out: </strong> 
                            {{ new Date(reserva?.data_saida + "T00:00:00").toLocaleDateString('pt-BR')}}
                        </p>

                        <p class="fonte-1 has-text-left pb-2"><strong>Pernoites: </strong> 
                            {{pernoites }} {{ pernoites > 1 ? 'dias' : 'dia' }}
                        </p>
                    </div>
                    <div class="column is-6">

                        <p class="fonte-1 has-text-left pb-2"><strong>Valor Diária:</strong> 
                            {{reserva?.valor_diaria?.toLocaleString('pt-br', {style: "currency", currency: "BRL"})}}
                        </p>

                        <p class="fonte-1 has-text-left pb-2"><strong>Qtd. Adultos:</strong> 
                            {{reserva?.quantidade_adultos }}</p>

                        <p class="fonte-1 has-text-left pb-2"><strong>Qtd. Crianças:</strong> 
                            {{reserva?.quantidade_criancas }}
                        </p>

                    </div>

                </div>
                <hr class="my-1">
            </div>
            <div class="column is-12 pb-3">
                <h1 class="title is-size-5 has-text-left fonte-1">
                    <span class="icon-text">
                        <i class="icon mdi mdi-web"></i>
                        <span>Canal:</span>
                    </span>
                </h1>
            </div>

            <div class="column is-12">
                <p class="fonte-1 has-text-left has-text-weight-bold pb-2"> {{ reserva?.canal?.opcao }}</p>
                <hr class="my-2">
            </div>
            <div class="column is-12 pb-3">
                <h1 class="title is-size-5 has-text-left fonte-1">
                    <span class="icon-text">
                        <i class="icon mdi mdi-information-outline"></i>
                        <span>Informações do Hóspede:</span>
                    </span>
                </h1>

            </div>

            <div class="column is-12">
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <p class="fonte-1 has-text-left pb-2"><strong>Nome:</strong> 
                            {{ reserva?.hospede?.nome }}
                        </p>
                        <p class="fonte-1 has-text-left pb-2"><strong>CPF:</strong> 
                            {{reserva?.hospede?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")}}
                        </p>
                        <p class="fonte-1 has-text-left pb-2"><strong>Data de Nascimento:</strong> 
                            {{ new Date(reserva?.hospede?.data_nascimento + "T00:00:00").toLocaleDateString('pt-BR') }}
                        </p>
                    </div>
                    <div class="column is-6">
                        <p class="fonte-1 has-text-left pb-2"><strong>Telefone:</strong> 
                            {{ reserva?.hospede?.telefone?.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/g, "(\$1) \$2 - \$3") }}
                        </p>

                        <p class="fonte-1 has-text-left pb-2"><strong>E-mail:</strong> 
                            {{reserva?.hospede?.email }}
                        </p>

                        <p class="fonte-1 has-text-left pb-2"><strong>Estado:</strong> 
                            {{ reserva?.hospede?.estado }}
                        </p>
                    </div>
                </div>
                <hr class="my-1">
            </div>


            <div class="column is-12 pb-5">
                <h1 class="title is-size-5 has-text-left fonte-1">

                    <span class="icon-text">
                        <i class="icon mdi mdi-bed-outline"></i>
                        <span>Acomodação:</span>

                    </span>
                </h1>

                <div class="columns is-mobile is-multiline">
                    <div class="column is-4 is-12-touch">
                        <p class="subtitle fonte-1 is-size-7 pb-0"><strong>UH</strong>
                        </p>
                        <p class="title fonte-1 is-size-5 is-size-6-touch"> <span v-if="Number(reserva?.acomodacao?.nome)">Quarto</span>
                            {{ reserva?.acomodacao?.nome ?
        reserva?.acomodacao?.nome : '-' }}</p>
                    </div>
                    <div class="column is-4">
                        <p class="subtitle fonte-1 is-size-7 pb-0"><strong>CATEGORIA</strong>
                        </p>
                        <p class="title fonte-1 is-size-5 is-size-6-touch">
                            {{ reserva?.acomodacao?.tipo_acomodacao?.descricao ? reserva?.acomodacao?.tipo_acomodacao?.descricao : '-' }}
                        </p>
                    </div>
                    <div class="column is-4">
                        <p class="subtitle fonte-1 is-size-7 pb-0"><strong>VALOR BASE</strong>
                        </p>
                        <p class="title fonte-1 is-size-5 is-size-6-touch">
                            {{ reserva?.acomodacao?.tipo_acomodacao?.valor_base !== undefined ? reserva?.acomodacao?.tipo_acomodacao?.valor_base?.toLocaleString('pt-br', {
            style: "currency",
            currency: "BRL"
        }) : '-' }}</p>
                    </div>
                </div>

                <hr class="my-1">
            </div>

            <div class="column is-12 pb-5">

                <h1 class="title is-size-5 has-text-left fonte-1">
                    <span class="icon-text">
                        <i class="icon mdi mdi-cash-multiple has-text-success"></i>
                        <span>Faturamento:</span>

                    </span>
                </h1>

                <template v-if="!reserva.cortesia">
                    <div class="columns is-mobile is-multiline">
                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0"><strong>DIÁRIAS</strong>
                            </p>
                            <p class="title fonte-1 is-size-5 is-size-6-touch">{{
                                reserva.valor_diarias.toLocaleString('pt-br', {style: "currency", currency:"BRL"})}}
                            </p>
                        </div>
                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0"><strong>SINAL PAGO</strong>
                            </p>
                            <p class="title fonte-1 is-size-5 is-size-6-touch">
                                {{ reserva.valor_sinal.toLocaleString('pt-br', {style: "currency", currency:"BRL"}) }}
                            </p>
                        </div>

                        <div class="column">
                            <p class="subtitle fonte-1 is-size-7 pb-0">
                                <strong>PENDENTE</strong>
                            </p>
                            <p class="title fonte-1 is-size-5 is-size-6-touch ">
                                {{ valorRestanteBRL }}
                            </p>
                        </div>
                    </div>
                </template>

                <template v-else>

                    <div class="notification">

                        <p class="is-size-5 is-size-6-touch">
                            <i class="icon mdi mdi-information-outline"/>
                            <span>Essa reserva é uma Cortesia</span>

                        </p>
                    </div>

                </template>

            </div>

            <div class="column is-12" id="info-adicional">
                <hr>
                <form @submit.prevent="confirmarCheckIn" id="form-checkout">

                    <div class="field has-text-left">
                        <div class="control">
                            <label for="forma-pagamento" class="label fonte-1 has-text-left">Forma de pagamento:</label>
                            <div class="select">
                                <select name="" class="fonte-1" :disabled="reserva.cortesia" v-model="formaPagamento"
                                    id="">
                                    <option value="Transferência/Pix">Transferência/Pix</option>
                                    <option value="Dinheiro">Dinheiro</option>
                                    <option value="Crédito">Crédito</option>
                                    <option value="Débito">Débito</option>
                                    <option value="Cortesia">Cortesia</option>
                                </select>
                            </div>

                        </div>


                    </div>

                    <div class="field mt-6 has-text-left">
                        <label class="label  fonte-1">Informações adicionais (Opcional):</label>
                        <div class="control">
                            <textarea class="textarea has-fixed-size" v-model="informacoesAdicionais"
                                placeholder="Máx. 255 caracteres" maxlength="255"></textarea>
                        </div>
                    </div>
                    <hr>
                    <label for="" class="label is-hidden-desktop has-text-left fonte-1">
                        Deseja confirmar o Check-in?
                    </label>
                    <div class="field is-grouped is-grouped-right-desktop">
                        <div class="control is-expanded has-text-left">
                            <button class="button is-fullwidth" @click="modal_ativo = !modal_ativo" type="button">
                                <span class="icon-text has-text-weight-bold">
                                    <!-- <i class="icon mdi mdi-cancel"></i> -->
                                    <span>Cancelar</span>
                                </span>
                            </button>
                        </div>
                        <div class="control is-expanded has-text-right">
                            <button class="button is-fullwidth is-success">
                                <span class="icon-text">
                                    <!-- <i class="icon mdi mdi-check-circle-outline"></i> -->
                                    <span>Confirmar <span class="is-hidden-touch">Check-in</span></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import CPFInput from '../inputs/CPFInput.vue'
import MoneyInput from '../inputs/MoneyInput.vue';

export default {
    name: 'ModalCheckin',
    data() {
        return {
            reserva: {},
            modal_ativo: false,
            cpfMascara: {
                masked: '',
                unmasked: '',
                completed: false
            },

            formaPagamento: 'Transferência/Pix',
            cpf: '',
            informacoesAdicionais: '',

        };
    },
    emits: ['checkinRealizado'],

    computed: {
        pernoites() {
            const data1 = new Date(this.reserva?.data_entrada)
            const data2 = new Date(this.reserva?.data_saida)

            return Math.ceil(Math.abs(data2 - data1) / (1000 * 60 * 60 * 24));
        },
        valorRestante() {
            return (this.reserva.valor_diarias - this.reserva.valor_sinal)
        },
        valorRestanteBRL() {
            return (this.valorRestante)?.toLocaleString('pt-br',
                { style: "currency", currency: "BRL" })
        },

    },


    methods: {

        ativarModal(reserva) {
            this.reserva = reserva;
            //console.log(reserva);
            this.verificarOcupacao()
            this.descricao = '';
        },
        verificarOcupacao() {

            const filtro = {
                acomodacao: this.reserva.acomodacao.id,
                status: 'Ocupada'
            }

            axios.get(`api/v1/reservas/`, { params: filtro })
                .then(response => {

                    ////console.log(response.data.length > 0)
                    if (response.data.length > 0) {
                        const msg = `<i class='mdi mdi-alert mr - 2'><span>  Essa acomodação já está ocupada (Reserva ${response.data[0].id}).</span>`

                        toast({
                            message: msg,
                            type: 'is-warning is-light',
                            position: 'top-right',
                            duration: 6000,
                            dismissible: true,
                            pauseOnHover: true,
                        })
                    } else {
                        this.modal_ativo = true;
                    }

                })
                .catch(error => {
                    console.log(error)
                })
        },

        async confirmarCheckIn() {

            this.reserva.status = "Ocupada";

            this.reserva.valor_pago += this.valorRestante

            const acomodacao = this.reserva.acomodacao

            acomodacao.ocupado = true

            var descricao = `O hóspede fez o check-in realizando um pagamento no valor de ${this.valorRestanteBRL}
                 na forma de ${this.formaPagamento}.`;

            if (this.reserva.cortesia) {
                descricao = 'O hóspede fez o check-in na reserva com cortesia.'
            }

            await axios.patch(`api/v1/reservas/${this.reserva.id}/`, this.reserva)
                .then(response => {

                    axios.patch(`api/v1/acomodacoes/${acomodacao.id}/`, acomodacao)
                        .then(response => {

                            //console.log(response)

                            axios.post('api/v1/historicos/adicionar-historico/', {
                                id_reserva: this.reserva.id,
                                informacao_adicional: this.informacoesAdicionais,
                                descricao: descricao, categoria: "Check-in"
                            })
                                .then(response => {

                                    const msg = `<i class='mdi mdi-check-circle mr-2'><span> Check-in realizado com sucesso!</span>`

                                    toast({
                                        message: msg,
                                        type: 'is-success is-light',
                                        position: 'top-right',
                                        duration: 6000,
                                        dismissible: true,
                                        pauseOnHover: true,
                                    })

                                    this.$emit('checkinRealizado')
                                    this.modal_ativo = false;

                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        })
                        .catch(error => {

                            console.log(error)
                        })


                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    components: { CPFInput, MoneyInput }
}

</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1024px) {

    #conteudo-checkin,
    #header-checkin {
        width: 95% !important;
    }

    #conteudo-checkin{
        padding: 1rem !important;
    }


}

#conteudo-checkin,
#header-checkin,
#footer-checkin {
    width: 55%;
}

#conteudo-checkin {
    height: 70%;
}

#control-cpf {
    text-align: left;
}

#input-cpf2 {

    width: 9rem;
    text-align: center;

}

#fonte-1 {
    width: fit-content;
}
</style>