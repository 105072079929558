<template>
    <div class="modal is-active" v-if="modalAtivo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-adicionar-historico">
            <header class="modal-card-head">
                <p class="modal-card-title fonte-2">
                    Adicionar Histórico
                </p>
                <button class="delete is-large" aria-label="close" @click="modalAtivo = false"></button>
            </header>
        </div>

        <div class="modal-content has-background-white py-5 px-5" id="content-adicionar-historico">

            <form @submit.prevent="adicionarHistorico">

                <div class="field has-text-left">
                    <label for="categoria" class="label fonte-1">Categoria:</label>

                    <div class="control">

                        <div class="select">

                            <select name="categoria" :value="historico.categoria" required id="categoria">

                                <option value="Atualização">Atualização</option>
                                <option value="Negociação">Negociação</option>
                                <option value="Confirmação">Confirmação</option>
                                <option value="Check-in">Check-in</option>
                                <option value="Check-out">Check-out</option>

                            </select>
                        </div>

                    </div>

                </div>

                <div class="field has-text-left">
                    <label for="descricao" class="label fonte-1">Descrição:</label>
                    <div class="control">

                        <textarea name="descricao" v-model="historico.descricao" required id="descricao"
                            placeholder="Máx. 255 caracteres" class="textarea has-fixed-size" maxlength="255" />

                    </div>
                </div>
                <hr>

                <div class="field is-grouped is-grouped-right">

                    <div class="control">
                        <button type="button" class="button" @click="modalAtivo = false">Cancelar</button>
                    </div>

                    <div class="control">
                        <button type="submit" class="button is-info" :class="{ 'is-loading': isLoading }">
                            Adicionar
                        </button>
                    </div>

                </div>

            </form>


        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'

export default {

    name: 'modal-adicionar-historico',
    emits: ['historicoAdicionado'],
    data() {

        return {
            modalAtivo: false,
            historico: {
                id_reserva: null,
                categoria: 'Atualização',
                descricao: '',
                informacao_adicional: '',
            },
            reserva: null,
            isLoading: false,
        }

    },

    methods: {

        ativarModal(reserva) {

            this.historico.id_reserva = reserva.id

            this.modalAtivo = true

            this.historico.categoria = 'Atualização'
            this.historico.descricao = ''
            this.historico.informacao_adicional = ''

        },

        async adicionarHistorico() {

            this.isLoading = true

            await axios.post('api/v1/historicos/adicionar-historico/', this.historico)

                .then(response => {

                    //console.log(response)
                    const msg = `<span> <strong>Sucesso: </strong>Histórico adicionado na Reserva ${this.historico.id_reserva}!</span>`

                    toast({
                        message: msg,
                        type: 'is-success',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })

                    // this.modalAtivo = false
                    this.$emit('historicoAdicionado')
                    this.isLoading = false

                })
                .catch(error => {
                    const msg = `<span> <strong>Erro: </strong>Ocorreu uma falha ao tentar adicionar o histórico.</span>`

                    toast({
                        message: msg,
                        type: 'is-danger',
                        position: 'top-right',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                    })
                    this.isLoading = false
                })

        }

    }

}


</script>

<style scoped>
@media only screen and (max-width: 1024px) {

    #header-adicionar-historico,
    #content-adicionar-historico {
        width: 90% !important;

    }


}
</style>