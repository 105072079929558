<template>
    <div class="columns is-multiline m-0 strech" id="container">
        <div class="column is-12 py-0 is-hidden-touch" >
            <div class="box py-0" id="painel-header">
                <div class="columns m-0 is-vcentered">
                    <div class="column is-8-desktop is-12-touch has-text-left">
                        <h1 class="fonte-1  is-size-2-desktop is-size-4-touch ">{{ dataHojeStr }}</h1>
                    </div>
                    <div class="column is-4 has-text-right-desktop has-text-left-touch">
                        <p class="fonte-1 is-size-2-desktop is-size-4-touch icon-text ">
                            <span class="icon">
                                <i class="mdi mdi-clock-outline"></i>
                            </span>
                            <span>
                                {{ horaAtual }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-6 is-12-touch">
            <div class="box containers">
                <div class="column is-8 pl-0">
                    <h1 class="fonte-1 title has-text-left">Reservas para check-in</h1>
                </div>

                <hr class="">

                <div class="tabs is-boxed">
                    <ul>
                        <li :class="[tabEntrada == 1 ? 'is-active' : '']" @click="tabEntrada = 1">
                            <a class="tab-item fonte-1 has-text-weight-bold">
                                <span>
                                    Hoje
                                </span>
                            </a>
                        </li>
                        <li :class="[tabEntrada == 2 ? 'is-active' : '']" @click="tabEntrada = 2">
                            <a class="tab-item fonte-1 has-text-weight-bold">
                                <span>
                                    Amanhã
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>


                <div class="table-container">
                    <template v-if="(tabEntrada === 1 ? reservasCheckin : reservasCheckinAmanha).length > 0">
                        <table class="table is-fullwidth">
                            <thead class="thead_color">
                                <th>Hóspede</th>
                                <th class="">CPF</th>
                                <th>UH</th>
                                <!-- <th>Status</th> -->
                                <th></th>
                            </thead>

                            <tbody>

                                <tr class="has-text-left"
                                    v-for="  reserva   in   tabEntrada === 1 ? reservasCheckin : reservasCheckinAmanha "
                                    v-bind:key="reserva.id">
                                    <td class="fonte-1 pl-3">{{ reserva.hospede.nome }}</td>
                                    <td class="fonte-1">{{ reserva.hospede.cpf }}</td>
                                    <td class="fonte-1">{{ reserva.acomodacao.nome }}</td>
                                    <!-- <td class="fonte-1">{{ reserva.status }}</td> -->
                                    <td class="fonte-1 pr-0">
                                        <div class="buttons is-right">
                                            <button @click="this.$refs.modalCheckin.ativarModal(reserva)"
                                                class=" button is-small is-responsive is-info">
                                                <span class="icon is-small">
                                                    <i class="mdi mdi-account-arrow-up"></i>
                                                </span>
                                                <span>Check-in</span>
                                            </button>
                                            <button @click="this.$refs.modalConsultarReserva.ativarModal(reserva)"
                                                class=" button is-small  is-responsive is-dark">

                                                <span class="icon">
                                                    <i class="mdi mdi-eye"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="has-text-left notification">
                            <p class="icon-text fonte-1">
                                <span class="icon">
                                    <i class="mdi mdi-information-outline"></i>
                                </span>
                                <span>
                                    Nenhum check-in agendado para
                                    {{ tabEntrada === 1 ? 'Hoje' : 'Amanhã' }}.
                                </span>
                            </p>

                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="column is-6  is-12-touch">
            <div class="box containers ">
                <div class="column is-8 pl-0">
                    <h1 class="fonte-1 title has-text-left">Reservas para check-out</h1>
                </div>
                <hr class="">
                <div class="tabs is-boxed">
                    <ul>
                        <li :class="[tabSaida == 1 ? 'is-active' : '']" @click="tabSaida = 1">
                            <a class="tab-item fonte-1 has-text-weight-bold">
                                <span>
                                    Hoje
                                </span>
                            </a>
                        </li>
                        <li :class="[tabSaida == 2 ? 'is-active' : '']" @click="tabSaida = 2">
                            <a class="tab-item fonte-1 has-text-weight-bold">
                                <span>
                                    Amanhã
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>


                <div class="table-container is-bordered">
                    <template v-if="(tabSaida === 1 ? reservasCheckout : reservasCheckoutAmanha).length > 0">
                        <table class="table is-fullwidth">

                            <thead class="thead_color">
                                <th>Hóspede</th>
                                <th class="">CPF</th>
                                <th>UH</th>
                                <!-- <th>Status</th> -->
                                <th></th>
                            </thead>
                            <tbody>


                                <tr class=" has-text-left"
                                    v-for="  reserva   in   tabSaida === 1 ? reservasCheckout : reservasCheckoutAmanha  "
                                    v-bind:key="reserva.id">
                                    <td class="fonte-1 pl-3">{{ reserva.hospede.nome }}</td>
                                    <td class="fonte-1">{{ reserva.hospede.cpf }}</td>
                                    <td class="fonte-1">{{ reserva.acomodacao.nome }}</td>
                                    <!-- <td class="fonte-1">{{ reserva.status }}</td> -->
                                    <td class="fonte-1 pr-0">
                                        <div class="buttons is-right">
                                            <button @click="this.$refs.modalCheckout.ativarModal(reserva)"
                                                class=" button is-small is-responsive is-danger">
                                                <span class="icon">
                                                    <i class="mdi mdi-account-arrow-down"></i>
                                                </span>
                                                <span>Check-out</span>
                                            </button>

                                            <button @click="this.$refs.modalConsultarReserva.ativarModal(reserva)"
                                                class=" button is-small  is-responsive is-dark">

                                                <span class="icon">
                                                    <i class="mdi mdi-eye"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="has-text-left notification">
                            <p class="icon-text fonte-1">
                                <span class="icon">
                                    <i class="mdi mdi-information-outline"></i>
                                </span>
                                <span>
                                    Nenhum check-out agendado para
                                    {{ tabSaida === 1 ? 'Hoje' : 'Amanhã' }}.
                                </span>
                            </p>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="column is-12 pb-0">
            <div class="columns  is-multiline">
                <div class="column is-3-desktop is-6-tablet is-12-mobile">
                    <div class="box box-infos">
                        <i class="mdi mdi-account-arrow-up has-text-success icon-infos"></i>
                        <p class="fonte-1 title has-text-left">
                            <span class="icon-text">
                                <span>
                                    {{ reservasCheckin.length }}
                                </span>
                            </span>
                        </p>
                        <p class="subtitle has-text-left fonte-1 ">
                            Check-ins do dia
                        </p>

                    </div>
                </div>
                <div class="column is-3-desktop is-6-tablet is-12-mobile">
                    <div class="box box-infos">
                        <i class="mdi mdi-account-arrow-down has-text-danger icon-infos"></i>

                        <p class="fonte-1 title has-text-left">
                            <span class="icon-text">
                                <span>
                                    {{ reservasCheckout.length }}
                                </span>
                            </span>
                        </p>
                        <p class="subtitle has-text-left fonte-1 ">
                            Check-outs do dia
                        </p>

                    </div>

                </div>
                <div class="column is-3-desktop is-6-tablet is-12-mobile">
                    <div class="box box-infos">
                        <i class="mdi mdi-account has-text-info icon-infos"></i>

                        <p class="fonte-1 title has-text-left">
                            <span class="icon-text">
                                <span>
                                    {{ acomodacoesOcupadas }}
                                </span>
                            </span>
                        </p>
                        <p class="subtitle has-text-left fonte-1 ">
                            Hóspedes na casa
                        </p>

                    </div>

                </div>

                <div class="column is-3-desktop is-6-tablet is-12-mobile">
                    <div class="box box-infos">
                        <i class="mdi mdi-home  icon-infos"></i>

                        <p class="fonte-1 title has-text-left">
                            <span class="icon-text">
                                <span>
                                    {{ ocupacao }}%
                                </span>
                            </span>
                        </p>
                        <p class="subtitle has-text-left fonte-1 ">
                            Ocupação
                        </p>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <ModalConsultarReserva ref="modalConsultarReserva" />
    <ModalCheckIn ref="modalCheckin"
        @checkin-realizado="this.tabEntrada === 1 ? this.getCheckin() : getCheckinAmanha()" />
    <ModalCheckOut ref="modalCheckout"
        @checkout-realizado="this.tabSaida === 1 ? this.getCheckout() : getCheckoutAmanha()" />
</template>



<script>
import ModalCheckIn from '@/components/Modals/ModalCheckIn.vue';
import ModalCheckOut from '@/components/Modals/ModalCheckOut.vue';
import ModalConsultarReserva from '@/components/Modals/ModalConsultarReserva.vue';
import Navbar from '@/components/layout/Navbar.vue'
import axios from 'axios'

export default {
    name: "Painel",
    data() {
        return {
            reservasCheckin: [],
            reservasCheckout: [],
            reservasCheckoutAmanha: [],
            reservasCheckinAmanha: [],
            dataHoje: new Date(),
            dataAmanha: new Date(),
            horaAtual: '00:00:00',
            tabEntrada: 1,
            tabSaida: 1,
            acomodacoesOcupadas: 0,
            total: 0,
        };
    },
    computed: {
        dataHojeStr() {
            return this.dataHoje.toLocaleDateString('pt-br', { day: 'numeric', month: 'long', year: 'numeric' })
        },
        dataHojeFormatada() {
            return this.dataHoje.toLocaleDateString('en-ca')
        },

        dataAmanhaFormatada() {
            return this.dataAmanha.toLocaleDateString('en-ca')
        },

        ocupacao() {


            const valor = Math.round(this.acomodacoesOcupadas / this.total * 100)

            if (isNaN(valor)) {
                return 0;
            }
            return valor;
        }

    },
    mounted() {
        this.dataAmanha = new Date(this.dataHojeFormatada)
        this.dataAmanha.setDate(this.dataAmanha.getDate() + 2)

        this.getCheckin();
        this.getCheckout();
        this.getCheckinAmanha();
        this.getCheckoutAmanha();
        this.getOcupacao();
        this.atualizarHorario()


        window.setInterval(this.atualizarHorario, 1000)
    },
    methods: {
        async getCheckin() {
            const filter = {
                data_entrada_range: 'today',
                status: "Confirmada"
            };
            await axios.get('api/v1/reservas', { params: filter })
                .then(response => {
                    this.reservasCheckin = response.data;
                });
        },
        async getCheckinAmanha() {
            const filter = {
                data_entrada_exata: this.dataAmanhaFormatada,
                status: "Confirmada"
            };
            await axios.get('api/v1/reservas', { params: filter })
                .then(response => {
                    this.reservasCheckinAmanha = response.data;
                });
        },
        async getCheckout() {
            const filter = {
                data_saida_range: 'today',
                status: "Ocupada"
            };
            await axios.get('api/v1/reservas', { params: filter })
                .then(response => {
                    this.reservasCheckout = response.data;
                });
        },
        async getCheckoutAmanha() {
            const filter = {
                data_saida_exata: this.dataAmanhaFormatada,
                status: "Ocupada"
            };

            //console.log(filter)
            await axios.get('api/v1/reservas', { params: filter })
                .then(response => {

                    if (response.data.length > 0) {
                        this.reservasCheckoutAmanha = response.data;
                    }
                });
        },
        ativarModalConsultaReserva(reserva) {
            this.$refs.modalConsultarReserva.ativarModal(reserva)
        },
        atualizarHorario() {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();

            // Format the string with leading zeroes
            this.horaAtual = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },

        async getOcupacao() {

            await axios.get('api/v1/acomodacoes',)
                .then(response => {
                    this.total = response.data.length
                    this.acomodacoesOcupadas = response.data.filter(acomodacao => { return acomodacao.ocupado }).length
                })

        }
    },
    components: { Navbar, ModalConsultarReserva, ModalCheckIn, ModalCheckOut }
}

</script>

<style lang="scss" scoped>
.sidebar {
    position: sticky !important;
}

#container {
    width: 100%;
}

.containers {
    height: 100%;

}



li.is-active a {
    opacity: 100%;

}

li:not(.is-active) a {
    opacity: 50%;
    color: gray !important;
}

@media all and (display-mode: fullscreen) {
    .containers {
        min-height: 40rem !important;
        max-height: 40rem !important;
    }
}

.tab-item {
    color: $primary-color !important;
}

#painel-header{
    border: $primary-color 1px solid ;
}

.box-infos {
    border: $primary-color 1px solid;
    position: relative;
}

.box-infos .subtitle {
    position: relative;
    z-index: 10;
    font-weight: 600;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.icon-infos {
    align-items: center !important;
    justify-content: center !important;
    font-size: 80px !important;
    position: absolute;
    top: 0 !important;
    right: 30px;
    height: auto !important;
    width: auto;
    border-radius: 999px;
    z-index: 4;
    opacity: 30%;
}

.containers {
    min-height: 35rem !important;
    max-height: 35rem !important;
    border: 1px grey solid;
}

.info {
    position: relative;
    border: $primary-color solid 2px;
}
</style>
