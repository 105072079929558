<template>
    <div class="control has-icons-left has-icons-right">

        <input ref="senha" type="password" class="input" :class="class" :id="id" :name="name" required
            :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <span class="icon is-large is-left">
            <i class="mdi mdi-lock" />
        </span>
        <span class=" icon is-right is-large is-clickable" @click="mostrar">
            <i class="mdi" :class="[visivel ? 'mdi-eye' : 'mdi-eye-off']"></i>
        </span>
    </div>

</template>

<script>
export default {
    name: 'inputSenha',
    props: {
        modelValue: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        class: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            visivel: false,
        }
    },

    methods: {
        mostrar() {
            this.visivel = !this.visivel
            if (this.visivel) {
                // document.querySelector('input').type = 'text'
                this.$refs.senha.type = 'text'
            } else {
                // document.querySelector('input').type = 'password'
                this.$refs.senha.type = 'password'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.control.has-icons-right .icon.is-right {
    color: $primary-color !important;
    border-left: 0.2px rgb(128, 128, 128, 0.5) solid;
}
</style>