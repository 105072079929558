<template>
    <div class="modal is-active" v-if="modal_ativo">
        <div class="modal-background"></div>
        <div class="modal-card" id="header-modal-manutencao">
            <header class="modal-card-head" id="header">
                <p class="modal-card-title titulo fonte-2">

                    <span>Agendar Manutenção</span>

                </p>
                <button class="delete is-large" aria-label="close" @click="modal_ativo = !modal_ativo"></button>

            </header>
        </div>
        <div class="modal-content" id="body-modal-manutencao">
            <section class="modal-card-body">

                <section class="section p-1">
                    <form @submit.prevent="salvarManutencao()" id="form-manutencao">
                        <div class="columns is-multiline is-mobile m-0 is-vcentered">

                            <div class="column is-6-desktop is-12-touch">

                                <div class="field">
                                    <label class=" label has-text-left fonte-1" for="input-dtInicio">
                                        Data inicio: </label>
                                    <div class="control">
                                        <input class="input" readonly id="input-dtInicio" ::max="max_data_icial"
                                            v-model="dtInicio" type="date">

                                    </div>

                                </div>
                            </div>
                            <div class="column is-6-desktop is-12-touch">
                                <div class="field">
                                    <label class=" label has-text-left fonte-1" for="input-dtInicio">
                                        Data final:
                                    </label>
                                    <div class="control ">
                                        <input class="input" id="input-dtFinal" :min="dtFinal" :max="max_data_final"
                                            v-model="dtFinal" type="date">

                                    </div>
                                </div>

                            </div>

                            <div class="column is-12">
                                <div class="field ">

                                    <label class="label fonte-1 has-text-left">
                                        Descrição da Manutenção:
                                    </label>
                                    <div class="control">
                                        <textarea class="textarea has-fixed-size" required v-model="descricao"
                                            placeholder="Máx. 255 caracteres" maxlength="255"></textarea>
                                    </div>

                                </div>
                            </div>

                            <div class="column is-12 pr-0">
                                <p class="subtitle is-size-6-desktop is-size-6-touch fonte-1 has-text-left">

                                    <span class="icon">
                                        <i class="mdi mdi-information-outline"></i>

                                    </span>
                                    <span>
                                        As reservas da acomodação serão bloqueadas até o fim da manutenção.
                                    </span>

                                </p>
                                <hr>
                                <div class="field is-grouped is-grouped-center">
                                    <div class="control is-expanded">

                                        <button type="button" class="button is-fullwidth  has-text-weight-bold "
                                            @click="modal_ativo = !modal_ativo">

                                            <span>
                                                Cancelar
                                            </span>
                                        </button>
                                    </div>
                                    <div class="control is-expanded">
                                        <button class="button is-fullwidth is-info" :class="{ 'is-loading': op_salvar }"
                                            form="form-manutencao">
                                            <span>
                                                Agendar
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </section>
            </section>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import MoneyInput from '../inputs/MoneyInput.vue';

export default {
    name: 'Modal Produtos',
    data() {
        return {
            modal_ativo: false,
            acomodacao: null,

            dtInicio: '',
            dtFinal: '',
            dtLivre: '',
            descricao: '',

            op_salvar: true,
            max_data_final: '',
            max_data_icial: "",
            descricao: true,
            data_atual: new Date().toLocaleDateString('en-CA')

        };
    },
    emits: ['manutencaoRegistrada'],
    methods: {

        incrementarDias(string, dias) {

            const dataInc = new Date(string + "T00:00")
            //console.log(string)
            dataInc.setDate(dataInc.getDate() + 1)

            var mes = dataInc.getMonth() + 1
            mes = mes >= 10 ? mes : '0' + mes

            var dia = dataInc.getDate()

            dia = dia >= 10 ? dia : '0' + dia

            //console.log(dataInc.getFullYear() + '-' + mes + '-' + dia)

            return dataInc.getFullYear() + '-' + mes + '-' + dia

        },

        ativarModal(acomodacao, dtInicio = null) {


            this.modal_ativo = true;
            this.acomodacao = acomodacao
            this.descricao = ''
            this.dtFinal = ''
            this.op_excluir = false
            this.op_salvar = false

            //console.log(dtInicio === null)
            if (dtInicio === null) {
                this.set_datas()
            } else {
                this.dtInicio = dtInicio
                this.max_data_icial = dtInicio
                this.dtFinal = this.incrementarDias(this.dtInicio)
                this.set_data_final()
            }




        },


        salvarManutencao() {
            this.op_salvar = true

            const reserva = {
                acomodacao: this.acomodacao,
                data_entrada: this.dtInicio,
                data_saida: this.dtFinal,
                status: 'Bloqueada',
                manutencao: true,
                valor_diaria: 0.0,
                valor_diarias: 0.0,
                valor_sinal: 0.0,
                valor_total: 0.0,
                valor_consumo: 0.0,
                descricao: this.descricao,
                hospede: null,
                cortesia: false,
                canal: null
            }



            const hoje = new Date()
            hoje.setHours(0)
            hoje.setMinutes(0)
            hoje.setSeconds(0)
            hoje.setMilliseconds(0)
            const data_inicio = new Date(this.dtInicio + "T00:00:00")


            axios.post('/api/v1/reservas/', reserva)
                .then(response => {

                    //console.log(data_inicio.getTime() === hoje.getTime())
                    //console.log(data_inicio.getTime(), ' ', hoje.getTime())

                    if (data_inicio.getTime() === hoje.getTime()) {
                        this.acomodacao.status = 'Manutenção'

                        axios.patch(`/api/v1/acomodacoes/${this.acomodacao.id}/`, this.acomodacao)
                            .then(response => {

                                const msg = `<i class='mdi mdi-check-circle mr-2'><span> Manutenção agendada.</span>`

                                toast({
                                    message: msg,
                                    type: 'is-success is-light',
                                    position: 'top-right',
                                    duration: 6000,
                                    dismissible: true,
                                    pauseOnHover: true,
                                })

                                this.$emit('manutencaoRegistrada')
                                this.modal_ativo = false;
                            })

                            .catch(error => {
                                console.log(error)
                            })
                    } else {
                        const msg = `<i class='mdi mdi-check-circle mr-2'><span> Manutenção agendada.</span>`

                        toast({
                            message: msg,
                            type: 'is-success is-light',
                            position: 'top-right',
                            duration: 6000,
                            dismissible: true,
                            pauseOnHover: true,
                        })

                        this.$emit('manutencaoRegistrada')
                        this.modal_ativo = false;
                    }

                })

                .catch(error => {
                    console.log(error)
                })
            this.op_salvar = false



        },

        async set_datas() {
            //console.log("-> " + new Date().toLocaleDateString('en-CA'))
            await axios.post('api/v1/reservas/get_prox_data_livre/', { data: new Date().toLocaleDateString('en-CA'), id_acomodacao: this.acomodacao.id })
                .then(response => {

                    this.dtLivre = response.data.data_livre
                    this.dtInicio = this.dtLivre
                    this.dtFinal = this.incrementarDias(this.dtLivre)
                    this.max_data_icial = this.dtLivre
                    this.set_data_final()
                })
                .catch(error => {
                    console.log(error)
                })
        },

        set_data_final() {
            axios.post('api/v1/reservas/get_data_limite/', { data: this.dtInicio, id_acomodacao: this.acomodacao.id })
                .then(response => {
                    this.max_data_final = response.data.data_limite
                    //console.log(this.max_data_final)

                })
                .catch(error => {
                    console.log(error)
                })
        }
    },


    components: { MoneyInput }
}

</script>

<style scoped lang="scss">
@media only screen and (max-width: 1024px) {

    #body-modal-manutencao,
    #header-modal-manutencao {
        width: 97% !important;

    }

}

#conteudo-confirmacao {
    height: auto;
}

#header {
    background-color: $primary-color;
}

.modal-card-head {
    border-radius: 20px 20px 0 0;
}

.modal-card-body {
    border-radius: 0 0 20px 20px;
}
</style>