import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { defaultThemeColors } from "./configs/colors";


const app = createApp(App)

axios.defaults.baseURL = 'https://api.atenahub.com.br/'
// axios.defaults.baseURL = 'http://localhost:8000/'

app.config.globalProperties.$colors = defaultThemeColors;

app.use(store).use(router, axios).mount('#app')
